import React, {useEffect, useRef, useState} from 'react'
import KonvaStage from '../konvaStageClass'
import {isEmpty} from "../utils";

const FloorplanMarkup = ({ config, property, updateDefect }) => {
  const photoRef = useRef(null)
  const konvaStageRef = useRef(null)
  const [openStage, setOpenStage] = useState(false)
  config = { 'drawingMode': false, ...config }
  let markupData = evt => {
    // We have the data, lets process it
    let data = evt.detail

    if (!isEmpty(data)) {
      setOpenStage(false)
      if (data.hasOwnProperty('stageJSONData')) {
        updateDefect('floorplan_markup_canvas_json', data.stageJSONData)
      }
      if (data.hasOwnProperty('filename')) {
        updateDefect('floorplan_markup_filename', data.filename)
      }
      if (!isEmpty(data.thumbImageData)) {
        photoRef.current.src = data.thumbImageData
      }
    }
  }
  let closeUpStage = evt => {
    setOpenStage(false)
  }
  document.addEventListener('markup-data', markupData)
  document.addEventListener('konva-stage-closed', closeUpStage)

  useEffect(() => {
    // Ensure konvaStageClass is available
    if (typeof KonvaStage === 'undefined') {
      console.error('KonvaStage is not defined')
      return
    }

    // Initialize KonvaStage class
    if (openStage) {
      konvaStageRef.current = new KonvaStage({
        photo: photoRef.current,
        ...config,
      })
    }

    return () => {
      // Cleanup if necessary
      if (konvaStageRef.current) {
        try {
          konvaStageRef.current.stage.destroy()
        } catch (e) {
        }
      }
    }
  }, [config, openStage])

  return (
    <div id="wrapper-konva-stage-container">
      {property.floorplan_image ? (
        <span className="photo-thumbnail cursor-pointer inline-block border border-gray-100 hover:border-gray-300 p-2 rounded-lg"
              thumbnail-url={property.floorplan_image}
              full-url={property.floorplan_image}>
          <img ref={photoRef}
               id="markup-img"
               className="object-cover"
               src={property.floorplan_image}
               width='300'
               height="auto"
               data-full-url={property.floorplan_image}
               data-full-width=""
               data-full-height=""
               data-markup-json=""
               alt=""
               onClick={e => setOpenStage(true)}/>
        </span>
      ) : (
        <p className="text-gray-500">No floorplan is available for this property. If you would like to add markup to a floorplan, please contact your DefectBuddy Administrator.</p>
      )}
    </div>
  )
};

export default FloorplanMarkup
