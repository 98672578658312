import React, {useEffect, useRef, useState} from 'react'
import configDataStore from "../data-stores/configDataStore"
import {Link} from "react-router-dom";
import TopNav from "./TopNav";
import SideMenu from "./SideMenu";

const ProjectsPage = () => {
  const { projects } = configDataStore()
  const mainDiv = useRef(null)
  const mainContentSection = useRef(null)
  let [topNavProps, setTopNavProps] = useState({})

  useEffect(() => {
    setTopNavProps({
      backUrl: `/`,
      backUrlLabel: 'Home',
      pageTitle: 'Select Project',
      mainDivRef: mainDiv,
      mainContentSectionRef: mainContentSection
    })
  }, [])

  return (
    <div className="flex flex-col h-screen" ref={mainDiv} id="maindiv">
      <TopNav {...topNavProps}></TopNav>
      
      <div className="flex flex-1 overflow-hidden">
        <SideMenu {...'x'} />
        <div className="section bg-gray-50 flex flex-1 flex-col items-stretch min-h-0 h-full w-full overflow-y-auto" id="main-content-section" ref={mainContentSection}>
          <section className="overflow-auto overflow-y-scroll flex flex-col md:px-4 h-full">
                  
            <div className="container mx-auto">
              <section className="bg-gray-50 py-4">
                <div className="container px-4 mx-auto">
                  <div className="flex flex-wrap -m-3 justify-center items-center">
                    {projects.map((project) => (
                      <div key={project.id} className="w-full md:w-1/2 xl:w-1/4 p-3">
                        <Link to={`/projects/${project.id}/properties`}>
                          <div className="bg-white border border-gray-100 shadow-dashboard rounded-md">
                            <div className="flex flex-col justify-center items-center px-4 pt-8 pb-6 border-b border-gray-100">
                              <h2 className="font-medium text-gray-900 text-xl">{project.short_name}</h2>
                              <h3 className="mb-3 text-xs font-medium text-gray-400">{project.combined_address ?? ''}&nbsp;</h3>
                            </div>
                            <div className="flex flex-wrap pt-4 pb-6 -m-2">
                              <div className="w-full Xmd:w-1/3 p-2">
                                <div className="text-center">
                                  <p className="mb-1 text-xs text-gray-900 font-semibold">
                                    {project.property_count}
                                  </p>
                                  <p className="text-xs text-gray-400 font-medium">
                                    Total Units
                                  </p>
                                </div>
                              </div>
                              {/*
                              <div className="w-full md:w-1/3 p-2">
                                <div className="text-center">
                                  <p className="mb-1 text-xs text-gray-900 font-semibold">
                                    {project.inspected_count}
                                  </p>
                                  <p className="text-xs text-gray-400 font-medium">
                                    Inspected Unit
                                    {project.inspected_count !== 1 && 's'}
                                  </p>
                                </div>
                              </div>
                              <div className="w-full md:w-1/3 p-2">
                                <div className="text-center">
                                  <p className="mb-1 text-xs text-gray-900 font-semibold">
                                    {project.remaining_count}
                                  </p>
                                  <p className="text-xs text-gray-400 font-medium">
                                    Remaining Unit
                                    {project.remaining_count !== 1 && 's'}
                                  </p>
                                </div>
                              </div>*/}
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ProjectsPage
