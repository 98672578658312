import React, {useEffect, useRef, useState} from 'react'
import KonvaStage from '../konvaStageClass'
import {getImageMarkup, isEmpty} from "../utils"

const DefectImagesDisplay = ({ config, defect, defectImages }) => {
  const photoRefs = useRef([])
  const konvaStageRef = useRef(null)
  const [openStage, setOpenStage] = useState(false)
  const [openedPhotoIndex, setOpenedPhotoIndex] = useState(0)
  const [markupJson, setMarkupJson] = useState(false)

  let closeUpStage = evt => {
    setOpenStage(false)
  }

  const setMarkupStage = (image, index) => {
    setOpenedPhotoIndex(index)
    setMarkupJson(prevState => getImageMarkup(defect, image.uniqueFilename))
    setOpenStage(true)
  }

  const getMarkedImage = (imgName) => {
    return ( !isEmpty(defect.marked_images) && !isEmpty(defect.marked_images[ imgName ]) ) ? defect.marked_images[ imgName ] : null
  }

  document.removeEventListener('konva-stage-closed', closeUpStage)
  document.addEventListener('konva-stage-closed', closeUpStage)

  useEffect(() => {
    config = { presentPhoto: true, ...config }

    // Ensure konvaStageClass is available
    if (typeof KonvaStage === 'undefined') {
      console.error('KonvaStage is not defined')
      return
    }

    // Initialize KonvaStage class
    if (openStage) {
      konvaStageRef.current = new KonvaStage({
        photo: photoRefs.current[ openedPhotoIndex ],
        stageData: JSON.stringify(markupJson),
        ...config,
      })
    }

    return () => {
      // Cleanup if necessary
      if (konvaStageRef.current) {
        try {
          konvaStageRef.current.stage.destroy()
        } catch (e) {
        }
      }
    }
  }, [config, openStage])

  return (
    !isEmpty(defectImages) ? (
      defectImages.map((image, i) => (
      <div key={i} className="border border-gray-100 hover:border-gray-300 p-2 my-2 mr-4 rounded-lg text-center"
              onClick={event => setMarkupStage(image, i)}>
        <div className="photo-thumbnail cursor-pointer relative top-0 left-0">
          <img className="object-cover relative top-0 left-0"
              ref={(el) => (photoRefs.current[i] = el)}
              src={image.src}
              data-full-url={image.src}
              data-original-filename={image.originalFilename}
              data-unique-filename={image.uniqueFilename}
              data-full-width=""
              data-full-height=""
              width="200"
              height="auto"
              alt={image.originalFilename}/>
          {getMarkedImage(image.uniqueFilename) && (
                <img src={getMarkedImage(image.uniqueFilename)} alt={image.originalFilename} width="200" height="auto"
                    className="defect-photo-markup absolute top-0 left-0" />
                  )}
        </div>
        <h2 className="text-sm font-medium text-gray-900">
          {image.originalFilename.replace(/^\d+-/, '')}
        </h2>
      </div>
      ))
    ) : (
      <p className="text-gray-500 pt-3">No photos have been provided for this defect.</p>
    )
  )
};

export default DefectImagesDisplay
