import React, { createContext, useState } from 'react'
import {isEmpty} from "./utils";

const AuthContext = createContext({isAuthenticated: false})

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  let token = localStorage.getItem('token')

  if (!isAuthenticated && token && token !== 'undefined') {
    setIsAuthenticated(true)
  }


  const handleLogin = ({ token, user, permissions }) => {
    localStorage.setItem('token', token)
    if (!isEmpty(user)) {
      localStorage.setItem('user', JSON.stringify(user))
    }
    if (!isEmpty(permissions)) {
      localStorage.setItem('permissions', JSON.stringify(permissions))
    }
    setIsAuthenticated(true)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('permissions')
    setIsAuthenticated(false)
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }