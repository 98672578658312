import {create} from 'zustand'
import {persist, createJSONStorage} from "zustand/middleware"

const configDataStore = create(
  persist((
      set) => (
      {
        floorplanImageCache: 'floorplan-cache',
        imageCacheName: 'image-cache',
        settings: {},
        projects: [],
        properties: [],
        locations: [],
        categories: [],
        originalTradesByCategoryByProject: {},
        remedialTradesByCategoryByProject: {},
        allAvailableTrades: {},
        user: {},
        permissions: {},
        floorplanImages: {},
        offlineMode: true,
        pwaPromptDisplayed: false,

        // Actions to update the data variables
        updateSettings: (newSettings) => set((state) => ({ settings: { ...state.settings, ...newSettings } })),
        updateProjects: (data) => set({ projects: data }),
        updateProperties: (data) => {
          //console.log('Updating properties:', data)
          set({ properties: data })
        },
        updateLocations: (data) => set({ locations: data }),
        updateCategories: (data) => set({ categories: data }),
        updateOriginalTradesByCategoryByProject: (data) => set({ originalTradesByCategoryByProject: data }),
        updateRemedialTradesByCategoryByProject: (data) => set({ remedialTradesByCategoryByProject: data }),
        updateAllAvailableTrades: (data) => set({ allAvailableTrades: data }),
        // updateLocations: (data) => set((state) => {
        //   if (!data || data.length === 0)
        //     return {locations: state.locations}
        //
        //   state.locations.forEach((location, index) => {
        //     if (location.room.building_property == data[0]?.room?.building_property) {
        //       state.locations.splice(index, 1)
        //     }
        //   })
        //
        //   return {locations: [...state.locations, ...data]}
        // }),
        appendLocations: (data) => set((state) => {
          if (!data || data.length === 0)
            return { locations: state.locations }

          const existingLocations = state.locations.map(location => {
            if (location.room.building_property == data[ 0 ]?.room?.building_property) {
              return location.room.id
            }
            return false
          }).filter(location => location)
          const newProperties = data.filter(location => !existingLocations.includes(location.room.id))

          if (newProperties.length === 0)
            return { locations: state.locations }

          return { locations: [...state.locations, ...newProperties] }
        }),
        
        
  
        updateUser: (data) => set({ user: data }),
        updatePermissions: (data) => set({ permissions: data }),
        updateFloorplanImages: (data) => set({ floorplanImages: data }),
        setOfflineMode: (data) => set({ offlineMode: data }),
        setPwaPromptDisplayed: (data) => set({ pwaPromptDisplayed: data }),
      }
    ),
    {
      name: 'configDataStore', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage)
    }
  )
)

export default configDataStore
