// adapted from https://github.com/JedWatson/react-select/blob/master/storybook/stories/UnstyledWithTailwind.stories.tsx

import Select, { Props } from "react-select";
import classNames from 'classnames';
import {forwardRef} from "react";

const sampleOptions = [
  {
    label: "Finland",
    options: [
      {
        label: "Great Hotel",
        value: "Great Hotel",
      },
      // More options
    ],
  },
  {
    label: "Sweden",
    options: [{ label: "Stockholm", value: "Stockholm" }],
  },
];

export const ReactSelect = forwardRef(({
  value,
  options = sampleOptions,
  placeholder="Select...",
  isInvalid = false,
  ...props
}: Props, ref) => {
  return (
    <Select
      placeholder={placeholder}
      value={value}
      options={options}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      ref={ref}
      {...props}
      unstyled // Remove all non-essential styles
        classNames={{
          clearIndicator: ({ isFocused }) =>
            classNames(
              isFocused ? 'text-neutral-600' : 'text-neutral-200',
              'p-2',
              isFocused ? 'hover:text-neutral-800' : 'hover:text-neutral-400'
            ),
          // container: () => classNames(),
          control: ({ isDisabled, isFocused }) =>
            classNames(
              isDisabled ? 'bg-neutral-50' : 'bg-white',
              isDisabled ? 'border-gray-200' : (isFocused ? 'border-green-500' : (isInvalid ? 'border-red-500' : 'border-gray-300')),
              'rounded-md',
              'border',
              //isFocused && 'shadow-[0_0_0_1px] shadow-gray-500',
              isFocused ? 'hover:border-green-500' : 'hover:border-neutral-300'
            ),
          dropdownIndicator: ({ isFocused }) =>
            classNames(
              isFocused ? 'text-gray-600' : 'text-gray-900',
              'p-2',
              isFocused ? 'hover:text-neutral-800' : 'hover:text-neutral-400'
            ),
          group: () => classNames('py-2'),
          groupHeading: () =>
            classNames(
              'text-neutral-400',
              'text-xs',
              'font-medium',
              'mb-1',
              'px-3',
              'uppercase'
            ),
          // indicatorsContainer: () => classNames(),
          indicatorSeparator: ({ isDisabled }) =>
            classNames(
              isDisabled ? 'bg-neutral-100' : 'bg-neutral-200',
              'my-2'
            ),
          input: () => classNames('m-0.5', 'py-0.5', 'text-neutral-800'),
          loadingIndicator: ({ isFocused }) =>
            classNames(
              isFocused ? 'text-neutral-600' : 'text-neutral-200',
              'p-2'
            ),
          loadingMessage: () => classNames('text-neutral-400', 'py-2', 'px-3'),
          menu: () =>
            classNames(
              'bg-white',
              'border',
              'border-green-500',
              'rounded-md',
              'shadow-[0_0_0_1px_rgba(0,0,0,0.1)]',
              'my-1'
            ),
          menuList: () => classNames('py-1'),
          // menuPortal: () => classNames(),
          multiValue: () => classNames('bg-neutral-100', 'rounded-sm', 'm-0.5'),
          multiValueLabel: () =>
            classNames(
              'rounded-sm',
              'text-neutral-800',
              'text-sm',
              'p-[3]',
              'pl-[6]'
            ),
          multiValueRemove: ({ isFocused }) =>
            classNames(
              'rounded-sm',
              isFocused && 'bg-red-500',
              'px-1',
              'hover:bg-red-500',
              'hover:text-red-800'
            ),
          noOptionsMessage: () =>
            classNames('text-neutral-400', 'py-2', 'px-3'),
          option: ({ isDisabled, isFocused, isSelected }) =>
            classNames(
              isSelected
                ? 'bg-green-500'
                : isFocused
                ? 'bg-green-300'
                : 'bg-transparent',
            isDisabled
              ? 'text-neutral-200'
              : isSelected
                ? 'text-white'
                : 'text-inherit',
            'py-2',
            'px-3',
            !isDisabled &&
            ( isSelected ? 'active:bg-green-500' : 'active:bg-green-300' )
          ),
        placeholder: () => classNames('text-gray-600', 'mx-0.5'),
        singleValue: ({ isDisabled }) =>
          classNames(
            isDisabled ? 'text-neutral-400' : 'text-neutral-800',
            'mx-0.5'
          ),
        valueContainer: () => classNames('py-1', 'px-4'),
      }}
    />
  );
});
export default ReactSelect