import React, {useEffect, useRef, useState} from 'react'
import {isEmpty, deviceDetector, haveDefectUnsyncedChanges, optimizeDefectForServer, syncDefect, truncateString} from "../utils"

const DefectSyncingModal = ({ defects, showSyncingModal, setShowSyncingModal, setUnSyncedDefects, updateSyncedDefects }) => {
  const syncingModal = useRef(null)
  const [syncStepName, setSyncStepName] = useState(null)
  const [syncConfirmed, setSyncConfirmed] = useState(false)
  const [syncComplete, setSyncComplete] = useState(false)
  const [progressInfo, setProgressInfo] = useState(null)
  const [percentProgress, setPercentProgress] = useState(0)
  const [defectSyncStatus, setDefectSyncStatus] = useState([])
  const abortControllerRef = useRef(null)


  const showDefectSyncingModal = () => {
    setSyncStepName(null)
    setShowSyncingModal(false)
  }

  const getUnsyncedDefects = () => {
    return defectSyncStatus.map((defectInfo) => {
      if (defectInfo.synceAttempted && !defectInfo.isSuccess) {
        return defectInfo.defect
      }
    })
  }


  const closeSyncingModal = (e) => {
    setSyncStepName(null)
    setShowSyncingModal(false)
  }

  const cancelDefectsSyncing = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    closeSyncingModal()
  }

  const updateProgressInfo = (status, current, total) => {
    setProgressInfo({ status: status, percentComplete: Math.round(( current / total ) * 100), current: current, total: total })
  }

  const confirmSyncing = () => {
    setSyncConfirmed(true)
    syncDefectsToServer().finally(() => {
      setUnSyncedDefects(getUnsyncedDefects())
    })
  }

  const syncDefectsToServer = async (defect) => {
    let _defects = isEmpty(defect) ? defects.filter(d => haveDefectUnsyncedChanges(d)) : [defect]
    setSyncStepName('Syncing Defects')
    let _defectsInfo = _defects.map(defect => {
      let defectDescription = !isEmpty(defect.client_issue_description) ? defect.client_issue_description : defect.defect_description
      defectDescription = truncateString(defectDescription, 20)
      return { temporaryId: defect.id, defect: defect, defectDescription: defectDescription, isSuccess: false, message: 'Pending sync', synceAttempted: false, rowColour: 'gray' }
    })
    setDefectSyncStatus(_defectsInfo)
    let current = 0

    abortControllerRef.current = new AbortController()
    const { signal } = abortControllerRef.current

    updateProgressInfo('Sync in Progress', 0, _defectsInfo.length)
    let successfulSyncedDefects = []
    for (const defectInfo of _defectsInfo) {
      current++
      if (signal.aborted) {
        defectInfo.message = 'Sync cancelled'
        console.log('Sync process aborted')
      }
      defectInfo.message = 'Preparing to sync'
      defectInfo.synceAttempted = true
      let defect = defectInfo.defect
      let defectDescription = !isEmpty(defect.client_issue_description) ? defect.client_issue_description : defect.defect_description
      defectDescription = truncateString(defectDescription, 20)
      try {
        let optimizedDefect = optimizeDefectForServer(defect)
        const response = await syncDefect(optimizedDefect, signal)
        if (response && !response.error) {
          defectInfo.message = 'Synced successfully'
          defectInfo.isSuccess = true
          defectInfo.rowColour = 'green'
          if(!isEmpty(response.images)) {
            response.images.forEach(image => {
              // From server, we only get original_filename, and in app, we use uniqueFilename and originalFilename
              image.uniqueFilename = image.originalFilename = image.original_filename
            })
          }
          defectInfo.defect = response
          defectInfo.defect.id = defectInfo.temporaryId
          successfulSyncedDefects.push(defectInfo.defect)
        } else {
          defectInfo.message = response.error
          defectInfo.rowColour = 'red'
        }
      } catch (error) {
        if (signal.aborted) {
          defectInfo.message = 'Sync cancelled'
          console.log('Sync cancelled')
        } else {
          defectInfo.message = 'Unable to connect to DefectBuddy server'
          defectInfo.rowColour = 'red'
        }
      }
      // Updating progress
      setDefectSyncStatus(_defectsInfo)
      updateProgressInfo('Sync in Progress', current, _defectsInfo.length)
    }
    updateProgressInfo('Syncing is complete', current, _defectsInfo.length)

    if (successfulSyncedDefects.length > 0) {
      // Updating the store
      updateSyncedDefects(successfulSyncedDefects)
    }
    setUnSyncedDefects(getUnsyncedDefects())
    setSyncStepName('Syncing Complete')
    setSyncComplete(true)
  }


  useEffect(() => {
    setSyncStepName('Sync Confirmation')
  }, [])


  const renderSyncProgress = () => {
    return (
      <div className="progress-container w-full flex-container z-100">
        <div className="status mb-2">{progressInfo.status}</div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div className="progress-bar h-2 bg-green-500 rounded-full" style={{ width: `${progressInfo.percentComplete}%` }}></div>
        </div>
        <div className="h-full my-4 border-separate overflow-clip rounded-xl border border-solid flex flex-col text-sm">
          <table className="w-full table-fixed">
            <thead className="sticky top-0">
            <tr>
              <th>Defect Description</th>
              <th>Status</th>
            </tr>
            </thead>
          </table>
          <div className="flex-1 overflow-y-auto">
            <table className="w-full table-fixed">
              <tbody>{
                defectSyncStatus.map(defectInfo => (
                  <tr key={defectInfo.temporaryId} className="cursor-pointer space-x-2">
                    <td className={`bg-${defectInfo.rowColour}-200`}>{defectInfo.defectDescription}</td>
                    <td className={`bg-${defectInfo.rowColour}-200`}>{defectInfo.message}</td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  const renderConfirmation = () => {
    return (
      <React.Fragment>
        <div className="mb-1.5 font-normal text-base text-gray-700" id="modal-content">
          Are you sure you want to upload all new defects to the cloud?
        </div>
        <div className="mb-1.5 font-normal text-sm text-gray-700" id="modal-content">
          Once they are uploaded, any photos attached to the defect will be deleted from the App to free up space,
          but you will still be able to view details of the defects.
        </div>
        <div className="mb-1.5 font-normal text-sm text-gray-700" id="modal-content">
          Your original photos stored on your {deviceDetector().device} will not be deleted.
        </div>
      </React.Fragment>
    )
  }

  return (
    showSyncingModal && (
      <div className="z-100 relative" ref={syncingModal}>
        <section className="fixed top-0 left-0 right-0 bottom-0 flex items-center w-full h-full p-4 bg-gray-800 bg-opacity-80 overflow-y-auto">
          <div className="max-w-lg w-full m-auto p-8 bg-white rounded-md">
            <h3 className="mb-2 text-xl font-semibold text-gray-900" id="modal-title">Sync Defects to the cloud</h3>

            {!syncConfirmed && renderConfirmation()}
            {syncConfirmed && renderSyncProgress()}
            <div id="modal-actions">
              <div className="flex flex-wrap border p-2 mb-3 text-red-900 border-red-900 bg-gray-100 hidden message"></div>
              <div className="flex flex-wrap justify-end -mx-2">
                {!syncConfirmed && (
                  <React.Fragment>
                    <div className="w-full md:w-1/2 p-2">
                      <button type="button"
                              onClick={confirmSyncing}
                              className="flex flex-wrap justify-center w-full px-4 py-2.5 bg-green-500 hover:bg-green-600 font-medium text-base text-white border border-green-500 rounded-md shadow-sm confirm">
                        <span>Start Uploading</span>
                      </button>
                    </div>
                    <div className="w-full md:w-1/2 p-2">
                      {syncConfirmed}
                      <button type="button"
                              onClick={closeSyncingModal}
                              className="flex flex-wrap justify-center w-full px-4 py-2.5 bg-white font-medium text-base border border-gray-200 text-gray-500 hover:bg-gray-50 hover:text-green-500 hover:border-gray-300 rounded-md shadow-sm cancel">
                        <span>Cancel</span>
                      </button>
                    </div>
                  </React.Fragment> )}
                {syncConfirmed && !syncComplete && (
                  <div className="w-full md:w-1/2 p-2">
                    {syncConfirmed}
                    <button type="button"
                            onClick={cancelDefectsSyncing}
                            className="flex flex-wrap justify-center w-full px-4 py-2.5 bg-white font-medium text-base border border-gray-200 text-gray-500 hover:bg-gray-50 hover:text-green-500 hover:border-gray-300 rounded-md shadow-sm cancel">
                      <span>Cancel Syncing</span>
                    </button>
                  </div>
                )}
                {syncConfirmed && syncComplete && (
                  <div className="w-full md:w-1/2 p-2">
                    {syncConfirmed}
                    <button type="button"
                            onClick={closeSyncingModal}
                            className="flex flex-wrap justify-center w-full px-4 py-2.5 bg-white font-medium text-base border border-gray-200 text-gray-500 hover:bg-gray-50 hover:text-green-500 hover:border-gray-300 rounded-md shadow-sm cancel">
                      <span>Close</span>
                    </button>
                  </div>
                )}

              </div>
            </div>
          </div>
        </section>
      </div>
    )
  )
}


export default DefectSyncingModal
