import React, {useEffect, useState, useRef} from 'react'
import configDataStore from "../data-stores/configDataStore"
import {Link, useParams} from 'react-router-dom'
import TopNav from "./TopNav";
import SideMenu from "./SideMenu";
import {isEmpty, topNavFix} from "../utils";

const PropertiesPage = () => {
  const { projectId } = useParams()
  const mainDiv = useRef(null)
  const mainContentSection = useRef(null)
  let { projects, properties } = configDataStore()
  let [filteredProperties, setFilteredProperties] = useState([])
  let [projectName, setProjectName] = useState('Project Properties')
  let project = projects.find(project => project.id === parseInt(projectId))

  useEffect(() => {
    const _filteredProperties = properties.filter(
      (property) => property.building_project == projectId
    )
    setFilteredProperties(_filteredProperties)

    setProjectName(project?.short_name ?? `Project ID: ${projectId}`)
    setupScrollHandler()
  }, [properties])

  const setupScrollHandler = () => {    
    let defectListTopPanel = document.getElementById('property_list_top_panel'),
      defectListScrollTop = document.getElementById('property_list_scroll_top')
  
    const scrollHandler = (entries) => {
        defectListTopPanel.classList.toggle('shadow-md', !entries[0].isIntersecting)
    }        
    // create the observer
    const observer = new window.IntersectionObserver(scrollHandler)
    // give the observer some dom nodes to keep an eye on
    observer.observe(defectListScrollTop)
  }

  const arrangePropertiesByLevel = () => {
    // Create an object to store the properties by level, we'll use javascript reduce function
    return filteredProperties.reduce((acc, property) => {
      const { level } = property

      if (acc.hasOwnProperty(level)) { // Level already exists in the accumulator
        acc[ level ].push(property)
      } else {
        acc[ level ] = [property]  // Creating new level array and push the property
      }
      return acc
    }, {})
  }
  const orderProperties = (properties) => {
    const orderedProperties = {}
    const keys = Object.keys(properties)

    // Sort keys with custom comparator to place "G" at the top
    keys.sort((a, b) => {
      if (a === 'G') return -1
      if (b === 'G') return 1
      return a.localeCompare(b, undefined, { numeric: true })
    })

    // Create a new object with the sorted keys
    keys.forEach(key => {
      orderedProperties[ key ] = properties[ key ]
    })

    return orderedProperties
  }
  let propertiesByLevel = arrangePropertiesByLevel()
  if (!isEmpty(propertiesByLevel)) {
    propertiesByLevel = orderProperties(propertiesByLevel)
  }

  const highlightProperty = (property) => {
    if (property?.inspection === 'property-inspected bg-gray-100 greentick-topright') return ' bg-gray-100'
    return ''
  }

  const propertySearchName = (property) => {
    if (property.unit_number !== undefined || property.unit_number != '') return property.unit_number
    if (property.lot_number !== undefined || property.lot_number != '') return 'Lot ' + property.lot_number
    return property.name
  }

  /*
  const handleInspection = (evt) => {
    const inspectionType = evt.target.value
    let _filteredProperties = properties.filter(property => property.building_project == projectId)

    if (inspectionType !== 'both')
      _filteredProperties = _filteredProperties.filter(property => property.inspection === inspectionType)

    setFilteredProperties(_filteredProperties)
  }
  */
  
  const toggleExpandCollapseAll = () => {
    const toggleAll = document.getElementById("toggle_all")
    const label = toggleAll.querySelector('.toggle_label')
    const elements = document.getElementsByClassName('toggle-trigger')
    let expand = true

    toggleAll.querySelector('svg').classList.toggle('rotate-180')
    if (label.innerText === 'Expand All') {
        label.innerText = 'Collapse All'
    } else {
        label.innerText = 'Expand All'
        expand = false
    }
    for (let element of elements) {
      expandCollapseGroup(element, expand)
    }
  }

  const expandCollapseGroup = (groupButton, expand, scrollToGroup = true) => {
      let cardDiv = groupButton.parentElement.parentElement.querySelector('.toggle-target')
      let arrow = groupButton.querySelector('svg')
      if (!arrow) {
          return
      }
      if (expand) {
          arrow.classList.add('rotate-180') 
          cardDiv.classList.remove('hidden')
          if (scrollToGroup) {
            cardDiv.scrollIntoView({behavior: 'smooth', block: 'nearest'})
            topNavFix()
          }
      } else {
          arrow.classList.remove('rotate-180') 
          cardDiv.classList.add('hidden')
      }

  }

  const handleToggle = (event) => {    
    const groupButton = event.currentTarget
    let arrow = groupButton.querySelector('svg')
    if (!arrow) {
        return
    }
    let cardDiv = groupButton.parentElement.parentElement.querySelector('.toggle-target')
    expandCollapseGroup(groupButton, cardDiv.classList.contains('hidden'))
  }


  return (
    <div className="flex flex-col h-screen" ref={mainDiv} id="maindiv">
      <TopNav backUrl={'/projects'} backUrlLabel={'Projects'} pageTitle={projectName} mainDivRef={mainDiv} mainContentSectionRef={mainContentSection}/>
      
      <div className="flex flex-1 overflow-hidden">
      <SideMenu showProjectDropdown={true} showSelectPropertyButton={true} selectedProject={project} />
        <div className="section bg-gray-50 flex flex-1 flex-col items-stretch min-h-0 h-full w-full overflow-y-auto" ref={mainContentSection}>
          <section className="overflow-auto overflow-y-scroll flex flex-col md:px-4 h-full">
            <div className="container mx-auto md:py-0 md:px-4">
              <div className="mb-2 md:mb-4 bg-white rounded-md shadow-lg">
                <div id="property_list_scroll_top"></div>
                <div id="property_list_top_panel" className="z-10 md:my-4 p-2 md:p-4 lg:p-6 block sticky -top-0 bg-white border-t border-gray-200 rounded-t-md flex flex-wrap justify-between">
                    <div className="w-auto px-2 pb-4" id="mainPropertyListHeadingDiv">
                      <h2 className="text-lg text-gray-900 font-semibold">Select Property</h2>
                      <h2 className="text-sm text-gray-600 font-medium">
                        Select a property to create defects
                      </h2>
                      {/*
                      <h2 className="text-sm text-green-600 font-medium">
                        {project?.inspected_count} of {project?.total_count} properties with completed inspections.
                      </h2>
                      */}
                    </div>
                    <div className="w-auto px-2 flex flex-wrap items-center justify-end gap-2 lg:gap-4" id="filterPanel"> 
                        {/*
                        <div id="inspection_filter_div" className="flex items-center flex-wrap gap-2 lg:gap-4 pt-2">
                            <div className="flex flex-wrap sm:flex-nowrap justify-start items-center w-full ml-4 lg:ml-0 lg:w-auto gap-2 lg:gap-4" id="inspectionTypeSelectDiv">
                                <div className="flex flex-nowrap font-semibold text-gray-500" id="inspectionOpenClosed">
                                    <input type="radio" name="inspection_open_closed" id="inspections-incomplete" value="incomplete" onChange={handleInspection}
                                        className="search_filter_includes absolute h-0 w-0 appearance-none"/>
                                    <label htmlFor="inspections-incomplete"
                                        className="relative flex items-center text-center bg-white px-4 py-2 border border-gray-200 shadow-sm cursor-pointer rounded-l-lg hover:bg-gray-50 hover:text-green-500 hover:border-gray-300">
                                        <span className="leading-tight">Incomplete</span>
                                    </label>
                                    <input type="radio" name="inspection_open_closed" id="inspections-both" value="both" onChange={handleInspection}
                                        className="search_filter_includes absolute h-0 w-0 appearance-none" checked />
                                    <label htmlFor="inspections-both"
                                        className="relative flex items-center text-center bg-white px-4 py-2 border border-gray-200 border-x-0 shadow-sm cursor-pointer hover:bg-gray-50 hover:text-green-500 hover:border-gray-300">
                                        <span className="leading-tight">Both</span>
                                    </label>
                                    <input type="radio" name="inspection_open_closed" id="inspections-completed" value="completed" onChange={handleInspection}
                                        className="search_filter_includes absolute h-0 w-0 appearance-none" />
                                    <label htmlFor="inspections-completed"
                                        className="relative flex items-center text-center bg-white px-4 py-2 border border-gray-200 shadow-sm cursor-pointer rounded-r-lg hover:bg-gray-50 hover:text-green-500 hover:border-gray-300">
                                        <span className="leading-tight">Completed</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="w-auto">
                          <button
                            id="toggle_all"
                            className="flex flex-nowrap justify-center w-auto px-4 py-1.5 font-medium text-sm text-gray-500 pt-2 border border-gray-200 hover:bg-gray-50 hover:text-green-500 hover:border-gray-300 rounded-md shadow-sm"
                            onClick={toggleExpandCollapseAll}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                  strokeWidth="2" stroke="currentColor" aria-hidden="true" className="mr-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M5 11l7-7 7 7M5 19l7-7 7 7"></path>
                            </svg>
                            <span className="toggle_label">Collapse All</span>
                          </button>
                        </div>
                    </div>
                </div>
                <div id="search_results" className="mx-2 px-2 md:px-4 lg:px-6 md:pb-4">
                  <div className="w-full mb-4 pb-4" id="property_search_results">
                    {Object.keys(propertiesByLevel).map((level) => (
                      <div key={level} className="mb-6 border border-gray-200 rounded-xl pb-3 accordion_level">
                        <h2 className="accordion_header sticky -top-4 bg-white rounded-t-xl flex items-center justify-between w-full font-medium text-left text-gray-500 hover:bg-gray-50 focus:ring-2 focus:ring-gray-200 border border-gray-200">
                          <button
                            type="button"
                            className="flex items-center justify-between grow p-5 toggle-trigger"
                            onClick={handleToggle}
                          >
                            <span>{level}</span>
                            <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor"
                                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </h2>
                        <section className="toggle-target bg-gray-50">
                          <div className="mx-auto p-4 xl:p-6 pb-1 bg-white">
                            <div className="flex justify-between">
                              <div className="grow flex flex-wrap justify-center md:justify-start accordion_property_list gap-2">
                                {propertiesByLevel[ level ].map((property) => (
                                  <Link key={property.id}
                                        to={`/properties/${property.id}/rooms`}
                                        className={`property flex flex-wrap justify-center w-24 md:w-32 px-2 md:px-4 py-2 font-medium text-lg border border-gray-200 text-gray-500 hover:bg-gray-50 hover:text-green-500 hover:border-gray-300 bg-white rounded-md shadow-sm ${highlightProperty(property)}`}>
                                    {propertySearchName(property)}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default PropertiesPage
