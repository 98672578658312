import React, {useEffect, useRef, useState} from 'react'
import KonvaStage from '../konvaStageClass'
import {getImageMarkup, isEmpty, topNavFix} from "../utils"
import configDataStore from '../data-stores/configDataStore'


const DefectImages = ({ config, defect, setDefect, fileInputs, setFileInputs }) => {
  const { imageCacheName } = configDataStore()
  const photoRefs = useRef([])
  const konvaStageRef = useRef(null)
  const [openStage, setOpenStage] = useState(false)
  const [openedPhotoIndex, setOpenedPhotoIndex] = useState(0)
  const [currentFile, setCurrentFile] = useState({})
  const [markupJson, setMarkupJson] = useState(false)

  let markupData = evt => {
    // We have the data, lets process it
    let data = evt.detail

    if (!isEmpty(data)) {
      if(isEmpty(data.filename)) {
        return
      }

      let filename = data.filename.replace('_markup.', '.')

      let images_markup = defect.images_markup ?? {}
      if (data.hasOwnProperty('stageJSONData')) {
        images_markup[filename] = JSON.parse(data.stageJSONData)  // Before submitting, it'll be properly converted to string
        // updateDefect('images_markup', images_markup)
      }

      let marked_images = defect.marked_images ?? {}
      if (!isEmpty(data.thumbImageData)) {
        marked_images[filename] = data.thumbImageData
        // updateDefect('marked_images', marked_images)
        // photoRefs.current[openedPhotoIndex].src = data.thumbImageData
      }

      setDefect(prevState => ({
        ...prevState, 
        images_markup, 
        marked_images
      }))

      setCurrentFile({})
      setOpenStage(false)
    }
  }

  let closeUpStage = evt => {
    setCurrentFile({})
    setOpenStage(false)
  }

  const deleteImage = async (fileName) => {
    setFileInputs((prev) => prev.filter((file) => file.name !== fileName))
    setDefect((prevDefect) => ( {
      ...prevDefect,
      images: prevDefect.images?.filter((image) => image.fileName !== fileName),
    } ))

    // Remove the image from the cache
    if ('serviceWorker' in navigator /*&& navigator.serviceWorker.controller*/) {
      const cache = await caches.open(imageCacheName)
      await cache.delete(`/cached-images/${fileName}`)
    }
  }

  const setMarkupStage = (file, index) => {
    setOpenedPhotoIndex(index)
    setCurrentFile(file)
    setMarkupJson(prevState => getImageMarkup(defect, file.uniqueFilename))
    document.removeEventListener(`markup-data-${file.uniqueFilename}`, markupData)
    document.addEventListener(`markup-data-${file.uniqueFilename}`, markupData)
    setOpenStage(true)
  }

  const getMarkedImage = (uniqueFilename) => {
    return (!isEmpty(defect.marked_images) && !isEmpty(defect.marked_images[uniqueFilename])) ? defect.marked_images[uniqueFilename] : null
  }

  document.addEventListener('konva-stage-closed', closeUpStage)

  useEffect(() => {
    config = { 'drawingMode': true, 'mimeType': 'image/jpeg', ...config }
    if(!isEmpty(currentFile)) {
      let mimeType = currentFile.type ?? 'image/jpeg'
      config = {...config, mimeType}
    }

    // Ensure konvaStageClass is available
    if (typeof KonvaStage === 'undefined') {
      console.error('KonvaStage is not defined')
      return
    }

    // Initialize KonvaStage class
    if (openStage) {
      konvaStageRef.current = new KonvaStage({
        photo: photoRefs.current[openedPhotoIndex],
        stageData: JSON.stringify(markupJson),
        ...config,
      })
    }

    return () => {
      // Cleanup if necessary
      if (konvaStageRef.current) {
        try {
          konvaStageRef.current.stage.destroy()
        } catch (e) {
        }
      }
    }
  }, [config, openStage])

  return (
    fileInputs.map((file, index) => (
      <div key={index} className="w-full @lg/edit_defect:w-auto border border-gray-100 p-2 my-2 rounded-lg bg-gray-50">
        <div className="flex flex-wrap items-center justify-between defect-photo-wrapper">
          <div className="w-auto p-2">
            <div className="flex flex-wrap align-top">
              <div className="w-auto p-2 photo-wrapper">
                <div className="photo-thumbnail cursor-pointer inline-block border border-gray-100 hover:border-gray-300 p-2 rounded-lg photo-markup-btn relative top-0 left-0"
                      onClick={event => setMarkupStage(file, index)}>
                    <img src={URL.createObjectURL(file)} alt={file.originalFilename} width="200" height="auto"
                          ref={(el) => (photoRefs.current[index] = el)}
                          data-original-filename={file.originalFilename}
                          data-unique-filename={file.uniqueFilename}
                          data-full-url={URL.createObjectURL(file)}
                          data-full-width=""
                          data-full-height=""
                          data-markup-json={getImageMarkup(defect, file.uniqueFilename)}
                          className="defect-photo relative top-0 left-0" />
                    {getMarkedImage(file.uniqueFilename) && (
                          <img src={getMarkedImage(file.uniqueFilename)} alt={file.originalFilename} width="200" height="auto"
                              className="defect-photo-markup absolute top-0 left-0" />
                    )}
                </div>
                <h2 className="text-sm font-medium text-gray-900">
                  {file.originalFilename.replace(/^\d+-/, '')}
                </h2>
              </div>
            </div>
          </div>
          <div className="w-auto p-2">
            <button onClick={event => setMarkupStage(file, index)}
                    type="button"
                    className="flex flex-wrap items-center justify-center py-3 px-4 w-full text-base text-gray-500 font-medium bg-white border border-gray-200 hover:border-gray-300 rounded-md shadow-sm mb-2 photo-markup-btn">
              <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.16666 15H7.69999C7.80966 15.0006 7.91838 14.9796 8.01991 14.9381C8.12144 14.8967 8.21379 14.8356 8.29166 14.7583L14.0583 8.98333L16.425 6.66667C16.5031 6.5892 16.5651 6.49703 16.6074 6.39548C16.6497 6.29393 16.6715 6.18501 16.6715 6.075C16.6715 5.96499 16.6497 5.85607 16.6074 5.75452C16.5651 5.65297 16.5031 5.5608 16.425 5.48333L12.8917 1.90833C12.8142 1.83023 12.722 1.76823 12.6205 1.72592C12.5189 1.68362 12.41 1.66183 12.3 1.66183C12.19 1.66183 12.0811 1.68362 11.9795 1.72592C11.878 1.76823 11.7858 1.83023 11.7083 1.90833L9.35832 4.26667L3.57499 10.0417C3.49776 10.1195 3.43665 10.2119 3.39518 10.3134C3.35371 10.4149 3.33269 10.5237 3.33332 10.6333V14.1667C3.33332 14.3877 3.42112 14.5996 3.5774 14.7559C3.73368 14.9122 3.94564 15 4.16666 15ZM12.3 3.675L14.6583 6.03333L13.475 7.21667L11.1167 4.85833L12.3 3.675ZM4.99999 10.975L9.94166 6.03333L12.3 8.39167L7.35832 13.3333H4.99999V10.975ZM17.5 16.6667H2.49999C2.27898 16.6667 2.06701 16.7545 1.91073 16.9107C1.75445 17.067 1.66666 17.279 1.66666 17.5C1.66666 17.721 1.75445 17.933 1.91073 18.0893C2.06701 18.2455 2.27898 18.3333 2.49999 18.3333H17.5C17.721 18.3333 17.933 18.2455 18.0892 18.0893C18.2455 17.933 18.3333 17.721 18.3333 17.5C18.3333 17.279 18.2455 17.067 18.0892 16.9107C17.933 16.7545 17.721 16.6667 17.5 16.6667Z"
                  fill="#D5DAE1"></path>
              </svg>
              Markup
            </button>
            <button onClick={() => deleteImage(file.uniqueFilename)}
                    type="button"
                    className="flex flex-wrap items-center justify-center py-3 px-4 w-full text-base text-gray-500 font-medium bg-white border border-gray-200 hover:border-gray-300 rounded-md shadow-sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"
                   viewBox="0 0 20 20" strokeWidth="2" stroke="currentColor" aria-hidden="true"
                   className="mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
              Remove
            </button>
          </div>
        </div>
      </div>
    ))
  )
};

export default DefectImages
