import React, {useEffect, useRef, useState} from 'react'
import configDataStore from "../data-stores/configDataStore"
import {Link, useParams} from 'react-router-dom'
import {
  formatDate,
  getAssignedRemedialContractorName,
  getAssignedTradeName,
  getCategoryName,
  getDefectRaisedByTypeLabel,
  getDefectStatusLabel,
  getImageUrlFromCache,
  getProjectFromDefect,
  getRoom,
  isEmpty
} from "../utils";
import TopNav from "./TopNav";
import SideMenu from "./SideMenu";
import {format} from "date-fns";
import DefectImagesDisplay from "./defectImagesDisplay";
import defectDataStore from '../data-stores/defectDataStore';


const ViewDefectPage = () => {
  let { defectId } = useParams()
  defectId = parseInt(defectId)
  const { properties, recordDefect, permissions, user } = configDataStore()
  const [propertyId, setPropertyId] = useState(0)
  const [defect, setDefect] = useState({})
  const [property, setProperty] = useState({})
  const [project, setProject] = useState({})
  const [topNavProps, setTopNavProps] = useState({})
  const [commentVisibilitySelectedChoice, setCommentVisibilitySelectedChoice] = useState('')
  const [commentVisibilitySelectedLabel, setCommentVisibilitySelectedLabel] = useState('Select Visibility')
  const [defectOptimized, setDefectOptimized] = useState(false)
  const [filteredComments, setFilteredComments] = useState([])
  const [defectImages, setDefectImages] = useState([])
  const mainDiv = useRef(null)
  const mainContentSection = useRef(null)
  const commentTextarea = useRef(null)
  const commentVisibilityButton = useRef(null)
  const commentVisibilityDropdown = useRef(null)
  const { defects } = defectDataStore()

  const setCommentVisibility = (elm) => {
    let choice = elm.currentTarget.dataset.choice
    let label = elm.currentTarget.innerText

    setCommentVisibilitySelectedChoice(choice)
    setCommentVisibilitySelectedLabel(label)

    commentVisibilityDropdown.current.classList.add('hidden')
  }

  const mainDivClickHandler = (evt) => {
    // Closing active comment visibility dropdown if there's any
    if(!commentVisibilityDropdown.current?.classList.contains('hidden')
      && !commentVisibilityDropdown.current?.contains(evt.target)
      && !commentVisibilityButton.current?.contains(evt.target)) {
      commentVisibilityDropdown.current?.classList.add('hidden')
    }
  }

  const filterComment = (evt) => {
    let visibility = evt.currentTarget.value
    if (!isEmpty(visibility) && !isEmpty(defect.comments)) {
      if (visibility === 'all') {
        setFilteredComments(prevState => defect.comments)
      } else {
        setFilteredComments(prevState => defect.comments.filter(c => {
          return c.visibility.includes(visibility)
        }))
      }
    }
  }

  const recordComment = (evt) => {
    if(commentVisibilitySelectedChoice === "") {
      // TODO Need to show tooltip about the error
      commentVisibilityDropdown.current.classList.remove('hidden')
      return
    }

    if(commentTextarea.current.value.trim() === "") {
      return
    }

    let recordedAt = new Date()
      recordedAt = format(recordedAt, "yyyy-MM-dd'T'HH:mm:ssXXX")

    let comment = {
      id: +Math.floor(new Date() / 10000),
      visibility: commentVisibilitySelectedChoice,
      visibility_description: commentVisibilitySelectedLabel,
      author: user.id,
      recorded_at: recordedAt,
      parent_defect_id: defect.id,
      text: commentTextarea.current.value,
      syncedToServer: false
    }

    defect.comments = !isEmpty(defect.comments) ? [...defect.comments, comment] : [comment]

    recordDefect(defect)

    // Resetting comment text for new comment
    commentTextarea.current.value = ''

    // Refreshing the filtered comments
    filterComment({ currentTarget: { value: 'all' } })
  }

  const isPermitted = (perm = '') => {
    let availablePerms = permissions?.properties[ propertyId ]
    if (availablePerms) {
      // Temporary amendments for admins
      if (['admin', 'super_user'].includes(user.role)) {
        availablePerms = [...availablePerms, ...[
          'edit_defect_details',
          'view_defect_client_details',
          'edit_defect_client_details',
          'create_defect_comments',
          'set_defect_comment_visibility',
        ]]
      }
    }

    let appPermissions = permissions?.app
    if (appPermissions) {
      availablePerms = ( availablePerms ) ? [...availablePerms, ...appPermissions] : appPermissions
    }

    if (!isEmpty(project)) {
      let projectPermissions = permissions?.projects[ project.id ]
      if (projectPermissions) {
        availablePerms = availablePerms ? [...availablePerms, ...projectPermissions] : projectPermissions
      }
    }

    return availablePerms?.includes(perm)
  }

  const getUserName = (userId) => {
    if (isEmpty(userId) || user.id == userId) {
      return 'You'  // As defect.author is null when the defect is just created and not yet synced.
    }

    return 'Other User'
  }

  /*const getImageSrc = (imageName) => {
    let imgSrc = ''
    if (!isEmpty(defect.images_thumbs)) {
      defect.images_thumbs.find(({name, url}) => {
        if (name === imageName) {
          imgSrc = url
          return true // Breaks the loop
        }
      })
    }
    return imgSrc
  }*/

  const imageProps = () => {
    return {
      config: {}, defectImages,
      defect
    }
  }

  useEffect(() => {
    let _defect = defects.find(d => d.id === defectId)
    let _propertyId = 0
    let _prop = {}

    setDefect(prevState => {
      if (!isEmpty(_defect)) {
        _propertyId = _defect.building_property_id ?? _defect.building_property
        _prop = properties.find(p => p.id === _propertyId)
        if (!isEmpty(_prop)) {
          setPropertyId(_propertyId)
          setProperty(_prop)
        }

        // Setting comments
        if (!isEmpty(_defect.comments)) {
          setFilteredComments(_defect.comments)
        }

        // Getting images, and saving them in a separate state variable because the process is async and takes some time to fetch from cache.
        if (!isEmpty(_defect.images)) {
          _defect.images.forEach(image => {
            getImageUrlFromCache(image).then(imgUrl => {
              setDefectImages(prevState => [...prevState, { name: image.name, uniqueFilename: image.uniqueFilename, originalFilename: image.originalFilename, src: imgUrl }])
            })
          })
        }

        return _defect
      }
      return {}
    })

    let _project = getProjectFromDefect(_defect)
    setProject(_project)

    setTopNavProps({
      backUrl: `/properties/${_propertyId}/rooms`,
      backUrlLabel: 'Locations List',
      pageTitle: !isEmpty(_project) ? `${_project.short_name} ${_prop.unit_number}` : _prop.unit_number,
      mainDivRef: mainDiv,
      mainContentSectionRef: mainContentSection
    })
  }, [defectId])

  return (
    <div className="flex flex-col h-screen" ref={mainDiv} onClick={mainDivClickHandler}>
      <TopNav {...topNavProps} />
      
      <div className="flex flex-1 overflow-hidden">
      <SideMenu showProjectDropdown={true} showSelectPropertyButton={true} selectedProject={project} />
      <div className="section bg-gray-50 flex flex-1 flex-col items-stretch min-h-0 h-full w-full overflow-y-auto" id="main-content-section" ref={mainContentSection}>
            <div className="overflow-auto flex flex-col sm:pt-2 md:p-8 md:pt-4 h-full md:rounded-md md:shadow-lg">
              {!isEmpty(defect) ? (
                <div id="view_defect_div"
                     data-marking="{{ defect.marking|default:'' }}"
                     data-id={defectId}
                     data-room-id="{{ defect.room.id }}" className="flex justify-center w-full @container/view_defect">
                  <div className="p-6 bg-white sm:border sm:border-gray-100 sm:shadow-sm sm:rounded-md w-full @lg/view_defect:w-9/12">
                    <div className="@md/view_defect:pb-6">
                      <div className="flex flex-wrap items-center justify-between -m-2">
                        <div className="w-full @md/view_defect:w-auto p-2">
                          <h2 className="text-gray-900 text-lg font-semibold">View Defect</h2>
                        </div>
                        {( isPermitted('edit_defect_client_details') || isPermitted('edit_defect_details') || isPermitted('progress_defect_status') ) && (
                          <div className="w-full @md/view_defect:w-auto w-auto p-2">
                            <div className="flex flex-wrap justify-between @md/view_defect:justify-end -m-1.5">
                              {isPermitted('progress_defect_status') && (
                                <div className="w-auto p-1.5 progress_defect_status_list">
                                  {/*{% include "partials/progress_defect_status_list.html" with dropdown_direction="dropdown" button_py="py-3" %}*/}
                                </div>
                              )}

                              {( isPermitted('edit_defect_client_details') || isPermitted('edit_defect_details') ) && !defect.committed_by_author && (
                                <div className="w-auto p-1.5">
                                  <Link to={`/properties/${propertyId}/rooms/${defect.room}/defects/${defectId}/update`}
                                        className="flex flex-wrap items-center justify-center py-2 px-3 w-auto text-base text-gray-500 font-medium bg-white border border-gray-200 hover:border-gray-300 rounded-md shadow-sm hover:bg-gray-50 hover:text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mr-2" fill="currentColor">
                                      <path
                                        d="m14.06 9.02.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z">
                                      </path>
                                    </svg>
                                    <span>Edit Defect</span>
                                  </Link>
                                </div>
                              )}

                              {/*
                              {isPermitted('db_support_functions') && (
                                <div id="db_support_functions" className="w-auto p-1.5">
                                  % include "partials/defect_db_support_functions.html" with dropdown_direction="dropdown" button_py="py-3" %
                                </div>
                              )}

                              {isPermitted('assign_trade_user_to_defect') && (
                                % include "partials/assign_trade_user_button.html" % 
                              )}
                              */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="pt-4 border-t border-gray-100">
                      <div className="w-full @md/view_defect:px-3">
                        <div className="flex flex-wrap border-b border-gray-100">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Defect Number</p>
                          </div>
                          <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                            {defect.defect_number ?? 'Pending Sync'}
                          </div>
                        </div>
                        <div className="flex flex-wrap border-b border-gray-100">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Recorded</p>
                          </div>
                          <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                            {formatDate(defect.recorded_at)} by {getUserName(defect.author)}
                          </div>
                        </div>
                        <div className="flex flex-wrap border-b border-gray-100">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">On behalf of</p>
                          </div>
                          <div
                            className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">{getDefectRaisedByTypeLabel(defect.defect_raised_by_type)}</div>
                        </div>
                        {!isEmpty(property.settlement_date) && (
                          <>
                            <div className="flex flex-wrap border-b border-gray-100">
                              <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                                <p className="text-sm text-gray-700 font-semibold">Point of Contact</p>
                              </div>
                              <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                                {!isEmpty(defect.point_of_contact_name) ? (
                                  <table className="text-sm">
                                    <tbody>
                                      <tr>
                                        <td className="pr-2">Name:</td>
                                        <td className="font-medium">{defect.point_of_contact_name}</td>
                                      </tr>
                                      <tr>
                                        <td className="pr-2">Email:</td>
                                        <td className="font-medium">{defect.point_of_contact_phone}</td>
                                      </tr>
                                      <tr>
                                        <td className="pr-2">Phone:</td>
                                        <td className="font-medium">{defect.point_of_contact_email}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                ) : (
                                  "This unit has settled but no point of contact information has been provided."
                                )}
                              </div>
                            </div>
                            <div className="flex flex-wrap border-b border-gray-100">
                              <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                                <p className="text-sm text-gray-700 font-semibold">Permission to access property unattended</p>
                              </div>
                              <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                                {defect.entry_permitted ? (
                                  <>
                                    Yes<br/> Entry Instructions: {defect.entry_instructions}
                                  </>
                                ) : (
                                  "No"
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="flex flex-wrap border-b border-gray-100">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Status</p>
                          </div>
                          <div id="progress_status_oob_target" className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3">
                            <p className="mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">{getDefectStatusLabel(defect.current_status)} </p>
                            {!isEmpty(defect.current_status_reason) && (
                              <p className="mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed"><span className="font-semibold">Reason: </span>{defect.current_status_reason}</p>
                            )}
                          </div>
                        </div>
                        {isPermitted('view_external_reference_number') && defect.external_reference_number && (
                          <div className="flex flex-wrap border-b border-gray-100">
                            <div className="w-full @md/view_defect:w-1/3 py-1 @md/view_defect:py-3 text-sm text-gray-700 font-semibold">External Reference Number</div>
                            <div className="w-full @md/view_defect:flex-1 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed"> {defect.external_reference_number}</div>
                          </div>
                        )}
                        <div className="flex flex-wrap {% if not defect.location_notes %}border-b border-gray-100{% endif%}">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Defect Location</p>
                          </div>
                          <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                            <Link to={`/properties/${property.id}/rooms`} className="text-green-600 underline hover:font-semibold">{property.unit_number}</Link> /&nbsp;
                            {isEmpty(defect.room) ? ( "no location specified" ) : ( getRoom(defect.room)?.room_name )}
                          </div>
                        </div>
                        {defect.location_notes && (
                          <div className="flex flex-wrap border-b border-gray-100">
                            <div className="w-full @md/view_defect:w-1/3 pb-1 @md/view_defect:pb-3 text-sm text-gray-700 font-semibold">Location Notes</div>
                            <div className="w-full @md/view_defect:flex-1 pb-1 @md/view_defect:pb-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed"> {defect.location_notes}</div>
                          </div>
                        )}
                        {defect.high_priority && (
                          <div className="flex flex-wrap border-b border-gray-100">
                            <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                              <p className="text-sm text-gray-700 font-semibold">Priority</p>
                            </div>
                            <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                              <b>High Priority</b>
                            </div>
                          </div>
                        )}
                        <div className="flex flex-wrap border-b border-gray-100">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Defect Category</p>
                          </div>
                          <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                            {getCategoryName(defect) ?? 'No category selected'}
                          </div>
                        </div>

                        {isPermitted('view_defect_all_assigned_trades') ? (
                          <>
                            <div className="flex flex-wrap border-b border-gray-100">
                              <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                                <p className="text-sm text-gray-700 font-semibold">
                                  {defect.remedial_trade_company || defect.remedial_contractor_id ? "Original" : "Assigned"} Sub-Contractor
                                </p>
                              </div>
                              <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                                {defect.allocated_trade_practitioner || defect.trade_practitioner_id ? (
                                  <>
                                    {getAssignedTradeName(defect)}&nbsp;
                                    {isPermitted('view_date_sent_to_trade') && !( defect.remedial_trade_company || defect.remedial_contractor_id ) && !defect.linked_parent_defect && (
                                      <span className="italic">
                            {defect.send_to_trade_company_at ? (
                              defect.sent_to_trade_company_at ? (
                                `(emailed on ${defect.sent_to_trade_company_at})`
                              ) : (
                                `(email scheduled to send at ${defect.send_to_trade_company_at})`
                              )
                            ) : (
                              "(Defect has not been emailed yet)"
                            )}
                          </span>
                                    )}
                                  </>
                                ) : (
                                  "Not assigned yet"
                                )}
                              </div>
                            </div>
                            {defect.remedial_trade_company && (
                              <div className="flex flex-wrap border-b border-gray-100">
                                <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                                  <p className="text-sm text-gray-700 font-semibold">Remedial Contractor</p>
                                </div>
                                <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3">
                                  <p className="mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">{getAssignedRemedialContractorName(defect)}&nbsp;
                                    {isPermitted('view_date_sent_to_trade') && (
                                      <span className="italic">
                            {!isEmpty(defect.send_to_remedial_trade_company_at) ? (
                              !isEmpty(defect.sent_to_remedial_trade_company_at) ? (
                                `(emailed on ${defect.sent_to_remedial_trade_company_at})`
                              ) : (
                                `(email scheduled to send at ${defect.send_to_remedial_trade_company_at})`
                              )
                            ) : (
                              "(Defect has not been emailed yet)"
                            )}
                            </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="flex flex-wrap border-b border-gray-100">
                            <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                              <p className="text-sm text-gray-700 font-semibold">Assigned Trade Contractor</p>
                            </div>
                            <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                              {!isEmpty(defect.remedial_trade_company) || !isEmpty(defect.remedial_contractor_id) ? (
                                getAssignedRemedialContractorName(defect)
                              ) : (
                                !isEmpty(defect.allocated_trade_practitioner) || !isEmpty(defect.trade_practitioner_id) ? (
                                  getAssignedTradeName(defect)
                                ) : ( "Not assigned" )
                              )}
                              {defect.assigned_trade_user ?
                                ( <b>{getUserName(defect.assigned_trade_user)}</b> ) : (
                                  ['trade', 'trade_admin'].includes(user.role) &&
                                  ( <b>Not assigned to a team member</b> )
                                )}
                            </div>
                          </div>
                        )}

                        {isPermitted('view_defect_client_details') && !isEmpty(defect.client_issue_description) && (
                          <div className="flex flex-wrap border-b border-gray-100">
                            <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                              <p className="text-sm text-gray-700 font-semibold">
                                {user.role !== 'client' ? (
                                  "Description of Issue"
                                ) : (
                                  `${getDefectRaisedByTypeLabel(defect.defect_raised_by_type)} Issue Description`
                                )}
                              </p>
                            </div>
                            <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                              {defect.client_issue_description}
                            </div>
                          </div>
                        )}
                        {user.role !== 'client' && (
                          <div className="flex flex-wrap border-b border-gray-100">
                            <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                              <p className="text-sm text-gray-700 font-semibold">Defect Description</p>
                            </div>
                            <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3 mb-2 @md/view_defect:mb-0 text-gray-500 leading-relaxed">
                              {!isEmpty(defect.defect_description) ? defect.defect_description : "No description provided"}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="pt-4">
                      <div className="w-full @md/view_defect:px-3">
                        <div className="flex flex-wrap">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Markup</p>
                          </div>
                          <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3">
                            <div id="defect_markup_image" className="">
                              {!isEmpty(defect.floorplan_markup) ? (
                                <div className="photo-thumbnail cursor-pointer inline-block border border-gray-100 hover:border-gray-300 p-2 rounded-lg">
                                  <img id="markup-img"
                                       className="object-cover"
                                       src={defect.floorplan_markup_thumbnail}
                                       width="200"
                                       data-full-url={defect.floorplan_markup}
                                       data-full-width=""
                                       data-full-height=""
                                       alt=""/>
                                </div>
                              ) : (
                                !isEmpty(defect.floorplan_markup_canvas_json) ? (
                                  <p className="text-gray-500">Floorplan markup is available.</p>
                                ) : (
                                  <p className="text-gray-500">No floorplan markup has been provided for this defect.</p>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="py-4 border-t border-gray-100">
                      <div className="w-full @md/view_defect:px-3">
                        <div className="flex flex-wrap">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Photos</p>
                          </div>
                          <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3">
                            <div className="flex flex-wrap">
                              <DefectImagesDisplay {...imageProps()} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!isEmpty(defect.child_defects) && (
                      {/*{% include "partials/view_child_defects.html" %}*/ }
                    )}

                    <div className="py-4 border-t border-gray-100">
                      <div className="w-full @md/view_defect:px-3">
                        <div className="flex flex-wrap">
                          <div className="w-full @md/view_defect:w-1/3 pr-3 py-1 @md/view_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Comments</p>
                          </div>
                          <div className="w-full @md/view_defect:flex-1 pr-3 py-1 @md/view_defect:py-3">
                            <div className="flex flex-wrap">
                              <div id="comment-parent" className="flex flex-wrap w-full toggle-border">
                                {!isEmpty(defect.comments) ? (
                                  <>
                                    {!['client', 'trade', 'trade_admin'].includes(user.role) && (
                                      <div className="w-auto">
                                        <div className="flex flex-nowrap">
                                          <input type="radio" name="comment_visibility_type" id="all-comments" value="all" onChange={filterComment}
                                                 className="absolute h-0 w-0 appearance-none" defaultChecked={true}/>
                                          <label htmlFor="all-comments"
                                                 className="relative flex items-center text-center bg-white text-gray-500 px-4 py-1 sm:py-2 sm:my-2 border border-gray-200 hover:bg-gray-50 hover:text-green-500 border-r-0 shadow-sm cursor-pointer rounded-l-lg">
                                            <span className="font-semibold leading-tight">Show All</span>
                                          </label>
                                          <input type="radio" name="comment_visibility_type" id="internal-comments" value="internal" onChange={filterComment}
                                                 className="absolute h-0 w-0 appearance-none"/>
                                          <label htmlFor="internal-comments"
                                                 className="relative flex items-center text-center bg-white text-gray-500 px-4 py-1 sm:py-2 sm:my-2 border border-gray-200 hover:bg-gray-50 hover:text-green-500 border-r-0 shadow-sm cursor-pointer">
                                            <span className="font-semibold leading-tight">Internal Only</span>
                                          </label>
                                          <input type="radio" name="comment_visibility_type" id="client-comments" value="client" onChange={filterComment}
                                                 className="absolute h-0 w-0 appearance-none"/>
                                          <label htmlFor="client-comments"
                                                 className="relative flex items-center text-center bg-white text-gray-500 px-4 py-1 sm:py-2 sm:my-2 border border-gray-200 hover:bg-gray-50 hover:text-green-500 border-r-0 shadow-sm cursor-pointer">
                                            <span className="font-semibold leading-tight">Visible to Client</span>
                                          </label>
                                          <input type="radio" name="comment_visibility_type" id="trade-comments" value="trade" onChange={filterComment}
                                                 className="absolute h-0 w-0 appearance-none"/>
                                          <label htmlFor="trade-comments"
                                                 className="relative flex items-center text-center bg-white text-gray-500 px-4 py-1 sm:py-2 sm:my-2 border border-gray-200 hover:bg-gray-50 hover:text-green-500 shadow-sm cursor-pointer rounded-r-lg">
                                            <span className="font-semibold leading-tight">Visible to Trade</span>
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    <div className="w-full p-4">
                                      <ol className="border-l border-gray-300">
                                        {filteredComments.map((comment, i) => (
                                          <li className="comment" key={i} data-comment-visibility={isPermitted('view_defect_comment_visibility') ? comment.visibility : ''}>
                                            <div id={`comment_${comment.id}`} className="flex-start flex flex-wrap 2xl:flex-nowrap items-center pt-1.5">
                                              <div className="-ml-1.5 mr-3 h-2.5 w-2.5 rounded-full bg-gray-300"></div>
                                              <p className="text-sm text-gray-500 font-bold w-auto sm:w-full 2xl:w-auto">{getUserName(comment.author)}, {!isEmpty(comment.recorded_at) ? formatDate(comment.recorded_at) : "no time recorded"}</p>
                                              {!['client', 'trade', 'trade_admin'].includes(user.role) && (
                                                <>
                                                  <div className="mt-2 2xl:mt-0 ml-2 inline-block py-px px-2 text-sm font-medium text-gray-900 leading-5 bg-gray-100 rounded-xl">
                                                    {comment.visibility_description}
                                                  </div>
                                                  {comment.parent_defect_id != defect.id && (
                                                    <div className="mt-2 2xl:mt-0 ml-2 inline-block py-px px-2 text-sm font-medium text-gray-900 leading-5 bg-gray-100 rounded-xl">
                                                      {comment.parent_defect_id == defect.linked_parent_defect ? "Parent" : "Child"} Defect {comment.parent_defect}
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                            <div className="pb-2 sm:pb-6 ml-4">
                                              <p className="mb-3 text-gray-500">{comment.text}</p>
                                            </div>
                                          </li>
                                        ))}
                                      </ol>
                                    </div>
                                  </>
                                ) : (
                                  isPermitted('create_defect_comments') && (
                                    <p className="text-gray-500">No comments have been provided for this defect.</p>
                                  )
                                )}
                              </div>
                              {isPermitted('create_defect_comments') && !defect.committed_by_author && (
                                <>
                                  <div className="w-full">
                                    <textarea ref={commentTextarea}
                                              className="block w-full h-32 p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border border-gray-200 rounded-lg shadow-input resize-none"
                                              name="comment_text"></textarea>
                                    <div className="error"></div>
                                  </div>
                                  <div className="flex flex-nowrap mb-4 justify-between mt-2 items-start w-full">
                                    <div className="w-auto flex flex-nowrap">
                                      <button
                                        className="flex flex-nowrap items-center justify-center py-3 px-2 @sm/view_defect:px-4 text-base text-gray-500 font-medium bg-white border border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-green-500 rounded-md shadow-sm"
                                        onClick={recordComment}>
                                        <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0V0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
                                            fill="currentColor"></path>
                                        </svg>
                                        <span className="whitespace-nowrap">Add Comment</span>
                                      </button>
                                    </div>
                                    <div className="flex flex-col @sm/view_defect:flex-row flex-wrap justify-end items-end">
                                      {isPermitted('set_defect_comment_visibility') && (
                                        <div id="comment_visibility" className="relative">
                                          <div className="w-auto whitespace-nowrap">
                                            <input type="hidden" name="comment_visibility" value=""/>
                                            <button ref={commentVisibilityButton}
                                                    className="tooltip text-gray-500 bg-white border border-gray-200 hover:border-gray-300 hover:text-green-500 hover:bg-gray-50 shadow-sm focus:ring-2 focus:outline-none focus:ring-gray-400 font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex items-center"
                                                    type="button"
                                                    onClick={e => commentVisibilityDropdown.current.classList.toggle('hidden')}
                                                    data-tooltip="Please select who can see the comment">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-1"
                                                     aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"></path>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                                </svg>
                                                <span>{commentVisibilitySelectedLabel}</span>
                                            </button>
                                          </div>
                                          <div ref={commentVisibilityDropdown} className="z-10 bg-white hidden rounded-lg shadow w-auto absolute bottom-full right-0 border whitespace-nowrap">
                                              <ul className="max-h-60 py-2 overflow-y-auto text-gray-700 " aria-labelledby="DADropdownButton">
                                                  <li className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                                                      data-choice="internal"
                                                      onClick={setCommentVisibility}>Internal Comment</li>
                                                  <li className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                                                      data-choice="client"
                                                      onClick={setCommentVisibility}>Visible to Client</li>
                                                  <li className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                                                      data-choice="trade"
                                                      onClick={setCommentVisibility}>Visible to Trade</li>
                                                  <li className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                                                      data-choice="client + trade"
                                                      onClick={setCommentVisibility}>Visible to Client and Trade</li>
                                              </ul>
                                          </div>
                                        </div>
                                      )}
                                      {isPermitted('use_defect_comment_templates') && (
                                        <div id="comment_templates_list" className="relative ml-2">
                                          {/*{% include "partials/comment_templates.html" %}*/}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {( isPermitted('edit_defect_client_details') || isPermitted('edit_defect_details') ) && (
                        <div className="pt-6 border-t border-gray-100">
                          <div className="flex flex-wrap items-center justify-between -m-2">
                            <div className="w-full @md/view_defect:w-auto p-2">
                            </div>
                            <div className="w-full @md/view_defect:w-auto p-2">
                              <div className="flex flex-wrap justify-between @md/view_defect:justify-end -m-1.5">
                                {( isPermitted('edit_defect_client_details') || !isPermitted('edit_defect_details') ) && !defect.committed_by_author && (
                                  <div className="w-auto p-1.5">
                                    <Link to={`/properties/${propertyId}/rooms/${defect.room}/defects/${defectId}/update`}
                                          className="flex flex-wrap items-center justify-center py-2 px-3 w-full text-base text-gray-500 font-medium bg-white border border-gray-200 hover:border-gray-300 rounded-md shadow-sm hover:bg-gray-50 hover:text-green-500">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mr-2" fill="currentColor">
                                        <path
                                          d="m14.06 9.02.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"></path>
                                      </svg>
                                      <span>Edit Defect</span>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : ( !isEmpty(defectId) ? "Loading defect..." : "Please provide a valid defectId" )}
            </div>
      </div>
      </div>
      </div>
  )
}

export default ViewDefectPage
