import React, {useEffect, useRef, useState} from 'react'
import {isEmpty, deviceDetector, getDefectStorageInfo, haveDefectUnsyncedChanges, getBrowserInfo, topNavFix} from "../utils";
import {formatDistance, set} from "date-fns";
import configDataStore from "../data-stores/configDataStore";
import defectDataStore from '../data-stores/defectDataStore';
import DefectSyncingModal from '../components/defectSyncingModal';

const TopNavigation = ({ pageTitle, backUrl, backBtnClickHandler, backUrlLabel, mainDivRef, mainContentSectionRef, hideSyncButton = false}) => {

  const { settings } = configDataStore()
  const topHeader = useRef(null)
  const syncBtn = useRef(null)
  const syncWrapper = useRef(null)
  const [storageInfo, setStorageInfo] = useState('')
  const [lastFetched, setLastFetched] = useState('')
  const [unSyncedDefects, setUnSyncedDefects] = useState([])
  const { defects, updateSyncedDefects } = defectDataStore()
  const [showSyncingModal, setShowSyncingModal] = useState(false)
  const resizeInProgress = useRef(false)


  useEffect(() => {
    // trigger when viewport changes size, to cater for virtual keyboard popping up.
    // in iOS, the header does not return after the virtual keyboard closes, this hack fixes that.
    if (mainDivRef && mainContentSectionRef && topHeader && 'visualViewport' in window) {
      const browserInfo = getBrowserInfo(), 
      VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75

      if (browserInfo.iOSSafari) {
        window.visualViewport.addEventListener('resize', function (event) {
          if (resizeInProgress.current) {
            return
          }
          if (( ( event.target.height * event.target.scale ) / window.screen.height ) < VIEWPORT_VS_CLIENT_HEIGHT_RATIO) {
            // keyboard is shown
          } else {
            resizeInProgress.current = true
            topNavFix(resizeInProgress)
          } 
        });
      }
    }
  }, [mainDivRef, mainContentSectionRef, topHeader])

  
  const syncBtnHandler = (e) => {
    syncWrapper.current?.querySelector('#sync-content')?.classList.toggle('hidden')
    syncWrapper.current?.querySelector('#sync-icon-show')?.classList.toggle('hidden')
    syncWrapper.current?.querySelector('#sync-icon-close')?.classList.toggle('hidden')
  }

  useEffect(() => {
    setUnSyncedDefects(prevState => defects.filter(d => haveDefectUnsyncedChanges(d)))
  }, [defects])

  useEffect(() => {
    // Subscribe to defect changes
    const unsubscribe = defectDataStore.subscribe((state) => {
      setUnSyncedDefects(state.defects.filter(d => haveDefectUnsyncedChanges(d)))
    })

    // Cleanup subscription on component unmount
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    getDefectStorageInfo().then(estimate => {
      if (estimate && estimate.usage >= 0) {
        let remainingBytes = estimate.quota - estimate.usage
        let remainingSpace = remainingBytes > Math.pow(1024, 3) ? `${Math.floor(remainingBytes / Math.pow(1024, 3))} GB` : `${Math.floor(remainingBytes / Math.pow(1024, 3))} MB`
        setStorageInfo(remainingSpace)
      } else {
        setStorageInfo('Unable to fetch storage info')
      }
    })
    syncBtn.current?.addEventListener('click', syncBtnHandler)
  }, [])

  useEffect(() => {
    let timeSinceFetch = null
    if (settings.last_data_updated) {
      timeSinceFetch = formatDistance(new Date(settings.last_data_updated), new Date(), { addSuffix: true })
    }
    setLastFetched(timeSinceFetch)
  }, [])

  const showDefectSyncModal = () => {
    hideSyncButton = true
    setShowSyncingModal(true)
  }

  return (
    <React.Fragment>
    <nav className="flex justify-between p-4 lg:py-2 shadow-sm z-50" ref={topHeader} id="top-header">
      <div className="flex flex-start items-center">
        <div className="w-auto">
          <button id="navbar-burger" className="lg:hidden inline-flex items-center p-1 text-gray-800 text-sm font-medium bg-gray-100 border border-gray-200
            hover:border-gray-300 rounded-md shadow-sm" title="Menu">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
            </svg>
          </button>
          <div className="inline-block px-2 hidden lg:block">
            <img src={process.env.PUBLIC_URL + '/assets/images/DefectBuddy-with-name-wide-60h.png'} alt="DefectBuddy"/>
          </div>
        </div>
      </div>
      <div className="flex flex-1 justify-center items-center">
        <h2 className="text-md md:text-lg text-gray-900 font-semibold text-center">{pageTitle}</h2>
      </div>
      <div className="flex flex-end justify-end items-center">
        {!hideSyncButton && (
        <div className="relative mr-4 mb-0" ref={syncWrapper}>
          <button id="sync-btn"
                  ref={syncBtn}
                  className="m-0 inline-flex items-center px-3 py-1 text-gray-500 text-sm font-medium bg-white border border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-green-500 rounded-md shadow-sm"
                  title="Sync Defects">
                    
            <svg id="sync-icon-show" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="mr-2 w-6 h-6" fill="currentColor">
              <path
                d="M13.03 18C13.08 18.7 13.24 19.38 13.5 20H6.5C5 20 3.69 19.5 2.61 18.43C1.54 17.38 1 16.09 1 14.58C1 13.28 1.39 12.12 2.17 11.1S4 9.43 5.25 9.15C5.67 7.62 6.5 6.38 7.75 5.43S10.42 4 12 4C13.95 4 15.6 4.68 16.96 6.04C18.32 7.4 19 9.05 19 11C19.04 11 19.07 11 19.1 11C18.36 11.07 17.65 11.23 17 11.5V11C17 9.62 16.5 8.44 15.54 7.46C14.56 6.5 13.38 6 12 6S9.44 6.5 8.46 7.46C7.5 8.44 7 9.62 7 11H6.5C5.53 11 4.71 11.34 4.03 12.03C3.34 12.71 3 13.53 3 14.5S3.34 16.29 4.03 17C4.71 17.66 5.53 18 6.5 18H13.03M19 13.5V12L16.75 14.25L19 16.5V15C20.38 15 21.5 16.12 21.5 17.5C21.5 17.9 21.41 18.28 21.24 18.62L22.33 19.71C22.75 19.08 23 18.32 23 17.5C23 15.29 21.21 13.5 19 13.5M19 20C17.62 20 16.5 18.88 16.5 17.5C16.5 17.1 16.59 16.72 16.76 16.38L15.67 15.29C15.25 15.92 15 16.68 15 17.5C15 19.71 16.79 21.5 19 21.5V23L21.25 20.75L19 18.5V20Z"/>
            </svg>
            <svg id="sync-icon-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="mr-2 w-6 h-6 hidden" fill="currentColor">
              <path
                d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
            </svg>
            <div className="md:mr-2 inline-block px-2.5 text-center 2xl:mb-0 text-green-700 bg-green-100 font-medium rounded-9xl border border-green-500">
                {unSyncedDefects.length}
            </div>
            <span className="hidden md:block">
              Sync Defects
            </span>
          </button>
          <div id="sync-content"
               className="hidden fixed left-0 w-full md:left-auto md:w-128 md:absolute md:right-0 mt-0 bg-white rounded-md shadow-lg z-99 border-2 border-gray-300 w-96 md:w-144 p-2.5 md:p-4">
            <div className="flex flex-wrap items-center justify-between">
              <div className="w-auto py-1 text-gray-900 font-semibold">
                Defects ready to sync to Cloud:
                <div className="inline-block mx-2 px-2.5 text-center mb-2 2xl:mb-0 text-green-700 bg-green-100 font-medium rounded-9xl border border-green-500">
                  {unSyncedDefects.length}
                </div>
              </div>
              <div className="w-auto py-1">
                {!isEmpty(unSyncedDefects) && (
                  <button id="bulk_sync"
                          onClick={showDefectSyncModal}
                          className="flex flex-wrap justify-center w-auto px-4 py-2 text-white font-medium border bg-green-500 hover:bg-green-600 border-gray-200 hover:border-gray-300 rounded-md shadow-sm">
                    Sync All Defects
                  </button>
                )}
              </div>
              <div className="w-full pt-2 text-gray-900">
              Space available space on this {deviceDetector().device} to capture defects:
                <div className="inline-block px-2.5 text-center mb-2 2xl:mb-0 text-green-700 bg-green-100 font-medium rounded-9xl border border-green-500 ml-2">
                  {storageInfo}
                </div>
                
              </div>
              {lastFetched && 
              <div className="w-full py-1 text-gray-900">
                DefectBuddy configuration last fetched <b>{lastFetched}</b>
              </div>
              }
            </div>
          </div>
        </div>
        )}
        <div className="relative">
          {!isEmpty(backUrl) &&
            <a href={backUrl}
               onClick={backBtnClickHandler || (e => {})}
               className="inline-flex items-center px-3 py-1 text-gray-500 text-sm font-medium bg-white border border-gray-200 hover:bg-gray-50 hover:border-gray-300 hover:text-green-500 rounded-md shadow-sm">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="mr-2 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path>
              </svg>
              <span className="md:hidden">Back</span>
              <span id="main-back-btn-desc" className="hidden md:block">{!isEmpty(backUrlLabel) ? backUrlLabel : 'Back'}</span>
            </a>
          }
        </div>
      </div>
    </nav>    
    <DefectSyncingModal defects={defects} showSyncingModal={showSyncingModal} setShowSyncingModal={setShowSyncingModal} 
      setUnSyncedDefects={setUnSyncedDefects} updateSyncedDefects={updateSyncedDefects}  />
    </React.Fragment>
  )
}

export default TopNavigation
