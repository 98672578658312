import {addDays, addMonths, addYears, isAfter} from 'date-fns'
import configDataStore from './data-stores/configDataStore'

export const isEmpty = (value) => {
  if (typeof value == 'undefined') {
    return true;
  }

  if (value == null || value === 0 || value === false) {
    // null or undefined, 0 and false values
    return true;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    // String or Array
    return value.length === 0;
  }

  if (typeof value === 'object') {
    // Object (including plain objects and instances of classes)
    return Object.keys(value).length === 0;
  }

  return false;
}

export const getCache = (name = '') => {
  let cache = localStorage.getItem(name)
  if (cache) {
    try {
      cache = JSON.parse(cache)
      return cache
    } catch (e) {
    }
  }

  return cache
}

export const getApiUrl = () => {
  return getDjangoAppBaseUrl() + '/api/'
}

export const getDjangoAppBaseUrl = () => {
  let apiUrl = configDataStore.getState().settings.apiUrl
  if (apiUrl?.includes('/api/')) {
    apiUrl = apiUrl.replace('/api/', '')
  }
  if (process.env.REACT_APP_DJANGO_URL) {
    apiUrl = process.env.REACT_APP_DJANGO_URL
  }
  if (!isEmpty(apiUrl)) {
    // Remove any leading/trailing whitespace
    apiUrl = apiUrl.trim()

    // Add trailing slash if not present
    if (apiUrl.endsWith('/')) {
      apiUrl = apiUrl.left(apiUrl, apiUrl.length - 1)
    }
    if (!apiUrl.includes('://')) {
      apiUrl = 'https://' + apiUrl
    }
    return apiUrl
  }

  return 'http://127.0.0.1:9000'
}

export const getSetting = (name) => {
  let settings = getCache('settings')

  return settings ? settings[ name ] : null
}

export const fetchApiData = async (data = { type: 'projects', project_id: 0, property_id: 0, images_size: 'thumbnail' }) => {
  let url = getApiUrl()
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${localStorage.getItem('token')}`
  }

  if (data.type === 'properties') {

    if (data.project_id !== 0) url += 'projects/' + data.project_id + '/properties'
    else url += 'properties'

  } else if (data.type === 'defects') {

    let project_id = data.project_id ?? 0
    let property_id = data.property_id ?? 0
    let room_id = data.room_id ?? 0
    let images_size = data.images_size ?? 'thumbnail'

    if (project_id !== 0)
      url += `projects/${project_id}/defects`
    else if (property_id !== 0)
      url += `properties/${property_id}/defects`
    else if (room_id !== 0)
      url += `rooms/${room_id}/defects`
    else
      url += data.type

    url += `?images_size=${images_size}`

  } else if (data.type === 'rooms') {
    url += 'rooms'
    if (data.property_ids && Array.isArray(data.property_ids) && data.property_ids.length > 0) {
      let propQuery = data.property_ids.join('&prop=')
      url += `?prop=${propQuery}`
    }
  } else if (data.type === 'roomsWithDefects') {
    if (!data.property_id)
      if (!data.room_id)
        return []
    url += data.room_id ? `rooms/${data.room_id}` : `properties/${data.property_id}/rooms`

  } else if (data.type === 'catsTrades') {
    url+= 'categories-and-trades'
  } else {
    url += data.type
  }

  const response = await fetch(url, {
    headers: headers
  })

  if (response.ok) {
    return await response.json()
  }

  console.log(response)
  return false
}

export const fetchProjects = async () => {
  const response = await fetchApiData({ type: 'projects' })

  if (response) {
    // Cache the fetched data in localStorage for offline usage
    // localStorage.setItem('projects', JSON.stringify(response))
  }

  return response
}

export const fetchProperties = async (project_id = 0) => {
  const response = await fetchApiData({ type: 'properties', project_id })

  if (response) {
    // Cache the fetched data in localStorage for offline usage
    // localStorage.setItem('properties', JSON.stringify(response))
  }

  return response
}

export const fetchLocations = async (propertyIds = []) => {
  try {
    // Fetch data from API endpoint
    const response = await fetchApiData({ type: 'rooms', property_ids: propertyIds })

    if (response) {
      // Cache the fetched data in localStorage for offline usage
      // let cachedRooms = getCache('rooms')
      // if (!cachedRooms || cachedRooms.length === 0) {
      //   localStorage.setItem('rooms', JSON.stringify(response))
      // } else {
      //   let otherRooms = cachedRooms.filter(room => !propertyIds.includes(room.building_property))
      //
      //   cachedRooms = [...otherRooms, ...response]
      //
      //   localStorage.setItem('rooms', JSON.stringify(cachedRooms))
      // }
    }

    return response

  } catch (error) {
    console.error(error)
    return []
  }
}

export const fetchLocationsWithDefects = async (propertyId = 0) => {
  propertyId = parseInt(propertyId)

  try {
    // Fetch data from API endpoint
    const response = await fetchApiData({ type: 'roomsWithDefects', property_id: propertyId })

    if (response) {
      // Cache the fetched data in localStorage for offline usage
      let cachedLocations = getCache('locations')
      if (!cachedLocations || cachedLocations.length === 0) {
        localStorage.setItem('locations', JSON.stringify(response))
      } else {
        let otherLocations = cachedLocations.filter(location => location.room.building_property !== propertyId)

        cachedLocations = [...otherLocations, ...response]

        localStorage.setItem('locations', JSON.stringify(cachedLocations))
      }
    }

    return response

  } catch (error) {
    console.error(error)
  }
}

export const getAllDefectRaisedByTypes = () => {
  return [
    { value: 'developer', label: 'Developer' },
    { value: 'purchaser', label: 'Purchaser' },
    { value: 'tenant', label: 'Tenant' },
  ]
}

export const getDefectRaisedByTypes = (property, permissions) => {
  if (isEmpty(property) || isEmpty(permissions)) {
    return []
  }
  console.log(permissions.defect_raised_by_types_by_property_id[ property.id ])
  const defect_raised_by_types = []
  permissions.defect_raised_by_types_by_property_id[ property.id ].forEach((raisedByType) => {
    defect_raised_by_types.push({ value: raisedByType, label: getDefectRaisedByTypeLabel(raisedByType) })
  });

  console.log(defect_raised_by_types)
  return defect_raised_by_types
}

export const getDefectRaisedByTypeLabel = (raiseByType) => {
  if (isEmpty(raiseByType)) {
    return ''
  }
  let raisedByTypeLabel = getAllDefectRaisedByTypes().find(_raiseByType => _raiseByType.value === raiseByType)?.label
  console.log(`raisedByTypeLabel=${raisedByTypeLabel}`)
  return !isEmpty(raisedByTypeLabel) ? raisedByTypeLabel : raiseByType
}

export const defectStatusTypes = {
  DRAFT: 'draft',
  CLIENT_ISSUE_SUBMITTED_FOR_REVIEW: 'client_issue_submitted_for_review',
  READY_TO_SEND_TO_TRADE: 'ready_to_send_to_trade',
  SENT_TO_TRADE: 'sent_to_trade',
  ACKNOWLEDGED_BY_TRADE: 'acknowledged_by_trade',
  CONTESTED_BY_TRADE: 'contested_by_trade',
  WIP: 'wip',
  ONHOLD: 'onhold',
  SUBMITTED_FOR_COMPLETION: 'submitted_for_completion',
  COMPLETED: 'completed',
  CLOSED_NO_FURTHER_ACTION: 'closed_no_further_action'
}

export const defectStatusTypeChoices = [
  { value: 'draft', label: 'Draft' },
  { value: 'client_issue_submitted_for_review', label: 'Submitted for Review' },
  { value: 'ready_to_send_to_trade', label: 'Ready to Send to Trade' },
  { value: 'sent_to_trade', label: 'Sent to Trade' },
  { value: 'acknowledged_by_trade', label: 'Acknowledged by Trade' },
  { value: 'contested_by_trade', label: 'Defect Contested by Trade' },
  { value: 'wip', label: 'Work in Progress' },
  { value: 'onhold', label: 'Waiting for Materials/Trade' },
  { value: 'submitted_for_completion', label: 'Submitted for completion' },
  { value: 'completed', label: 'Completed' },
  { value: 'closed_no_further_action', label: 'Closed, No Further Action' }
]

export const getAvailableDefectStatuses = (defect, user, property) => {
  let availableDefectStatus = [defect.current_status ?? defectStatusTypes.DRAFT]

  if (user.role === 'client') {
    if (defect.current_status === defectStatusTypes.DRAFT) {
      availableDefectStatus.push(defectStatusTypes.CLIENT_ISSUE_SUBMITTED_FOR_REVIEW)
    }
  } else if (user.role === 'trade' || user.role === 'trade_admin') {
    availableDefectStatus.push(
      defectStatusTypes.ACKNOWLEDGED_BY_TRADE,
      defectStatusTypes.WIP,
      defectStatusTypes.SUBMITTED_FOR_COMPLETION
    )
  } else if (user.role === 'settlements') {
    availableDefectStatus.push(
      defectStatusTypes.READY_TO_SEND_TO_TRADE,
      defectStatusTypes.CLOSED_NO_FURTHER_ACTION
    )
  } else if (user.role === 'construction') {
    availableDefectStatus.push(
      defectStatusTypes.READY_TO_SEND_TO_TRADE,
      defectStatusTypes.SENT_TO_TRADE,
      defectStatusTypes.COMPLETED,
      defectStatusTypes.CLOSED_NO_FURTHER_ACTION
    )
    if (property?.construction_completed_date ||
      property?.occupation_certificate_date ||
      property?.settlement_date) {
      availableDefectStatus.push(
        defectStatusTypes.ACKNOWLEDGED_BY_TRADE,
        defectStatusTypes.WIP,
        defectStatusTypes.ONHOLD,
        defectStatusTypes.SUBMITTED_FOR_COMPLETION
      )
    }
  } else if (['admin', 'super_user', 'building_manager'].includes(user.role)) {
    defectStatusTypeChoices.forEach(status => {
      if (status.value !== defectStatusTypes.CLIENT_ISSUE_SUBMITTED_FOR_REVIEW &&
        ( status.value !== defectStatusTypes.DRAFT && status.value !== defectStatusTypes.READY_TO_SEND_TO_TRADE ||
          defect.current_status === defectStatusTypes.DRAFT ||
          defect.current_status === defectStatusTypes.READY_TO_SEND_TO_TRADE )) {
        availableDefectStatus.push(status.value)
      }
    })
  }

  const uniqueStatuses = [...new Set(availableDefectStatus)]
  return defectStatusTypeChoices.filter(status => uniqueStatuses.includes(status.value))
}

export const getDefectStatusLabel = (status) => {
  if (isEmpty(status)) {
    return ''
  }

  let statusLabel = defectStatusTypeChoices.find(s => s.value === status)?.label

  return statusLabel ?? status
}

export const propertySearchName = (property) => {
  if (isEmpty(property)) {
    return null
  }
  return property.unit_number ?? property.lot_number ?? property.name
}

export const generateDefectNumber = (id) => {
  return `DA-${String(id).padStart(6, '0')}`;
}

export const formatDate = (dateString) => {
  if (isEmpty(dateString)) {
    return ''
  }
  const date = new Date(dateString)
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
  return new Intl.DateTimeFormat('en-AU', options).format(date)
}

export const getRoom = (roomId) => {
  if (isEmpty(roomId)) {
    return {}
  }

  let locations = configDataStore.getState().locations
  if (isEmpty(locations)) {
    return {}
  }

  let room = locations.find(r => r.id === parseInt(roomId))

  return room ?? {}
}

export const getRooms = (propertyId) => {
  if (isEmpty(propertyId)) {
    return []
  }

  propertyId = parseInt(propertyId)

  let locations = configDataStore.getState().locations

  if (locations.length === 0) {
    return []
  }

  // We have the locations stored, but need the specific property rooms in it
  return locations.filter(room => room.building_property === propertyId)
}

export const saveOrphanDefect = (defect) => {
  if (!defect) return

  let orphanDefects = localStorage.getItem('orphan_defects')
  if (orphanDefects) {
    orphanDefects = JSON.parse(orphanDefects)
    let found = false
    orphanDefects.some(_defect => {
      if (defect.id === _defect.id) {
        _defect = defect
        found = true
        return true
      }
    })
    localStorage.setItem('orphan_defects', JSON.stringify(orphanDefects))
  } else {
    localStorage.setItem('orphan_defects', JSON.stringify([defect]))
  }
}

export const getOrphanDefect = (defectId) => {
  if (!defectId) return false

  let orphanDefects = localStorage.getItem('orphan_defects')
  if (orphanDefects) {
    orphanDefects = JSON.parse(orphanDefects)
    let found = false
    orphanDefects.some(_defect => {
      if (defectId === _defect.id) {
        found = _defect
        return true
      }
    })
    return found
  }

  return null
}

export const deleteFloorplanImageFromCache = async (image) => {
  const cacheName = configDataStore.getState().floorplanImageCache
  const cache = await caches.open(cacheName)
  const response = await cache.match(`/cached-images/${image.uniqueFilename}`)
  if (response) {
    const blob = await response.blob()
    const file = createBlobFile(blob, image.name, image.uniqueFilename, image.originalFilename)
    return URL.createObjectURL(file)
  }
  return null
}

export const getImageUrlFromCache = async (image) => {
  const cacheName = configDataStore.getState().imageCacheName
  const cache = await caches.open(cacheName)
  const response = await cache.match(`/cached-images/${image.uniqueFilename}`)
  if (response) {
    const blob = await response.blob()
    const file = createBlobFile(blob, image.name, image.uniqueFilename, image.originalFilename)
    return URL.createObjectURL(file)
  }
  return null
}

export const getImageMarkup = (defect, fileName) => {
  if (isEmpty(defect) || isEmpty(fileName)) {
    return false
  }

  return !isEmpty(defect.images_markup) ? defect.images_markup[ fileName ] : false
}

export const haveDefectUnsyncedChanges = (defect) => {
    if (Object.keys(defect).length === 0) {
      return false
    }

    if (!defect.committed_by_author) {
      return true
    }

    if (!isEmpty(defect.comments)) {
      let unsavedComment = defect.comments.find(comment => comment.syncedToServer === false)
      if (unsavedComment)
        return true
    }

    return false
  }

export const isMobileOrTablet = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const truncateString = (str, n) => {
  return (str.length > n) ? str.slice(0, n-1) + '&hellip;' : str;
}

export const syncDefect = async (defect, signal = null, purgeCache = true) => {
  if (isEmpty(defect)) {
    console.log('Calling syncDefect, defect is null')
    return null
  }
  console.log('Calling syncDefect, defect.id=' + defect.id)

  // Getting cached images
  const cacheName = configDataStore.getState().imageCacheName
  const cache = await caches.open(cacheName)
  const formData = new FormData()
  // Getting defect images from the cache
  if (!isEmpty(defect.images)) {
    for (const image of defect.images) {
      const response = await cache.match(`/cached-images/${image.uniqueFilename}`)
      if (response) {
        const blob = await response.blob()
        formData.append('defect_image_upload', blob, image.uniqueFilename)  // With the uniqueFilename, it'll be possible to track its markup
      }
    }
  }

  // Setting other defect elements
  for (const key in defect) {
    if (key === 'comments') {
      try {
        formData.append(key, JSON.stringify(defect[ key ]))
      } catch (e) {
      }

    } else if (key === 'images_markup') {
      formData.append(key, JSON.stringify(defect[ key ]))

    } else if (key !== 'images' || key !== 'marked_images') {
      formData.append(key, defect[ key ])
    }
  }

  
  const url = getApiUrl() + 'save-defect'
  // Send formData to the server
  let fetchOptions = {
    headers: {'Authorization': `Token ${localStorage.getItem('token')}`},
    method: 'POST',
    body: formData
  }

  if (signal) {
    fetchOptions.signal = signal
  }

  let response = await fetch(url, fetchOptions)

  if (response.ok && response.status <= 200) {
    if (purgeCache && !isEmpty(defect.images)) {
      for (const image of defect.images) {
        const cacheName = configDataStore.getState().imageCacheName
        const cache = await caches.open(cacheName)
        await cache.delete(`/cached-images/${image.uniqueFilename}`)
      }
    }
    return await response.json()
  }

  if(!response.error) {
    response.error = response.status === 500 ? 'Server side error occurred. Please contact Defect Buddy admin.' : 'An error occurred. Please contact Defect Buddy admin'
  }
  console.log(response)
  return response
}

export const getProjectFromDefect = (defect) => {
  if (isEmpty(defect)) {
    return {}
  }

  let propertyId = defect.building_property_id ?? defect.building_property ?? 0

  let properties = configDataStore.getState().properties
  let projects = configDataStore.getState().projects

  let property = properties.find(prop => prop.id === parseInt(propertyId))

  return projects.find(project => project.id === parseInt(property?.building_project))
}

export const getProperty = (roomId = 0) => {
  roomId = parseInt(roomId)
  try {
    let property = null
    let cachedProperties = getCache('properties')
    if (cachedProperties?.length) {
      cachedProperties.some(_property => {
        if (_property.id === roomId) {
          property = _property
          return true  // Closing the loop
        }
        return false
      })
    }

    if (!property) {
      // We need to fetch it from the server

    }
  } catch (error) {
  }
}

export const addTemporalUnitToDate = (date, temporalUnit, amount) => {
  let newDate = new Date(date);
  if (amount !== 0) {
    if (temporalUnit === 'DAY') {
      newDate = addDays(newDate, amount)
    } else if (temporalUnit === 'MONTH') {
      newDate = addMonths(newDate, amount)
    } else if (temporalUnit === 'YEAR') {
      newDate = addYears(newDate, amount)
    }
  }
  return newDate
}

export const getAvailableCategories = (defect, property, allCategories) => {
  if (isEmpty(defect) || isEmpty(property) || isEmpty(allCategories)) {
    return []
  }

  let availableCategories = []
  allCategories.forEach(category => {
    let categoryOption = {value:category.id, label:category.name}
    if (defect.defect_category !== category && category.applicable_x_units_post_reference_date &&
      category.applicable_reference_date_unit && category.applicable_reference_date) {
      let refDate = null
      if (category.applicable_reference_date === 'Construction Completed Date' && property.construction_completed_date) {
        refDate = new Date(property.construction_completed_date)
      } else if (category.applicable_reference_date === 'Occupation Certificate Date' && property.occupation_certificate_date) {
        refDate = new Date(property.occupation_certificate_date)
      } else if (category.applicable_reference_date === 'Settlement Date' && property.settlement_date) {
        refDate = new Date(property.settlement_date)
      }

      // console.debug(`refdate=${refDate}`)
      if (refDate) {
        const endDate = addTemporalUnitToDate(refDate, category.applicable_reference_date_unit, category.applicable_x_units_post_reference_date)
        const defect_recorded_date = defect.recorded_at ? new Date(defect.recorded_at) : null
        const now = new Date()

        if (defect_recorded_date) {
          if (isAfter(endDate, defect_recorded_date)) {
            availableCategories.push(categoryOption)
          }
        }
        if (isAfter(endDate, now)) {
          availableCategories.push(categoryOption)
        }
      } else {
        availableCategories.push(categoryOption)
      }
    } else {
      availableCategories.push(categoryOption)
    }
  })

  return availableCategories
}

export const getCategoryTrades = (categoryId = 0, trades = {}) => {
  if (isEmpty(categoryId) || isEmpty(trades)) {
    return []
  }

  return trades[ categoryId ] ?? []
}

export const getCategory = (defect) => {
  if (isEmpty(defect)) {
    return {}
  }

  let defectCategoryId = defect.defect_category_id ?? defect.defect_category ?? 0
  if (isEmpty(defectCategoryId)) {
    return {}
  }

  let allCategories = configDataStore.getState().categories

  let category = allCategories.find(cat => cat.id == defectCategoryId)

  return !isEmpty(category) ? category : {}
}

export const getCategoryName = (defect) => {
  let category = getCategory(defect)
  return !isEmpty(category) ? category.name : null
}

export const getAssignedTrade = (defect) => {
  if (isEmpty(defect)) {
    return {}
  }
  let tradePractitionerId = defect.trade_practitioner_id ?? defect.allocated_trade_practitioner ?? 0
  if (isEmpty(tradePractitionerId)) {
    return {}
  }
  return getTradeById(tradePractitionerId)
}

export const getTradeById = (tradePractitionerId) => {
  let allAvailableTrades = configDataStore.getState().allAvailableTrades
  let trade = Object.values(allAvailableTrades).find(t => t.id == tradePractitionerId)
  if (Array.isArray(trade)) {
    trade = trade.find(t => t.id == tradePractitionerId)
  }

  return !isEmpty(trade) ? trade : {}
}

export const getAssignedTradeName = (defect) => {
  let trade = getAssignedTrade(defect)
  return !isEmpty(trade) ? trade.business_name : null
}

export const getAssignedRemedialContractor = (defect) => {
  if (isEmpty(defect)) {
    return {}
  }
  if (isEmpty(defect.remedial_trade_company)) {
    return {}
  }
  return getTradeById(defect.remedial_trade_company)
}

export const getAssignedRemedialContractorName = (defect) => {
  let trade = getAssignedRemedialContractor(defect)
  return !isEmpty(trade) ? trade.business_name : null
}

const createBlobFile = (blob, filename, uniqueFilename, originalFilename) => {
  let f = new File([blob], filename, { type: blob.type })
  f.uniqueFilename = uniqueFilename
  f.originalFilename = originalFilename ?? filename
  return f
}

// TODO: May be able to remove this, as it's not used anywhere
export const getImageFromCache = async (image) => {
  const cacheName = configDataStore.getState().imageCacheName
  const cache = await caches.open(cacheName)
  const response = await cache.match(`/cached-images/${image.uniqueFilename}`)

  if (response) {
    const blob = await response.blob()
    return createBlobFile(blob, image.name, image.uniqueFilename, image.originalFilename)
  }

  return null
}

export const getDefectImagesFromCache = async (defect) => {
  if (isEmpty(defect?.images)) {
    return []
  }

  const cacheName = configDataStore.getState().imageCacheName
  const cache = await caches.open(cacheName)
  let images = []

  for (const image of defect.images) {
    const response = await cache.match(`/cached-images/${image.uniqueFilename}`)
    if (response) {
      const blob = await response.blob()
      const file = createBlobFile(blob, image.name, image.uniqueFilename, image.originalFilename)
      images.push(file)
    }
  }

  return images
}

export const optimizeDefectForServer = (defect) => {
  if (isEmpty(defect)) {
    return
  }

  if (defect.building_property) {
    defect.building_property_id = defect.building_property
  }

  if (defect.allocated_trade_practitioner) {
    defect.trade_practitioner_id = defect.allocated_trade_practitioner
  }

  if (defect.remedial_trade_company) {
    defect.assign_to_remedial_contractor = true
    defect.remedial_contractor_id = defect.remedial_trade_company
  }

  if (defect.defect_category) {
    defect.defect_category_id = defect.defect_category
  }

  if (isEmpty(defect.images)) {
    defect.images = []
  }

  if (isEmpty(defect.estimated_completion_date)) {
    delete ( defect.estimated_completion_date )
  }

  if (isEmpty(defect.point_of_contact_email)) {
    delete ( defect.point_of_contact_email )
  }

  if (isEmpty(defect.point_of_contact_portal_user)) {
    defect.point_of_contact_portal_user = 'other'
  }

  return defect
}

export const strLinesCount = (str) => {
  return !isEmpty(str) ? str.split(/\r\n|\r|\n/).length : 0
}

//https://github.com/PoeHaH/devicedetector/blob/master/devicedetector-production.js
export const deviceDetector = () => {
	var ua = navigator.userAgent.toLowerCase();
	var detect = (function(s) {
		if(s===undefined) {
      s=ua;
    } else {
      ua = s.toLowerCase();
    }
		if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)) {
      return 'tablet';
    } else if(/(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(ua)) {
      return 'phone';
    } 
    return 'desktop';
  });
  return{
      device:detect(),
      detect:detect,
      isMobile:((detect()!='desktop')?true:false),
      userAgent:ua
  };
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const storageEstimateWrapper = () => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    // We've got the real thing! Return its response.
    return navigator.storage.estimate();
  }

  if ('webkitTemporaryStorage' in navigator &&
      'queryUsageAndQuota' in navigator.webkitTemporaryStorage) {
    // Return a promise-based wrapper that will follow the expected interface.
    return new Promise(function(resolve, reject) {
      navigator.webkitTemporaryStorage.queryUsageAndQuota(
        function(usage, quota) {resolve({usage: usage, quota: quota})},
        reject
      );
    });
  }

  // If we can't estimate the values, return a Promise that resolves with NaN.
  return Promise.resolve({usage: NaN, quota: NaN});
}

export const getDefectStorageInfo = () => {
  

  return storageEstimateWrapper().then(function(estimate) {
      return estimate;
  }).catch(error => {
      return 'Error retrieving storage info';
  });
}

export const validateEmail = (emailAddress) => {
  return String(emailAddress)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const getBrowserInfo = () => {
  let ua = window.navigator.userAgent,
      iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i),
      webkit = !!ua.match(/WebKit/i),
      iOSSafari = iOS && webkit && !ua.match(/CriOS/i)

  let browserInfo = {iOS: iOS, iOSSafari: iOSSafari, webkit: webkit}
  if (iOSSafari) {
    const match = /Version\/(\d+\.\d+)/.exec(navigator.userAgent)
    browserInfo.iOSVersion = match ? parseFloat(match[1]) : null
  }
  return browserInfo
}

// in iOS, the header does not return after the virtual keyboard closes, this hack fixes that.
export const topNavFix = (resizeInProgress = null, functionToCallAfter) => {
  const browserInfo = getBrowserInfo()
  let mainDiv = document.getElementById('maindiv'),
      mainContentSection = document.getElementById('main-content-section'),
      topHeader = document.getElementById('top-header')
  
  if (mainDiv && mainContentSection && topHeader) {
    if (browserInfo.iOSSafari) {
      if (browserInfo.iOSVersion >= 17) {
        mainDiv.classList.add('hidden')
        topHeader.classList.add('fixed', 'bg-white', 'w-full')
      } else {
          topHeader.classList.add('fixed', 'bg-white', 'w-full')
          mainContentSection.style.paddingTop = (topHeader.offsetHeight) + 'px'
      }
      setTimeout(function (){
          topHeader.classList.remove('fixed', 'bg-white', 'w-full')
          mainDiv.classList.remove('hidden')
          if (resizeInProgress) {
            resizeInProgress.current = false
          }
          if (functionToCallAfter) {
            functionToCallAfter()
          }
      }, 1)
    }
  }
}