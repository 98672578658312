import React, {useEffect, useRef} from 'react'
import { SpinnerCircularFixed } from 'spinners-react';


const SpinnerModal = ({ title = "processing" }) => {

  const spinnerModal = useRef(null)

  useEffect(() => {
  }, [])

  return (
    <div className="z-101 fixed flex-col top-0 left-0 right-0 bottom-0 flex justify-center items-center p-4 bg-gray-800 bg-opacity-80" ref={spinnerModal}>
          <h3 className="mb-2 text-2xl font-semibold text-white">{title}</h3>
          <SpinnerCircularFixed сolor="22c55e" secondaryColor="166534" />
    </div>
  )
}

export default SpinnerModal
