import React, { useRef, useEffect } from 'react';
import configDataStore from "../data-stores/configDataStore"

/*  dependencies:
    "@khmyznikov/pwa-install": "*",
    "@lit": "*"
*/
import PWAInstall from '@khmyznikov/pwa-install/react-legacy';

/*
  manifestUrl = '/manifest.json',
  icon = '',
  name = 'React App',
  description = '',
  installDescription = '',
  disableDescription = false,
  disableScreenshots = false,
  manualApple = false,
  manualChrome = false,
  disableChrome = false,
*/

const PWAInstallComponent = ({
  onInstallSuccess,
  onInstallFail,
  onUserChoiceResult,
  onInstallAvailable,
  onInstallHowTo,
  onInstallGallery,
  ...props
}) => {
    const pwaInstallRef = useRef(null);
    const { pwaPromptDisplayed, setPwaPromptDisplayed } = configDataStore()
    const showInstallButton = useRef(false);
    //let showInstallButton = false

    const installApp = () => {
        if (pwaInstallRef.current) {
            if (pwaInstallRef.current.isInstallAvailable) {
                pwaInstallRef.current.install()
            } else {
                pwaInstallRef.current.showDialog(true)
            }
        }
    }

    // Filter out null or undefined props
    const nonNullProps = Object.fromEntries(
        Object.entries(props).filter(([_, value]) => value != null)
    );

    useEffect(() => {
        const currentElement = pwaInstallRef.current;

        const handleInstallSuccess = (event) => onInstallSuccess?.(event);
        const handleInstallFail = (event) => onInstallFail?.(event);
        const handleUserChoiceResult = (event) => onUserChoiceResult?.(event);
        const handleInstallAvailable = (event) => onInstallAvailable?.(event);
        const handleInstallHowTo = (event) => onInstallHowTo?.(event);
        const handleInstallGallery = (event) => onInstallGallery?.(event);

        if (currentElement) {
            currentElement.addEventListener('pwa-install-success-event', handleInstallSuccess);
            currentElement.addEventListener('pwa-install-fail-event', handleInstallFail);
            currentElement.addEventListener('pwa-user-choice-result-event', handleUserChoiceResult);
            currentElement.addEventListener('pwa-install-available-event', handleInstallAvailable);
            currentElement.addEventListener('pwa-install-how-to-event', handleInstallHowTo);
            currentElement.addEventListener('pwa-install-gallery-event', handleInstallGallery);

            return () => {
                currentElement.removeEventListener('pwa-install-success-event', handleInstallSuccess);
                currentElement.removeEventListener('pwa-install-fail-event', handleInstallFail);
                currentElement.removeEventListener('pwa-user-choice-result-event', handleUserChoiceResult);
                currentElement.removeEventListener('pwa-install-available-event', handleInstallAvailable);
                currentElement.removeEventListener('pwa-install-how-to-event', handleInstallHowTo);
                currentElement.removeEventListener('pwa-install-gallery-event', handleInstallGallery);
            };
        }
    }, [onInstallSuccess, onInstallFail, onUserChoiceResult, onInstallAvailable, onInstallHowTo, onInstallGallery]);
  
    useEffect(() => {
        // This code will run after the component mounts
        const handleDOMContentLoaded = () => {
            if (pwaInstallRef.current && !pwaInstallRef.current.isUnderStandaloneMode) {
                showInstallButton.current = true

                //if (!pwaPromptDisplayed) {
                setTimeout(function() {
                    if (pwaInstallRef.current && !pwaPromptDisplayed) {
                        pwaInstallRef.current.showDialog(true)
                        setPwaPromptDisplayed(true)
                    }
                  }, 10000);
                //}
            }
        }
        // Check if the DOM is already loaded
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', handleDOMContentLoaded)
        } else {
            handleDOMContentLoaded()
        }
    }, []);

  return (
    <React.Fragment>
        <PWAInstall manualApple={true} manualChrome={true} disableChrome={false}
            ref={pwaInstallRef} 
            {...nonNullProps}
        />
        {showInstallButton && (
        <button className="p-3 py-4 flex items-center justify-between text-gray-500 hover:text-green-500 hover:bg-gray-50 rounded-md"
            onClick={installApp}>
            <div className="flex items-center">
            <svg  xmlns="http://www.w3.org/2000/svg" className="mr-2" width="24" height="24" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M6 5a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-14z" /><path d="M11 4h2" /><path d="M12 17v.01" /></svg>
                <p className="font-medium text-base">
                    Create App Shortcut
                </p>
            </div>
        </button>)}
    </React.Fragment>
  );
};

export default PWAInstallComponent;
