import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from "react-router-dom"
import {getApiUrl} from "./utils"

const LoginForm = ({ handleLogin }) => {
  const location = useLocation()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isPasswordResetRequested, setIsPasswordResetRequested] = useState(false)

  const handleForgotPassword = (e) => {
    e.preventDefault()
    setIsPasswordResetRequested(!isPasswordResetRequested)
  }

  const handlePasswordResetFormSubmit = (e) => {
    e.preventDefault()
    // Handle password reset form submission
    console.log('Email:', username)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      let apiUrl = getApiUrl()
      const response = await fetch(apiUrl + 'login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })

      if (response.ok) {
        const data = await response.json()
        handleLogin(data)
      } else {
        throw new Error('Invalid credentials')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getQueryParams = (query) => {
    return new URLSearchParams(query)
  }

  useEffect(() => {
    const params = getQueryParams(location.search)
    const _token = params.get('_token')
    if (_token && _token.length) {
      handleLogin({'token': _token})
    }
  }, [location])

  return (
    <div className="md:h-screen" id="content">
      <section
        className="relative pt-16 pb-0 md:py-32 bg-white h-full"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/pattern-white.svg')`,
          backgroundPosition: 'center',
          height: '100% !important',
        }}
      >
        <div className="container px-4 mx-auto mb-16">
          <div className="w-full md:w-3/5 lg:w-1/2">
            <div className="max-w-sm mx-auto">
              <div className="mb-6 text-center">
                <a className="inline-block mb-6" href="#">
                  <img className="h-32" src={process.env.PUBLIC_URL + '/assets/images/DefectBuddy-with-name.png'} alt="" />
                </a>
                <h3 className="mb-4 text-2xl md:text-3xl font-bold" id="main-title">
                  {isPasswordResetRequested ? 'Enter your email to reset your password' : 'Sign in to your account'}
                </h3>
              </div>
              {isPasswordResetRequested ? (
                <form method="post" onSubmit={handlePasswordResetFormSubmit}>
                  <div className="mb-6">
                    <label className="block mb-2 text-gray-800 font-medium" htmlFor="email">
                      Email
                    </label>
                    <input
                      name="email"
                      id="email"
                      className="appearance-none block w-full p-3 leading-5 text-gray-900 border border-gray-200 rounded-lg shadow-md placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                      placeholder="Enter your email address"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <button
                    type="submit"
                    className="inline-block py-3 px-7 mb-6 w-full text-base text-green-50 font-medium text-center leading-6 bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm"
                  >
                    Reset Password
                  </button>
                  <div className="w-full md:w-auto mt-1">
                    <a className="inline-block text-xs font-medium text-green-500 hover:text-green-600" href="#"
                        onClick={handleForgotPassword}
                    >Back to Login</a>
                  </div>
                </form>
              ) : (
                <form method="post" onSubmit={handleSubmit}>
                  <div className="mb-6">
                    <label className="block mb-2 text-gray-800 font-medium" htmlFor="username">
                      Email
                    </label>
                    <input
                      id="username"
                      name="username"
                      className="appearance-none block w-full p-3 leading-5 text-gray-900 border border-gray-200 rounded-lg shadow-md placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                      placeholder="Enter your email address"
                      value={username}
                      autoComplete="username"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block mb-2 text-gray-800 font-medium" htmlFor="password">
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      className="appearance-none block w-full p-3 leading-5 text-gray-900 border border-gray-200 rounded-lg shadow-md placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                      type="password"
                      placeholder="************"
                      value={password}
                      autoComplete="current-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-wrap items-center justify-between">
                    <button
                        className="inline-block py-3 px-7 mb-3 w-full text-base text-green-50 font-medium text-center leading-6 bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm"
                        type="submit">
                      Sign In
                    </button>
                    <div className="w-full md:w-auto mt-1">
                      <a
                          className="inline-block text-xs font-medium text-green-500 hover:text-green-600"
                          href="#"
                          onClick={handleForgotPassword}
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <img
          className="hidden md:block md:absolute md:top-0 md:right-0 mx-auto md:h-full md:w-2/5 lg:w-1/2 md:object-cover"
          src={process.env.PUBLIC_URL + '/assets/images/the-banks-hero04.jpg'}
          alt="Apartment"
        />
      </section>
    </div>
  )
}

export default LoginForm