import React from 'react'
import {createRoot} from 'react-dom/client'
import App from './App'
import {AuthProvider} from './AuthContext'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <AuthProvider>
    <App/>
  </AuthProvider>
)

serviceWorkerRegistration.register()