// Layout.js
import React, { useEffect } from 'react';
import { getDjangoAppBaseUrl } from '../utils';
//import '@khmyznikov/pwa-install';
import PWAInstallComponent from "../components/pwa-install.js";
import configDataStore from "../data-stores/configDataStore"

const SideMenu = ({ showProjectDropdown = false, showSelectPropertyButton = false, selectedProject = null }) => {
    let pwaInstall;
    const { projects } = configDataStore()
    useEffect(() => {
        // This code will run after the component mounts
        const handleDOMContentLoaded = () => {
            // Burger menus
            // open
            const burger = document.getElementById('navbar-burger')
            const menu = document.getElementById('main-sidebar')
            const menuContent = document.getElementById('main-sidebar-content')
    
            if (burger && menu) {
                burger.addEventListener('click', function () {
                    //menu.classList.toggle('lg:block')
                    menu.classList.toggle('hidden')
                })
                menu.addEventListener('click', function (e) {
                    if (!menuContent.contains(e.target)) {
                        menu.classList.add('hidden')
                    }
                })
            }
    
            // close
            const closeMenu = document.getElementById('navbar-close');
    
            if (closeMenu) {
                closeMenu.addEventListener('click', function () {
                    //menu.classList.toggle('lg:hidden')
                    menu.classList.toggle('hidden')
                })
            }
    
            // Closing modal window on Escape key
            window.addEventListener('keydown', function (e) {
                if (e.key === 'Escape' && menu.length) {
                    for (let menux of menu) {
                        if (!menux.classList.contains('hidden')) {
                            menux.classList.add('hidden')
                        }
                    }
                }
            })
            pwaInstall = document.getElementsByTagName('pwa-install')[0]
            if (!pwaInstall.isUnderStandaloneMode) {
                document.getElementById('link-create-shortcut-nav').classList.remove('hidden')
            }
        }

        // Check if the DOM is already loaded
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', handleDOMContentLoaded)
        } else {
            handleDOMContentLoaded()
        }

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded)
        }
    }, [])

    const addToHomeScreen = () => {
        document.getElementsByTagName('pwa-install')[0].install();
    }

  return (
    <React.Fragment>
    <div id="main-sidebar" className="navbar-menu h-full overflow-y-auto shadow-md z-99
         hidden bg-gray-900 bg-opacity-50 fixed top-0 left-0 w-full
         lg:block lg:bg-white lg:static lg:w-auto">
        <div id="main-sidebar-content" className="fixed top-0 left-0 bottom-0 w-full w-4/6 max-w-xs bg-white lg:static lg:w-auto z-50">   
            <nav className="relative flex flex-col p-6 lg:pt-2 h-full overflow-y-auto">
                <div className="flex justify-between w-full lg:hidden">
                    <div className="inline-block p-2">
                        <img src={process.env.PUBLIC_URL + '/assets/images/DefectBuddy-with-name-wide-60h.png'} alt="DefectBuddy"/>
                    </div>                
                    <a id="navbar-close" className="absolute top-5 p-4 right-3" href="#" title="Close">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.94004 6L11.14 1.80667C11.2656 1.68113 11.3361 1.51087 11.3361 1.33333C11.3361 1.1558 11.2656 0.985537 11.14 0.860002C11.0145 0.734466 10.8442 0.66394 10.6667 0.66394C10.4892 0.66394 10.3189 0.734466 10.1934 0.860002L6.00004 5.06L1.80671 0.860002C1.68117 0.734466 1.51091 0.663941 1.33337 0.663941C1.15584 0.663941 0.985576 0.734466 0.860041 0.860002C0.734505 0.985537 0.66398 1.1558 0.66398 1.33333C0.66398 1.51087 0.734505 1.68113 0.860041 1.80667L5.06004 6L0.860041 10.1933C0.797555 10.2553 0.747959 10.329 0.714113 10.4103C0.680267 10.4915 0.662842 10.5787 0.662842 10.6667C0.662842 10.7547 0.680267 10.8418 0.714113 10.9231C0.747959 11.0043 0.797555 11.078 0.860041 11.14C0.922016 11.2025 0.99575 11.2521 1.07699 11.2859C1.15823 11.3198 1.24537 11.3372 1.33337 11.3372C1.42138 11.3372 1.50852 11.3198 1.58976 11.2859C1.671 11.2521 1.74473 11.2025 1.80671 11.14L6.00004 6.94L10.1934 11.14C10.2554 11.2025 10.3291 11.2521 10.4103 11.2859C10.4916 11.3198 10.5787 11.3372 10.6667 11.3372C10.7547 11.3372 10.8419 11.3198 10.9231 11.2859C11.0043 11.2521 11.0781 11.2025 11.14 11.14C11.2025 11.078 11.2521 11.0043 11.286 10.9231C11.3198 10.8418 11.3372 10.7547 11.3372 10.6667C11.3372 10.5787 11.3198 10.4915 11.286 10.4103C11.2521 10.329 11.2025 10.2553 11.14 10.1933L6.94004 6Z"
                                fill="#556987"></path>
                        </svg>
                    </a>
                </div>
                <div className="flex flex-col h-full">
                    <ul>
                        {showProjectDropdown && (
                            <li id="select-project-nav">
                                <div className="relative">
                                    <svg className="pointer-events-none absolute right-4 top-1/2 transform -translate-y-1/2" width="16"
                                        height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z"
                                            fill="#8896AB"></path>
                                    </svg>
                                    <select name="select_project" defaultValue={selectedProject?.id}
                                        className="appearance-none w-full py-2.5 px-4 text-gray-900 text-base font-normal bg-white border outline-none border-gray-200 focus:border-green-500 rounded-lg shadow-input">
                                        {projects.map(project => (
                                            <option key={project.id} value={project.id}
                                                    data-project-id={project.id}
                                                >{project.short_name} 
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>                     
                        )}
                        {showSelectPropertyButton && (                                                   
                        <li id="link-select-prop-nav">
                            <a className="p-3 py-4 flex items-center justify-between text-gray-500 hover:text-green-500 hover:bg-gray-50 rounded-md"
                                href="TODO">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24"
                                        viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                        aria-hidden="true" className="mr-2">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4">
                                        </path>
                                    </svg>
                                    <p className="font-medium text-base">Select Property</p>
                                </div>
                            </a>
                        </li>
                        )}
                        <li id="link-online-mode-nav">
                            <a className="p-3 py-4 flex items-center justify-between text-gray-500 hover:text-green-500 hover:bg-gray-50 rounded-md"
                                href={getDjangoAppBaseUrl()}>
                                <div className="flex items-center">
                                    <svg  xmlns="http://www.w3.org/2000/svg"  className="mr-2" width="24" height="24" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 18l0 -3" /><path d="M10 18l0 -6" /><path d="M14 18l0 -9" /><path d="M18 18l0 -12" />
                                    </svg>
                                    <p className="font-medium text-base">
                                        Return to Online Mode
                                    </p>
                                </div>                
                            </a>
                        </li>
                    </ul>
                    <p className="m-2 text-xs font-medium text-gray-500 uppercase">Settings</p>
                    <ul>
                        <li id="link-create-shortcut-nav" className="hidden">                            
                            <PWAInstallComponent installDescription="To add DefectBuddy Offline to the Home Screen of your mobile device, click 'Install'" />
                            {/*}
                            <button className="p-3 py-4 flex items-center justify-between text-gray-500 hover:text-green-500 hover:bg-gray-50 rounded-md"
                                onClick={addToHomeScreen}>
                                <div className="flex items-center">
                                <svg  xmlns="http://www.w3.org/2000/svg" className="mr-2" width="24" height="24" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M6 5a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-14z" /><path d="M11 4h2" /><path d="M12 17v.01" /></svg>
                                    <p className="font-medium text-base">
                                        Create App Shortcut</p>
                                </div>
                            </button>
                            */}
                        </li>
                        <li id="link-logout-nav">
                            <a  href="#" className="p-3 py-4 flex items-center justify-between text-gray-500 hover:text-green-500 hover:bg-gray-50 rounded-md">
                                <div className="flex items-center">
                                    <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.29 8.71L18.59 11L9.00004 11C8.73483 11 8.48047 11.1054 8.29294 11.2929C8.1054 11.4804 8.00004 11.7348 8.00004 12C8.00004 12.2652 8.1054 12.5196 8.29294 12.7071C8.48047 12.8946 8.73483 13 9.00004 13L18.59 13L16.29 15.29C16.1963 15.383 16.1219 15.4936 16.0712 15.6154C16.0204 15.7373 15.9942 15.868 15.9942 16C15.9942 16.132 16.0204 16.2627 16.0712 16.3846C16.1219 16.5064 16.1963 16.617 16.29 16.71C16.383 16.8037 16.4936 16.8781 16.6155 16.9289C16.7373 16.9797 16.868 17.0058 17 17.0058C17.1321 17.0058 17.2628 16.9797 17.3846 16.9289C17.5065 16.8781 17.6171 16.8037 17.71 16.71L21.71 12.71C21.8011 12.6149 21.8724 12.5028 21.92 12.38C22.0201 12.1365 22.0201 11.8635 21.92 11.62C21.8724 11.4972 21.8011 11.3851 21.71 11.29L17.71 7.29C17.6168 7.19676 17.5061 7.1228 17.3843 7.07234C17.2625 7.02188 17.1319 6.99591 17 6.99591C16.8682 6.99591 16.7376 7.02188 16.6158 7.07234C16.494 7.1228 16.3833 7.19676 16.29 7.29C16.1968 7.38324 16.1228 7.49393 16.0724 7.61575C16.0219 7.73757 15.996 7.86814 15.996 8C15.996 8.13186 16.0219 8.26243 16.0724 8.38425C16.1228 8.50607 16.1968 8.61676 16.29 8.71ZM12 21C12 20.7348 11.8947 20.4804 11.7071 20.2929C11.5196 20.1054 11.2653 20 11 20L5.00004 20C4.73482 20 4.48047 19.8946 4.29293 19.7071C4.1054 19.5196 4.00004 19.2652 4.00004 19L4.00004 5C4.00004 4.73478 4.1054 4.48043 4.29293 4.29289C4.48047 4.10536 4.73483 4 5.00004 4L11 4C11.2653 4 11.5196 3.89464 11.7071 3.70711C11.8947 3.51957 12 3.26522 12 3C12 2.73478 11.8947 2.48043 11.7071 2.29289C11.5196 2.10536 11.2653 2 11 2L5.00004 2C4.20439 2 3.44133 2.31607 2.87872 2.87868C2.31611 3.44129 2.00004 4.20435 2.00004 5L2.00004 19C2.00004 19.7956 2.31611 20.5587 2.87872 21.1213C3.44133 21.6839 4.20439 22 5.00004 22L11 22C11.2653 22 11.5196 21.8946 11.7071 21.7071C11.8947 21.5196 12 21.2652 12 21Z"
                                            fill="currentColor"></path>
                                    </svg>
                                    <p className="font-medium text-base">
                                        Sign out</p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
    {/*<pwa-install></pwa-install>*/}
    </React.Fragment>
  );
};

export default SideMenu;
