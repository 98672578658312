import React, {useContext, useEffect, useState} from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate, useParams, useLocation} from 'react-router-dom'
import LoginForm from './LoginForm'
import {AuthContext} from './AuthContext'
import routes from "./Routes"
import configDataStore from "./data-stores/configDataStore"
import {fetchProjects, fetchProperties, getCache, getSetting} from "./utils"

const App = () => {
  const { isAuthenticated, handleLogin, handleLogout } = useContext(AuthContext)
  const updateUser = configDataStore((state) => {
    return state.updateUser
  })
  const updatePermissions = configDataStore((state) => state.updatePermissions)
  const updateProjects = configDataStore((state) => state.updateProjects)
  const updateProperties = configDataStore((state) => state.updateProperties)

  useEffect(() => {
    // let _user = getCache('user')
    // let _perms = getCache('permissions')
    // let _settings = getCache('settings')
    //
    // updateUser(_user)
    // updatePermissions(_perms)
  }, [])

  useEffect(() => {
    // const fetchAndUpdateData = async () => {
    //   try {
    //     if(offlineMode) {
    //       // Check for cached data in localStorage
    //       let cachedProjects = localStorage.getItem('projects')
    //       let cachedProperties = localStorage.getItem('properties')
    //
    //       if (cachedProjects) updateProjects(JSON.parse(cachedProjects))
    //       if (cachedProperties) updateProperties(JSON.parse(cachedProperties))
    //     }
    //
    //     // Fetch data from API endpoint
    //     const newProjects = await fetchProjects()
    //     const newProperties = await fetchProperties()
    //
    //     if (newProjects) updateProjects(newProjects)
    //     if (newProperties) updateProperties(newProperties)
    //
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }
    //
    // fetchAndUpdateData()
  }, [updateProjects, updateProperties])

  return (
    <Router>
      {isAuthenticated ? (
        <Routes>
          <Route path="/login" element={<Navigate to="/"/>}/>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component/>}
            />
          ))}
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginForm handleLogin={handleLogin}/>}/>
          <Route path="/*" element={<Navigate to='/login' />}/>
        </Routes>
      )}
    </Router>
  )
}

export default App
