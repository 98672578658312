import Konva from 'konva'

class KonvaStage {
    container
    wrapper
    stageMode
    marking
    width
    height
    stageData
    stageStructureMarkup
    stage
    loaded
    bgLayer
    drawingLayer
    sliderInput
    selectedShape
    stageDialogHtml
    stageDialog
    editable
    photo
    photoObj
    imageObj
    mimeType
    drawingMode
    drawingEnabled
    drawingLine
    drawingLineStrokeWidth
    lastCenter
    lastDist
    drawingInProgress
    lastLine
    drawingToggle
    konvaStageScale
    SHAPE_OPACITY_HOVER_SELECTED = 1
    SHAPE_OPACITY_UNSELECTED = 0.5
    fullImageLoading = false
    iOSSafari = false
    originalStageDataImageWidth
    presentPhoto

    // circleNo

    constructor(inputData) {
        // It was not working using ?? operator, idk why, so applied a workaround

        // JR 07/01/2024 unused because the getField should be using getAttribute for width and height, and if it did this will cause issues if the values are actually set...
        //this.width = this.getField('width', inputData)
        //this.height = this.getField('height', inputData) 
        this.presentPhoto = this.getField('presentPhoto', inputData)
        this.wrapper = this.getField('wrapper', inputData)
        this.stageMode = this.getField('stageMode', inputData)

        this.stageData = this.getField('stageData', inputData)
        if (!this.presentPhoto) {
            this.editable = this.getField('editable', inputData)
            this.mimeType = this.getField('mimeType', inputData)
            this.drawingMode = this.getField('drawingMode', inputData)
            if (!this.height) this.height = !this.wrapper ? window.innerHeight - (this.isMobile() ? 95 : 130) : this.wrapper.offsetHeight
        }
        else {
            this.editable = false
            this.mimeType = false
            this.drawingMode = false
            if (!this.height) this.height = !this.wrapper ? window.innerHeight : this.wrapper.offsetHeight
        }
        this.photo = this.getField('photo', inputData)
        this.stageDialogHtml = this.getStageDialogHtml()

        if (!this.width) this.width = !this.wrapper ? window.innerWidth : this.wrapper.offsetWidth
        if (this.stageData) this.stageData = JSON.parse(this.stageData)

        if (this.stageMode === 'aggregate') {
            this.marking = this.getField('marking', inputData)
            this.stageStructureMarkup = this.getField('stageStructureMarkup', inputData)
            if(this.stageStructureMarkup) this.stageStructureMarkup = JSON.parse(this.stageStructureMarkup)
        }

        if (['image/png', 'image/jpeg'].indexOf(this.mimeType) === -1) this.mimeType = 'image/png'

        this.drawingEnabled = false
        this.drawingLine = null

        // this.circleNo = 0
        //this.dotSize = 25

        let existingStageDialog = this.wrapper ? document.querySelector('#wrapper-konva-stage-dialog') : document.querySelector('#konva-stage-dialog')
        if (existingStageDialog) {
            existingStageDialog.remove()
        }

        if (this.wrapper) {
            this.wrapper.appendChild(this.stageDialogHtml)
        } else {
            document.body.appendChild(this.stageDialogHtml)
        }

        this.stageDialog = this.wrapper ? document.querySelector('#wrapper-konva-stage-dialog') : document.querySelector('#konva-stage-dialog')
        this.container = this.wrapper ? document.querySelector('#wrapper-konva-stage-container') : document.querySelector('#konva-stage-container')

        this.loadStage()

        /* commenting out until we have time to implement full solution
        window.addEventListener('resize', () => {
            if (this.loaded) {  
                console.log('loaded, resizing')
                this.setStageScale()
            } else {
                console.log('NOT loaded, NOT resizing')
            }
        })*/

        this.drawingLayer.listening(this.editable)

        this.openStageDialog()
    }

    getImageDimensionsForStage(w, h) {
        console.log('calling getImageDimensionsForStage')
        // since this is a dynamic image upload we want to resize the canvas image object to get
        // a pleasing effect.
        const padding = 0;

        // get the aperture we need to fit by taking padding off the stage size.
        const targetW = this.stage.getWidth() - (2 * padding);
        const targetH = this.stage.getHeight() - (2 * padding);

        // compute the ratios of image dimensions to aperture dimensions
        const widthFit = targetW / w;
        const heightFit = targetH / h;

        // compute a scale for best fit and apply it
        const scale = (widthFit > heightFit) ? heightFit : widthFit;

        w = parseInt(w * scale, 10);
        h = parseInt(h * scale, 10);

        const imageDimensions = {width: w, height: h}

        console.log('imageDimensions', imageDimensions)
        console.log('getImageDimensionsForStage returning')
        return imageDimensions;
    }

    setImageSize(imageObj) {
        console.log('calling setImageSize')

        let imageDimensions = this.getImageDimensionsForStage(imageObj.width, imageObj.height)

        this.photoObj.image(imageObj);  // give the image to the cannvas image object.
        this.photoObj.size({
            width: imageDimensions.width,
            height: imageDimensions.height
        });

        this.setStageScale()
        this.bgLayer.batchDraw(); // My favourite thing to forget.
        console.log('setImageSize end')
    }

    setStageScale() {
        console.log('Calling setStageScale()')
        this.konvaStageScale = this.photoObj.attrs.width / 1920
        console.log('konvaStageScale', this.konvaStageScale)
    }

    getSafariVersion() {
        // Extract the Safari version from the user agent string
        const match = /Version\/(\d+\.\d+)/.exec(navigator.userAgent)
        return match ? parseFloat(match[1]) : null
    }

    loadMarking() {
        if (this.marking) {
            let _marking = [],
                _this = this,
                scale = this.konvaStageScale  // This value is not trust-worthy

            if (this.stageStructureMarkup) {
                let _floorplanWidth = this.stageStructureMarkup.children[0].children[0].attrs.width
                scale = this.photoObj.attrs.width / _floorplanWidth  // The correct scale for this specific floorplan
            }

            this.marking.forEach(shapes => {
                shapes.forEach(shape => {
                    if (shape?.attrs?.name === 'circle') {
                        shape.attrs.radius *= scale
                        shape.attrs.strokeWidth *= scale
                        shape.attrs.x *= scale
                        shape.attrs.y *= scale
                        let _circle = new Konva.Circle(shape.attrs)
                        _this.attachEventsToShape(_circle)
                        _circle.setDraggable(false)  // As it is aggregate stage shape, so we need it to stick to where its actual position is
                        _marking.push(_circle)
                    }
                })
            })
            console.log(_marking)
            this.drawingLayer.add(..._marking)
        }
    }

    selectShapes(shapes, by_defect) {
        if (!shapes || shapes.length === 0) {
            this.drawingLayer.children.forEach(child => {
                child.opacity(this.SHAPE_OPACITY_UNSELECTED)
            })
            this.drawingLayer.batchDraw()
            return
        }

        shapes.forEach(shape => {
            this.drawingLayer.children.forEach(child => {
                if (by_defect) {
                    child.attrs.defect_id === shape.attrs.defect_id ?
                        child.opacity(this.SHAPE_OPACITY_HOVER_SELECTED) : child.opacity(this.SHAPE_OPACITY_UNSELECTED)
                }
                // Following is for clicking and selecting on a specific shape
                else if (child.attrs.name === shape.attrs.name
                    && child.attrs.defect_id === shape.attrs.defect_id
                    && child.attrs.x === shape.attrs.x) {
                    child.opacity(this.SHAPE_OPACITY_HOVER_SELECTED)
                }
            })
        })

        this.drawingLayer.batchDraw()
    }

    loadStage() {
        let mode = (eval("let __temp = null"), (typeof __temp === "undefined")) ? "strict" : "non-strict";
        console.log('loadStage, mode', mode)
        Konva.hitOnDragEnabled = true

        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        this.iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        Konva.pixelRatio = 2
        if (this.iOSSafari) {
            const safariVersion = this.getSafariVersion()
            if (!safariVersion || safariVersion < 17) {
                Konva.pixelRatio = 1
            }
        }

        // Check if the container element is available
        if (!this.container) {
            console.error('Container element not found.')
            return
        }
        console.log('Starting loadStage')

        let _this = this

        _this.lastCenter = null
        _this.lastDist = 0;

        // Import stageData if preset
        this.importStage()

        if (!this.stage) {
            // Create the Konva stage with hit on drag enabled
            this.stage = new Konva.Stage({
                container: _this.container.id,
                width: _this.width,
                height: _this.height,
                draggable: true
            })

            // Create the background and drawing layers
            this.bgLayer = new Konva.Layer({name: 'background-layer'})
            this.drawingLayer = new Konva.Layer({name: 'drawing-layer'})
        }

        if (this.photo) {
            let imageObj = new Image()
            imageObj.crossOrigin = 'Anonymous'

            // set onload
            imageObj.onload = () => {
                this.setImageSize(imageObj)
                if (this.fullImageLoading) {
                    this.disableLoadingStatus()
                    this.imageObj = imageObj
                }

                if (this.stageMode === 'aggregate') {
                    this.loadMarking()
                }
            }

            // initially load thumbnail
            this.fullImageLoading = true
            imageObj.src = _this.photo.src

            // set photo image will initial dimensions (this will very quickly be updated with from thumbnail loading)
            this.photoObj = new Konva.Image({
                image: imageObj,
                width: 200,
                height: 200,
                x: 0, y: 0
            })

            // set onload (thumbnail should have already loaded by now with initial dimensions)
            // now load full image
            imageObj.src = _this.photo.dataset.fullUrl
            this.bgLayer.add(this.photoObj)

            // no longer needed, as imageObj.onload calls setImageSize, which calls batchDraw
            //this.bgLayer.batchDraw(); // My favourite thing to forget.
            //this.setImageSize(imageObj)
        }

        this.stage.add(this.bgLayer)

        // Adding the drawing layer for interactions
        this.stage.add(this.drawingLayer)

        // Enabling zoom functionality
        this.stage.on('wheel', function (e) {
            console.log('wheel start')
            // stop default scrolling
            e.evt.preventDefault()

            const pointer = _this.stage.getPointerPosition()

            const scaleBy = 1.05
            const oldScale = _this.stage.scaleX()
            const mousePointTo = {
                x: pointer.x / oldScale - _this.stage.x() / oldScale,
                y: pointer.y / oldScale - _this.stage.y() / oldScale
            }

            // how to scale? Zoom in? Or zoom out?
            let direction = e.evt.deltaY > 0 ? 1 : -1

            // when we zoom on trackpad, e.evt.ctrlKey is true
            // in that case lets revert direction
            if (e.evt.ctrlKey) {
                direction = -direction
            }

            const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy

            const newPos = {
                x: -((mousePointTo.x - pointer.x / newScale) * newScale),
                y: -((mousePointTo.y - pointer.y / newScale) * newScale)
            }

            // cannot use the following, it does not work > _this.stage.scale({x: newScale, y: newScale})
            _this.stage.scaleX(newScale);
            _this.stage.scaleY(newScale);
            _this.stage.position(newPos)
            _this.stage.batchDraw()
            console.log('wheel end')
        })

        this.stage.on('dblclick dbltap', function (e) {
            if (!_this.editable || _this.drawingMode) return false

            const scale = this.scaleX()
            const pointerPosition = this.getPointerPosition()

            // Adjust the circle position based on the zoom scale
            const offsetX = (pointerPosition.x - this.x()) / scale
            const offsetY = (pointerPosition.y - this.y()) / scale

            _this.createCircle(offsetX, offsetY)
        })

        this.stage.on('click tap', function (e) {
            if (_this.stageMode === 'aggregate') {
                if (e.target.hasName('circle')) {
                    document.dispatchEvent(new CustomEvent('marking-click', {detail: e.target}))

                    // Resetting previously highlighted shapes
                    _this.selectShapes([])

                    // Highlighting new selected shape
                    _this.selectShapes([e.target])
                }

                return false
            }

            if (!_this.editable || _this.drawingEnabled) return false

            if (!e.target.hasName('circle')) {
                if (_this.selectedShape && _this.selectedShape.hasName('circle')) {
                    _this.selectedShape.opacity(_this.SHAPE_OPACITY_UNSELECTED)
                }
                _this.selectedShape = null
                return
            }

            if (_this.selectedShape && _this.selectedShape.hasName('circle')) {
                _this.selectedShape.opacity(_this.SHAPE_OPACITY_UNSELECTED)
            }
            _this.selectedShape = e.target
            let selectedShapeScaleX = _this.selectedShape.attrs.scaleX ? _this.selectedShape.attrs.scaleX : _this.drawingLayer.attrs.scaleX;
            _this.sliderInput.value = _this.selectedShape.attrs.radius * selectedShapeScaleX / _this.konvaStageScale
            _this.selectedShape.opacity(_this.SHAPE_OPACITY_HOVER_SELECTED)
            console.log('_this.sliderInput.value', _this.sliderInput.value)
            console.log(`_this.selectedShape.attrs.radius / _this.konvaStageScale = ${_this.selectedShape.attrs.radius} / ${_this.konvaStageScale}`, (_this.selectedShape.attrs.radius / _this.konvaStageScale))
        })

        // pinch to zoom.
        _this.stage.on('touchmove', function (e) {
            //console.log('touchmove start');
            e.evt.preventDefault();
            var touch1 = e.evt.touches[0];
            var touch2 = e.evt.touches[1];

            if (touch1 && touch2) {
                //console.log('touch1 && touch2');
                // if the stage was under Konva's drag&drop
                // we need to stop it, and implement our own pan logic with two pointers
                if (_this.stage.isDragging()) {
                    console.log('stopping drag');
                    _this.stage.stopDrag();
                }

                var p1 = {
                    x: touch1.clientX,
                    y: touch1.clientY,
                };
                var p2 = {
                    x: touch2.clientX,
                    y: touch2.clientY,
                };

                if (!_this.lastCenter) {
                    _this.lastCenter = _this.getCenter(p1, p2);
                    return;
                }
                var newCenter = _this.getCenter(p1, p2);

                var dist = _this.getDistance(p1, p2);

                if (!_this.lastDist) {
                    _this.lastDist = dist;
                }

                // local coordinates of center point
                var pointTo = {
                    x: (newCenter.x - _this.stage.x()) / _this.stage.scaleX(),
                    y: (newCenter.y - _this.stage.y()) / _this.stage.scaleX(),
                };

                var scale = _this.stage.scaleX() * (dist / _this.lastDist);

                _this.stage.scaleX(scale);
                _this.stage.scaleY(scale);

                // calculate new position of the stage
                var dx = newCenter.x - _this.lastCenter.x;
                var dy = newCenter.y - _this.lastCenter.y;

                var newPos = {
                    x: newCenter.x - pointTo.x * scale + dx,
                    y: newCenter.y - pointTo.y * scale + dy,
                };

                _this.stage.position(newPos);

                _this.lastDist = dist;
                _this.lastCenter = newCenter;
            }
            //console.log('touchmove end');
        });

        // end pinch to zoom
        _this.stage.on('touchend', function () {
            //console.log('touchend start');
            _this.lastDist = 0;
            _this.lastCenter = null;
            //console.log('touchend end');
        });

        _this.stage.on('mousedown touchstart', function (e) {
            //console.log('mousedown/touchstart start')
            if (!_this.editable || !_this.drawingEnabled) return false

            if (e.evt.touches) {
                var touch1 = e.evt.touches[0]
                var touch2 = e.evt.touches[1]
                if (touch1 && touch2) return false
            }

            // prevent scrolling on touch devices
            e.evt.preventDefault()
            _this.drawingInProgress = true
            console.log('drawing enabled')
            const scale = _this.stage.scaleX()

            console.log('stage scale', scale)
            console.log('konvaStageScale', _this.konvaStageScale)

            const pointerPosition = _this.stage.getPointerPosition()

            // Adjust the line position based on the zoom scale
            const offsetX = (pointerPosition.x - _this.stage.x()) / scale
            const offsetY = (pointerPosition.y - _this.stage.y()) / scale

            _this.lastLine = new Konva.Line({
                stroke: `rgba(223,75,38, ${_this.SHAPE_OPACITY_HOVER_SELECTED})`,
                strokeWidth: _this.sliderInput.value * _this.konvaStageScale,
                globalCompositeOperation: 'source-over',
                // round cap for smoother lines
                lineCap: 'round',
                lineJoin: 'round',
                name: 'line',
                // opacity: _this.SHAPE_OPACITY_HOVER_SELECTED,
                // add point twice, so we have some drawings even on a simple click
                points: [offsetX, offsetY, offsetX, offsetY]//,
                //scale: _this.konvaStageScale
            });
            _this.drawingLayer.add(_this.lastLine)
            _this.selectedShape = _this.lastLine
            //console.log('mousedown/touchstart end')
        });

        // and core function - drawing
        _this.stage.on('mousemove touchmove', function (e) {
            //console.log('mousemove/touchmove start')
            if (!_this.drawingInProgress) {
                return;
            }

            // prevent scrolling on touch devices
            e.evt.preventDefault();

            const scale = _this.stage.scaleX()
            const pointerPosition = _this.stage.getPointerPosition()

            // Adjust the line position based on the zoom scale
            const offsetX = (pointerPosition.x - _this.stage.x()) / scale
            const offsetY = (pointerPosition.y - _this.stage.y()) / scale

            var newPoints = _this.lastLine.points().concat([offsetX, offsetY])
            _this.lastLine.points(newPoints)
            console.log('mousemove/touchmove end')
        });

        _this.stage.on('mouseup touchend', function () {
            _this.drawingInProgress = false;
            //console.log('mousemove/touchmove end')
        });
    }

    // Drawing Circles
    createCircle(x, y) {
        const scale = this.stage.scaleX()
        console.log('Creating circle');
        console.log('konvaStageScale', this.konvaStageScale);
        console.log('this.sliderInput.value', this.sliderInput.value);
        const circle = new Konva.Circle({
            x: x,
            y: y,
            radius: 1 * this.sliderInput.value * this.konvaStageScale,
            fill: `rgba(255, 0, 0, ${this.SHAPE_OPACITY_HOVER_SELECTED})`,
            stroke: `rgba(0, 0, 0, ${this.SHAPE_OPACITY_HOVER_SELECTED})`,
            strokeWidth: 4 * this.konvaStageScale,
            // opacity: this.SHAPE_OPACITY_HOVER_SELECTED,
            draggable: true,
            name: 'circle' //,
            //scaleX: this.konvaStageScale,
            //scaleY: this.konvaStageScale
        })
        console.log('circle.radius', this.sliderInput.value * this.konvaStageScale);
        this.drawingLayer.add(circle)

        this.attachEventsToShape(circle)
        this.selectedShape = circle

        this.drawingLayer.batchDraw()
    }

    // Drawing Lines
    createLine(x, y) {
        const scale = this.stage.scaleX()
        let _this = this
        console.log('stage scale', scale);
        console.log('konvaStageScale', this.konvaStageScale);

        this.drawingLine = new Konva.Line({
            points: [x, y],
            stroke: 'red',
            fill: 'red',
            strokeWidth: this.sliderInput.value * this.konvaStageScale,
            opacity: _this.SHAPE_OPACITY_HOVER_SELECTED,
            lineCap: 'round',
            draggable: false,
            name: 'line',
            scale: this.konvaStageScale
        })

        this.drawingLayer.add(this.drawingLine);
        this.drawingLayer.batchDraw()
    }

    attachEventsToShape(shape) {
        let _this = this

        // Removal is necessary for unwanted ones
        shape.on('dblclick dbltap', function () {
            if (!_this.editable) return false
            shape.remove()
            document.body.style.cursor = 'default'
            _this.drawingLayer.batchDraw()
        })
        // Event listener for mouseover
        shape.on('mouseover', function () {
            document.body.style.cursor = 'pointer'
            if (shape.attrs.name === 'line') {
                // Preserving the strokeWidth for resetting later
                if (!_this.drawingLineStrokeWidth) {
                    _this.drawingLineStrokeWidth = shape.strokeWidth()
                    shape.strokeWidth(_this.drawingLineStrokeWidth * 1.2)
                }
            } else {
                shape.opacity(_this.SHAPE_OPACITY_HOVER_SELECTED)
            }
            _this.drawingLayer.batchDraw()
        })
        shape.on('dragstart', function () {
            if (!_this.editable || !_this.drawingEnabled) return

            if (_this.selectedShape && _this.selectedShape.hasName('circle')) {
                if (_this.selectedShape != shape) {
                    _this.selectedShape.opacity(_this.SHAPE_OPACITY_UNSELECTED)
                }
            }
            _this.selectedShape = shape
            _this.selectedShape.opacity(_this.SHAPE_OPACITY_HOVER_SELECTED)
        })
        // Event listener for mouseout
        shape.on('mouseout', function () {
            document.body.style.cursor = 'default'
            if (shape.attrs.name === 'line') {
                // Resetting line strokeWidth
                if (_this.drawingLineStrokeWidth) {
                    shape.strokeWidth(_this.drawingLineStrokeWidth)
                    _this.drawingLineStrokeWidth = null
                }
            } else {
                if (_this.selectedShape != shape) {
                    shape.opacity(_this.SHAPE_OPACITY_UNSELECTED)
                }
            }
            _this.drawingLayer.batchDraw()
        })
    }

    importStage() {
        console.log('calling importStage')
        if (!this.stageData) return false

        console.log('this.stageData', this.stageData)

        this.stage = Konva.Node.create(this.stageData, this.container)
        this.stage.setDraggable(true)
        this.drawingLayer = this.stage.find('.drawing-layer')[0]
        this.bgLayer = this.stage.find('.background-layer')[0]

        // Removing old image if present in bgLayer, as we are adding it back in the loadStage()
        if (this.bgLayer.find('Image')[0]) {
            this.bgLayer.find('Image')[0].remove()
        }

        const newScale = this.stageData.attrs.width / this.stage.width()
        this.stage.scaleX(newScale)
        this.stage.scaleY(newScale)

        this.stage.getChildren().forEach(layer => {
            // layer.scale({x: 1, y: 1})
            // layer.setPosition(0, 0)
            layer.scaleX(this.stage.scaleX());
            layer.scaleY(this.stage.scaleY());
            layer.position({x: 0, y: 0});
        })

        this.stage.setWidth(this.width)
        this.stage.setHeight(this.height)


        // Re-attaching events
        this.stage.find('.circle').forEach(circle => {
            this.attachEventsToShape(circle)
        })

        this.stage.find('.line').forEach(line => {
            this.attachEventsToShape(line)
        })

        // Update the stage size and redraw the layers
        this.stage.batchDraw();
    }

    updateStageSize() {
        const wrapperWidth = !this.wrapper ? window.innerWidth : this.wrapper.offsetWidth
        const wrapperHeight = !this.wrapper ? window.innerHeight : this.wrapper.offsetHeight
        const stageWidth = this.stage.getWidth()
        const stageHeight = this.stage.getHeight()
        const scale = Math.min(wrapperWidth / stageWidth, wrapperHeight / stageHeight)

        this.stage.width(stageWidth * scale)
        this.stage.height(stageHeight * scale)

        // Scale the layers accordingly
        this.stage.getChildren().forEach(layer => {
            layer.scaleX(scale)
            layer.scaleY(scale)
        })

        this.stage.find('.circle').forEach(circle => {
            this.updateShapePosition(circle)
        })

        this.stage.find('Line').forEach(line => {
            this.updateShapePosition(line)
        })

        // Update the stage and redraw the layers
        this.stage.batchDraw()
    }

    updateShapePosition(shape, newWidth, newHeight) {
        // It depends on the stage data.
        if (!this.stageData) return false

        const originalWidth = this.stageData.children[0].children[0].attrs.width,
            originalHeight = this.stageData.children[0].children[0].attrs.height

        newWidth = newWidth ?? this.bgLayer.children[0].width()
        newHeight = newHeight ?? this.bgLayer.children[0].height()

        const scaleX = newWidth / originalWidth
        const scaleY = newHeight / originalHeight
        const newX = shape.x() * scaleX
        const newY = shape.y() * scaleY

        if (shape.hasName('circle')) {
            shape.radius(shape.attrs.radius * scaleX)
        } else {
            shape.scaleX(scaleX)
            shape.scaleY(scaleY)
        }
        // shape.scale({x: 1, y: 1})
        shape.position({x: newX, y: newY})
    }

    getOutput() {
        Konva.pixelRatio = 1

        const attrs = {'mimeType': this.mimeType}
        if (this.mimeType === 'image/jpeg') {
            attrs['quality'] = 0.8
        }

        let naturalWidth = this.imageObj.width
        let naturalHeight = this.imageObj.height
        let currentStageWidth = this.stage.width()
        let currentImageWidth = this.photoObj.width()
        let currentImageHeight = this.photoObj.height()
        const naturalToCurrentScale = naturalWidth / currentImageWidth

        this.container.width = naturalWidth
        this.container.height = naturalHeight

        // cannot use the following, it does not work > _this.stage.scale({x: imageObjScale, y: imageObjScale})
        this.stage.scaleX(1)
        this.stage.scaleY(1)
        this.stage.width(naturalWidth)
        this.stage.height(naturalHeight)

        this.stage.position({x: 0, y: 0})

        let _this = this

        this.drawingLayer.scaleX(naturalToCurrentScale)
        this.drawingLayer.scaleY(naturalToCurrentScale)
        this.drawingLayer.position({x: 0, y: 0});
        this.drawingLayer = this.stage.find('.drawing-layer')[0].find('.circle').forEach(circle => {
            circle.opacity(_this.SHAPE_OPACITY_UNSELECTED)
        })
        this.photoObj.width(naturalWidth)
        this.photoObj.height(naturalHeight)

        // Getting the image blob to use in file input field
        let filename = this.drawingMode ? 'defect_photo_markup' : 'defect_floorplan_markup'

        if (this.drawingMode && this.photo.dataset.uniqueFilename) {
            filename = this.photo.dataset.uniqueFilename.replace(/\.[^/.]+$/, "") + '_markup'
        }

        filename += this.mimeType === 'image/jpeg' ? '.jpg' : '.png'
        // const imageFile = this.dataURLtoFile(imageData, filename)

        // Get the JSON data from the stage
        let tmpStageJSONData = JSON.parse(this.stage.toJSON())

        tmpStageJSONData.children[0].children[0].attrs['width'] = currentImageWidth
        tmpStageJSONData.children[0].children[0].attrs['height'] = currentImageHeight

        const stageJSONData = JSON.stringify(tmpStageJSONData)
        //console.log('stageJSONData', stageJSONData)

        const _scale = 200 / this.stage.width()
        this.bgLayer.remove()
        const thumbImageData = this.stage.toDataURL({pixelRatio: _scale, quality: 0.8})
        //console.log('imageData', imageData)

        Konva.pixelRatio = 2
        return {
            thumbImageData,
            filename,
            stageJSONData
        }
    }

    dataURLtoFile(dataURL, filename) {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    clearDrawing() {
        this.drawingLayer.destroyChildren()
    }

    getField(field, jsonObj) {
        return jsonObj.hasOwnProperty(field) ? jsonObj[field] : false
    }

    isMobile() {
        return window.screen.width <= 430
        // We can use this, and there are many other solutions, but I think the best solution is the first one, because there might be many new brands
        // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }

    getStageDialogHtml() {
        if(this.stageDialogHtml) {
            return this.stageDialogHtml
        }

        const createEl = (tag, attrs = {}) => Object.assign(document.createElement(tag), attrs)
        const createElNs = (tag, ns = 'http://www.w3.org/2000/svg', attrs = {}) => Object.assign(document.createElementNS(ns, tag), attrs)
        const append = (parent, ...children) => children.forEach(child => parent.appendChild(child))

        if (this.wrapper) {
            const konvaStageDialog = createEl('div', {id: 'wrapper-konva-stage-dialog', className: 'konva-stage-dialog'})
            const konvaStageContainer = createEl('div', {className: 'flex justify-center items-center h-full'})
            const konvaStageContent = createEl('div', {
                id: 'wrapper-konva-stage-container',
                className: 'card-loading-indicator'
            })
            append(konvaStageContainer, konvaStageContent)
            append(konvaStageDialog, konvaStageContainer)
            append(this.wrapper, konvaStageDialog)

            this.stageDialogHtml = konvaStageDialog
        }
        else if (!this.presentPhoto) {
            const markupType = this.drawingMode ? 'Pen' : 'Dot'
            const konvaStageDialog = createEl('div', {id: 'konva-stage-dialog', className: 'full-screen-modal'})
            const stageHeader = createEl('div', {className: 'stage-header sticky top-0 flex justify-between items-center px-2 pt-3 pb-2 sm:p-4 lg:p-6 bg-white border-b border-gray-100 z-50'})
            const stageHeaderLeft = createEl('div', {className: 'w-1/3 md:w-2/3'})
            const stageHeaderRight = createEl('div', {className: 'w-2/3 md:w-1/3'})
            const stageHeaderLeftContainer = createEl('div', {className: 'flex flex-wrap justify-start items-center hidden floorplan-markup-show-in-edit-mode'})
            const stageHeaderRightContainer = createEl('div', {className: 'flex flex-wrap justify-end'})
            const stageToolboxContainer = createEl('div', {className: 'top-22 absolute flex justify-between md:justify-start items-center px-3 sm:px-4 lg:px-6 sm:py-2 bg-white border-b border-gray-300 z-50 w-full hidden floorplan-markup-show-in-edit-mode'})
            const sliderDiv = createEl('div', {className: 'flex justify-start items-center cursor-pointer'})
            const sliderContainer = createEl('div', {className: 'slidecontainer w-40 md:w-80'})

            const sliderInput = createEl('input', {
                id: 'floorplan-markup-dot-size',
                className: 'slider-color w-full my-4',
                type: 'range',
                min: '1',
                max: '50',
                value: '25'
            })
            const sliderInputLabel = createEl('label', {
                className: 'ml-3 text-gray-700 font-medium pr-2',
                for: 'flooplan-markup-dot-size',
                innerText: markupType + ' Size'
            })
            sliderInput.addEventListener('change', (e) => {
                console.log('sliderInput.value', e.target.value)
                let newSize = e.target.value * this.konvaStageScale

                if (this.selectedShape) {
                    if (this.selectedShape.hasName('circle')) {
                        this.selectedShape.radius(newSize)
                    } else if (this.selectedShape.hasName('line')) {
                        this.selectedShape.strokeWidth(newSize)
                    }
                }
            })
            this.sliderInput = sliderInput


            append(sliderContainer, sliderInput)
            append(sliderDiv, sliderContainer, sliderInputLabel)
            append(stageToolboxContainer, sliderDiv)

            const markupNotes = createEl('div', {
                className: 'flex text-sm sm:text-base',
                innerText: 'To add markup, double tap/click on the desired area.'
            })

            const markupButtonContainer = createEl('div', {className: 'md:w-auto floorplan-markup-hide-in-edit-mode'})
            const cancelCloseButtonContainer = createEl('div', {className: 'w-auto pr-2 hidden floorplan-markup-show-in-edit-mode'})
            const saveButtonContainer = createEl('div', {className: 'md:w-auto hidden floorplan-markup-show-in-edit-mode'})
            const closeButtonContainer = createEl('div', {className: 'w-auto floorplan-markup-hide-in-edit-mode'})

            const cancelCloseButton = createEl('button', {
                innerText: 'Cancel',
                id: 'floorplan-markup-cancel-and-close',
                className: 'flex flex-wrap items-center justify-center py-2 px-3 w-full text-base text-gray-500 font-medium bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-200 rounded-md shadow-button'
            })
            const saveButton = createEl('button', {
                innerText: 'Save & Close',
                id: 'konva-stage-save',
                className: 'flex flex-wrap items-center justify-center py-2 px-3 w-full text-base text-white font-medium bg-green-500 hover:bg-green-600 rounded-md shadow-button'
            })
            const closeButton = createEl('button', {
                innerText: 'Close',
                id: 'floorplan-markup-close',
                className: 'flex flex-wrap items-center justify-center py-2 px-3 w-full text-base text-gray-500 font-medium bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-200 rounded-md shadow-button'
            })
            const flexDiv = createEl('div', {className: 'flex justify-center items-center h-full'})
            const konvaStageContainer = createEl('div', {
                id: 'konva-stage-container',
                className: 'card-loading-indicator'
            })

            saveButton.addEventListener('click', () => {
                this.saveStageData()
            })

            cancelCloseButton.addEventListener('click', () => {
                this.closeStageDialog()
            })

            closeButton.addEventListener('click', () => {
                this.closeStageDialog()
            })

            // Header Left
            if (this.drawingMode) {
                const drawingToggleParentLabel = createEl('label', {
                    htmlFor: 'drawingEnabledToggle',
                    className: 'flex items-center cursor-pointer mr-4'
                })
                const drawingToggleSpan1 = createEl('span', {className: 'relative'})
                const drawingToggleInput = createEl('input', {
                    name: 'drawingEnabledToggle',
                    id: 'drawingEnabledToggle',
                    type: 'checkbox',
                    className: 'sr-only',
                    value: 'yes'
                })
                drawingToggleInput.defaultChecked = true
                const drawingToggleLine = createEl('span', {
                    className: 'block bg-gray-600 w-14 h-8 rounded-full'
                })
                const drawingToggleDot = createEl('span', {
                    className: 'dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition'
                })
                append(drawingToggleSpan1, drawingToggleInput, drawingToggleLine, drawingToggleDot)
                const drawingToggleSpan2 = createEl('span', {
                    className: 'ml-3 text-gray-700 font-medium',
                    innerText: 'Pen'
                })
                append(drawingToggleParentLabel, drawingToggleSpan1, drawingToggleSpan2)
                append(stageHeaderLeftContainer, drawingToggleParentLabel)
                this.drawingToggle = drawingToggleInput
                this.drawingToggle.addEventListener('change', (e) => {
                    this.drawingEnabled = this.drawingToggle.checked
                    this.stage.setDraggable(!this.drawingToggle.checked)
                })
            } else {
                append(stageHeaderLeftContainer, markupNotes)
            }
            append(stageHeaderLeft, stageHeaderLeftContainer)

            /*
            const markupButton = createEl('button', {
                innerText: 'Markup',
                id: 'floorplan-markup-edit',
                className: 'flex flex-wrap items-center justify-center py-2 px-3 w-full text-base text-white font-medium bg-green-500 hover:bg-green-600 rounded-md shadow-button'
            })
            append(markupButtonContainer, markupButton)
            append(stageHeaderRightContainer, markupButtonContainer)
            markupButton.addEventListener('click', () => {
                this.editable = true
                this.drawingLayer.listening(this.editable) // Enabling drawing layer interactions/events

                if (this.drawingMode && this.drawingToggle) {
                    document.getElementById("drawingEnabledToggle").checked = 'checked'
                    document.getElementById("drawingEnabledToggle").click()
                    // this.drawingToggle.checked = true
                    this.drawingToggle.click()
                }

                document.querySelectorAll('.floorplan-markup-show-in-edit-mode').forEach(element => {
                    element.classList.remove('hidden')
                })
                document.querySelectorAll('.floorplan-markup-hide-in-edit-mode').forEach(element => {
                    element.classList.add('hidden')
                })
            })
            */

            append(cancelCloseButtonContainer, cancelCloseButton)
            append(saveButtonContainer, saveButton)
            append(closeButtonContainer, closeButton)

            if (this.drawingMode) {
                const clearMarkupButtonContainer = createEl('div', {className: 'w-auto px-2 hidden floorplan-markup-show-in-edit-mode'})
                const clearMarkupButton = createEl('button', {
                    id: 'floorplan-markup-clear',
                    className: 'flex flex-wrap items-center justify-center py-2 px-3 w-full text-base text-gray-500 font-medium bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-200 rounded-md shadow-button'
                })
                const clearIconSVG = createElNs('svg', 'http://www.w3.org/2000/svg', {})
                clearIconSVG.setAttribute('width', '16')
                clearIconSVG.setAttribute('height', '16')
                clearIconSVG.setAttribute('fill', 'currentColor')
                clearIconSVG.classList.add('mr-2')
                clearIconSVG.setAttribute('viewBox', '0 0 16 16')
                const clearIconPath = createElNs('path', 'http://www.w3.org/2000/svg', {})
                clearIconPath.setAttribute('d', 'M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z')
                append(clearIconSVG, clearIconPath)
                append(clearMarkupButton, clearIconSVG, createEl('span', {innerText: 'Clear'}))
                clearMarkupButton.addEventListener('click', () => {
                    this.clearDrawing()
                })
                append(clearMarkupButtonContainer, clearMarkupButton)
                append(stageToolboxContainer, clearMarkupButtonContainer)
            }
            append(stageHeaderRightContainer, cancelCloseButtonContainer, saveButtonContainer, closeButtonContainer)
            append(stageHeaderRight, stageHeaderRightContainer)

            // Full Header
            append(stageHeader, stageHeaderLeft, stageHeaderRight)

            // Konva Stage Container and Dialog Box
            append(flexDiv, konvaStageContainer)
            append(konvaStageDialog, stageHeader, stageToolboxContainer, flexDiv)

            this.stageDialogHtml = konvaStageDialog

        }
        else {
            const konvaStageDialog = createEl('div', {id: 'konva-stage-dialog', className: 'full-screen-modal'})
            const konvaStageContainer = createEl('div', {className: 'flex justify-center items-center h-full'})
            const konvaStageContent = createEl('div', {
                id: 'konva-stage-container',
                className: 'card-loading-indicator'
            })
            const buttonContainer = createEl('div', {className: 'absolute top-2 right-2 w-auto'})
            const closeButton = createEl('button', {
                innerText: 'Close',
                id: 'floorplan-markup-close',
                className: 'flex flex-wrap items-center justify-center py-2 px-3 w-full text-base text-white font-medium bg-green-500 hover:bg-green-600 rounded-md shadow-button shadow-md'
            })

            closeButton.addEventListener('click', () => {
                this.closeStageDialog()
            })

            append(buttonContainer, closeButton)
            append(konvaStageContainer, konvaStageContent, buttonContainer)
            append(konvaStageDialog, konvaStageContainer)

            this.stageDialogHtml = konvaStageDialog
        }

        return this.stageDialogHtml
    }

    saveStageData() {
        if (this.stageDialog) {            
            document.dispatchEvent(new Event('konva-stage-save-in-progress'))
            let data = this.getOutput()
            data['drawingMode'] = this.drawingMode
            let eventName = 'markup-data'
            if(this.drawingMode) {
              let fileName = this.photo.dataset.uniqueFilename ?? 'Unknown'
              eventName += `-${fileName}`
            }
            document.dispatchEvent(new CustomEvent(eventName, {detail: data}))
            document.dispatchEvent(new Event('konva-stage-save-complete'))
            this.closeDialog();
        }
    }

    openStageDialog() {
        if (this.stageDialog) {
            try {
                if (this.wrapper) {
                    this.wrapper.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'hidden';
                }
                this.stageDialog.classList.add('open')
                this.loaded = true
            } catch (e) {
                console.log('Got an error while starting Konva Stage. \n' + e)
            }
        }
    }

    closeStageDialog() {
        this.closeDialog()
    }

    closeDialog() {
        if (this.stageDialog) {
            try {
                if (document.getElementById("markup_image_upload")) {
                    document.getElementById("markup_image_upload").value = ''
                }
                if (document.getElementById("photo_markup_upload")) {
                    document.getElementById("photo_markup_upload").value = ''
                }

                this.stageDialog.classList.remove('open')
                this.stage.destroyChildren()
                this.stage.destroy()
                this.stage = null
                this.imageObj = null
                if (this.wrapper) {
                    this.wrapper.style.overflow = ''
                    this.wrapper.removeChild(this.stageDialog)
                } else {
                    document.body.style.overflow = ''
                    document.body.removeChild(this.stageDialog)
                }
                this.loaded = false
                document.dispatchEvent(new Event('konva-stage-closed'))
            } catch (e) {
                console.log('Due to some issues, the Konva Stage could not be closed properly. \n' + e)
            }
        }
    }

    disableLoadingStatus() {
        this.updateStageSize()  // This function should be called here, instead of in setImageSize()
        const konvaStageDiv = this.wrapper ? document.querySelector('#wrapper-konva-stage-container') : document.querySelector('#konva-stage-container')
        if (konvaStageDiv) {
            konvaStageDiv.classList.remove('card-loading-indicator')
        }

        if (!this.presentPhoto) {
            this.editable = true
            this.drawingLayer.listening(this.editable) // Enabling drawing layer interactions/events

            if (this.drawingMode && this.drawingToggle) {
                document.getElementById("drawingEnabledToggle").checked = 'checked'
                document.getElementById("drawingEnabledToggle").click()
                // this.drawingToggle.checked = true
                this.drawingToggle.click()
            }

            document.querySelectorAll('.floorplan-markup-show-in-edit-mode').forEach(element => {
                element.classList.remove('hidden')
            })
            document.querySelectorAll('.floorplan-markup-hide-in-edit-mode').forEach(element => {
                element.classList.add('hidden')
            })
        }

    }

    getDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    getCenter(p1, p2) {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
    }

    getRelativePointerPosition(node) {
        // the function will return pointer position relative to the passed node
        var transform = node.getAbsoluteTransform().copy();
        // to detect relative position we need to invert transform
        transform.invert();

        // get pointer (say mouse or touch) position
        var pos = node.getStage().getPointerPosition();

        // now we find relative point
        return transform.point(pos);
    }
}

export default KonvaStage
