import LoginPage from './LoginForm'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ProjectsPage from './pages/Projects'
import PropertiesPage from './pages/Properties'
import RoomsPage from './pages/Locations'
import DefectFormPage from './pages/defectForm'
import ViewDefectPage from './pages/viewDefect'

const routes = [
  {path: '/', component: HomePage, exact: true},
  {path: '/projects', component: ProjectsPage},
  {path: '/projects/:projectId/properties', component: PropertiesPage},
  {path: '/properties/:propertyId/rooms', component: RoomsPage},
  {path: '/properties/:propertyId/defects/new', component: DefectFormPage},
  {path: '/properties/:propertyId/rooms/:roomId/defects/new', component: DefectFormPage},
  {path: '/properties/:propertyId/rooms/:roomId/defects/:defectId/update', component: DefectFormPage},
  {path: '/defects/:defectId', component: ViewDefectPage},
  {path: '/login', component: LoginPage},
  {path: '/about', component: AboutPage},
]

export default routes