import React, {useState, useEffect, useRef} from 'react'
import {differenceInMinutes, formatISO, isBefore, subMinutes, formatDistance} from 'date-fns'
import {Link} from "react-router-dom"
import {fetchApiData, fetchLocations, fetchProjects, fetchProperties, isEmpty, getDjangoAppBaseUrl} from "../utils"
import configDataStore from "../data-stores/configDataStore"
import TopNav from "./TopNav";
import SideMenu from "./SideMenu";
import {useNavigate, useParams} from 'react-router-dom'

const HomePage = () => {
  const {
    updateUser, updatePermissions, updateFloorplanImages, updateProjects, updateProperties, updateLocations, updateCategories,
    updateOriginalTradesByCategoryByProject, updateRemedialTradesByCategoryByProject, updateAllAvailableTrades, settings,
    updateSettings, imageCacheName, properties
  } = configDataStore()
  const [isFetching, setIsFetching] = useState(false)
  const [loadedImages, setLoadedImages] = useState(0)
  const [failedLoadingImages, setFailedLoadingImages] = useState([])
  const [progress, setProgress] = useState({ current: 0, total: 100 })
  const [status, setStatus] = useState('')
  const [lastFetched, setLastFetched] = useState('')
  const mainDiv = useRef(null)
  const mainContentSection = useRef(null)
  const fetchTimeLimitMins = 43200 //30 days
  const navigate = useNavigate()

  const setProgressBar = (progress, status) => {
    setStatus(status)
    if (progress) {
      setProgress(progress)
    }
  }

  const cacheImage = (imgUrl, propertyName, imagesCount) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = imgUrl
      img.onload = () => {
        setLoadedImages((prev) => {
          const newLoadedImages = prev + 1
          setProgressBar({ current: newLoadedImages, total: imagesCount }, `Floorplan image for ${propertyName} loaded`)
          if (newLoadedImages + failedLoadingImages.length === imagesCount) {
            setIsFetching(false)
          }
          return newLoadedImages
        })
        resolve()
      }
      img.onerror = reject
    })
  }

  const preloadImages = async (floorplanImages) => {
    let floorplanImageUrls = floorplanImages.map((image) => image.image)
    for (const { image, property_name, checksum } of floorplanImages) {
      const existingPropertyMap = properties.reduce(function(map, prop) {
        map[prop.id] = prop;
        return map;
      }, {});
      if (!existingPropertyMap.hasOwnProperty(property_name) || existingPropertyMap[property_name].checksum !== checksum) {      
        try {
          await cacheImage(image, property_name, floorplanImages.length)
        } catch (error) {
          setFailedLoadingImages(prev => [...prev, image])
          console.error(`Failed to load image: ${image}`, error)
        }
      }
    }

    // remove other floorplans
    caches.open(imageCacheName).then(function(cache) {
      cache.keys().then(function(cachedImages) { 
        for (const cachedImage of cachedImages) {
          if (!floorplanImageUrls.includes(cachedImage.url)) {
            cache.delete(cachedImage)
          }
        }
      })
    })
  }

  const isValidUrl = (url) => {
    try {
      new URL(url)
      return true
    } catch (e) {
      return false
    }
  }

  const sanitizeUrl = (url) => {
    // Remove any leading/trailing whitespace
    url = url.trim()

    // Add trailing slash if not present
    if (!url.endsWith('/')) {
      url += '/'
    }

    return url
  }

  const setAPIUrl = (apiUrl) => {
    // Validate and sanitize the URL
    if (isEmpty(apiUrl) || !isValidUrl(apiUrl)) {
      console.error('Invalid API URL provided. Default will be used instead.')
      return null
    }

    apiUrl = sanitizeUrl(apiUrl)

    // Update the settings in the store
    updateSettings({ apiUrl })
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      setAPIUrl(e.target.value)
    }
  }

  const fetchPWAData = async () => {
    setIsFetching(true)
    setProgressBar({ current: 0, total: 5 }, 'Retrieving configuration...')

    try {
      const data = await fetchApiData({ type: 'get-pwa-data' }) // Update with your actual API endpoint
      if (data) {
        // localStorage.setItem('user', JSON.stringify(data.user))
        // localStorage.setItem('permissions', JSON.stringify(data.permissions))
        // localStorage.setItem('floorplan_images', JSON.stringify(data.floorplan_images))
        updateUser(data.user)
        updatePermissions(data.permissions)
        updateFloorplanImages(data.floorplan_images)

        setProgressBar({ current: 1, total: 5 }, 'Retrieving projects...')
        const newProjects = await fetchProjects()
        if (newProjects) updateProjects(newProjects)

        setProgressBar({ current: 2, total: 5 }, 'Retrieving properties...')
        const newProperties = await fetchProperties()
        if (newProperties) updateProperties(newProperties)

        setProgressBar({ current: 3, total: 5 }, 'Retrieving locations...')
        const rooms = await fetchLocations()
        if (rooms) updateLocations(rooms)

        setProgressBar({ current: 4, total: 5 }, 'Retrieving defect categories and business rules ...')
        const catsTrades = await fetchApiData({ type: 'catsTrades' })
        if (catsTrades && !catsTrades.hasOwnProperty('error')) {
          updateCategories(catsTrades.categories)
          updateOriginalTradesByCategoryByProject(catsTrades.available_original_trades_by_cat_by_project)
          updateRemedialTradesByCategoryByProject(catsTrades.available_remedial_trades_by_cat_by_project)
          updateAllAvailableTrades(catsTrades.all_trade_companies)
        }

        setProgressBar({ current: 5, total: 5 }, 'Configuration retrieval complete.')

        if (data.floorplan_images.length) {
          setProgressBar({ current: 0, total: data.floorplan_images.length }, 'Loading images...')
          await preloadImages(data.floorplan_images)

          if (failedLoadingImages.length === 0) {
            console.log('Redirecting to Offline Mode')
            // window.location = '/pwa' // Update with your actual redirection URL
          } else {
            alert('Error loading some images. Please inform DefectBuddy Support.')
          }
          setLoadedImages(0)
        }

        updateSettings({ last_data_updated: formatISO(new Date()) })
        setLastFetched('just now')
        navigate('/Projects')
      }
    } catch (error) {
      setProgressBar(null, `Error: ${error}`)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    const lastDataUpdated = settings.last_data_updated
    const timeLimit = subMinutes(new Date(), fetchTimeLimitMins)

    if (!lastDataUpdated || isBefore(new Date(lastDataUpdated), timeLimit)) {
      //fetchPWAData()
    } else {
      const timeSinceFetch = formatDistance(new Date(lastDataUpdated), new Date(), { addSuffix: true })   
      setLastFetched(timeSinceFetch)
    }
  }, [])

  return (
    <React.Fragment>
    <div className="flex flex-col h-screen" ref={mainDiv} id="maindiv">
      <TopNav backUrl={false} pageTitle={'Offline Mode'} mainDivRef={mainDiv} mainContentSectionRef={mainContentSection} hideSyncButton={true}/>
      
      <div className="flex flex-1 overflow-hidden">
        <SideMenu {...'x'} />
        <div className="section bg-gray-50 flex flex-1 flex-col items-stretch min-h-0 h-full w-full overflow-y-auto" id="main-content-section" ref={mainContentSection}>
          <section className="overflow-auto overflow-y-scroll flex flex-col md:px-4 h-full">
            <div className="container mx-auto md:py-0 px-4 md:px-4">
              {/*<h1 className="w-full font-semibold text-lg text-center">Offline Mode</h1>*/}
              <div className="w-full lg:hidden text-center flex flex-wrap justify-center pt-4 pb-1">
                <img src={process.env.PUBLIC_URL + '/assets/images/DefectBuddy-with-name-wide-60h.png'} alt="DefectBuddy"/>
              </div>
              <div className="w-full text-center md:py-4">
                DefectBuddy Offline mode enables you to create defects where there is slow or no internet. 
              </div>

              {isFetching ? (
              <div className="progress-container w-full flex-container p-4">
                <div className="status mb-2">{status}</div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div className="progress-bar h-2 bg-green-500 rounded-full" style={{ width: `${( progress.current / progress.total ) * 100}%` }}></div>
                </div>
              </div>
              ) : (
              <div>
                {/*
                <div className="py-3">
                  <div className="w-full">
                    <div className="flex flex-wrap">
                      <div className="w-full py-1" id="api-url">
                        <p className="text-sm text-gray-700 font-semibold">API URL</p>
                      </div>
                      <div className="w-full py-1">
                        <div className="relative">
                          <input name="api-url"
                                type="text"
                                placeholder="Enter API URL"
                                defaultValue={settings.apiUrl || ''}
                                onKeyUp={handleKeyUp}
                                onBlur={e => setAPIUrl(e.target.value)}
                                className="block w-full p-2 text-base text-gray-900 font-normal outline-none focus:border-green-500 border border-gray-200 rounded-lg shadow-input"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                */}
                <div className="py-3 w-full">
                    {lastFetched && (
                    <>
                    <div className="w-auto flex flex-wrap justify-center">
                      <Link to={`/projects`}
                        className="flex w-auto justify-center px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-lg text-white border border-green-500 rounded-md shadow-sm"
                      >Select Project</Link>
                    </div>
                    <div className="w-auto flex flex-wrap justify-center pt-4">
                      <p className="text-gray-900">DefectBuddy configuration last fetched <b>{lastFetched}</b></p>
                    </div>
                    </>
                    )}
                    {!lastFetched && (
                    <>
                    <div className="w-full text-center md:py-4 flex w-full justify-center">
                      <div className="w-auto text-green-500 pr-6 ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24"  height="24" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  
                          strokeLinecap="round"  strokeLinejoin="round" className="w-12 h-12">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M12 9v4" />
                            <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                            <path d="M12 16h.01" />
                        </svg>
                      </div>
                      <div className="w-auto text-left">
                        DefectBuddy Offline requires a fast and reliable internet connection to initially fetch configuration data. 
                        <br></br>We recommend you fetch the configuration data while on a Wifi connection if possible (to reduce mobile data usage).
                        <br></br>After that, you can down periscope and work offline.
                      </div>
                    </div>
                    </>)}
                    <div className="w-auto flex flex-wrap justify-center pt-1">
                      <button onClick={fetchPWAData} className="flex w-auto justify-center px-2 md:px-4 py-2 font-medium text-lg border border-gray-200 text-gray-500 hover:bg-gray-50 hover:text-green-500 hover:border-gray-300 bg-white rounded-md shadow-sm">
                        Fetch Configuration Data
                      </button>
                    </div>
                    <div className="w-auto flex flex-wrap justify-center pt-4">
                      <p className="text-gray-900 text-center">
                        If you've got a fast and reliable (e.g. not going into a basement) internet connection, 
                        <br className="md:hidden"/> you can return to the <a href={getDjangoAppBaseUrl()}className="underline text-green-500 font-medium">Online Mode</a>.
                      </p>
                    </div>
                </div>
              </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
    </React.Fragment>
  )
}

export default HomePage
