import React, {useEffect, useRef, useState} from 'react'
import configDataStore from "../data-stores/configDataStore"
import {useNavigate, useParams} from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  getDefectRaisedByTypes,
  defectStatusTypeChoices,
  getRooms,
  propertySearchName,
  getAvailableDefectStatuses,
  getAvailableCategories,
  isEmpty,
  getCategoryTrades,
  getImageUrlFromCache,
  getImageMarkup,
  getDefectImagesFromCache,
  getAssignedRemedialContractorName,
  getAssignedTradeName,
  validateEmail,
  isMobileOrTablet,
  topNavFix
} from "../utils"
import FloorplanMarkup from "./floorplanMarkup"
import {format, set} from 'date-fns'
import DefectImages from "./defectImages";
import TopNav from "./TopNav";
import SideMenu from "./SideMenu";
import ReactSelect from '../components/ReactSelect'
import SpinnerModal from '../components/spinnerModal'
import defectDataStore from '../data-stores/defectDataStore'

const DefectFormPage = () => {
  let { propertyId, roomId, defectId } = useParams()
  propertyId = propertyId ? parseInt(propertyId) : propertyId
  roomId = roomId ? parseInt(roomId) : roomId
  defectId = defectId ? parseInt(defectId) : defectId
  const { user, permissions, properties, projects, categories, originalTradesByCategoryByProject, remedialTradesByCategoryByProject, imageCacheName} = configDataStore()
  const { defects, recordDefect } = defectDataStore()
  const {
    register,
    setError,
    clearErrors,
    formState: { errors, isDirty },
    trigger,
    setValue
  } = useForm()
  const navigate = useNavigate()
  const clientIssueField = useRef(null)
  const descriptionField = useRef(null)
  const mainDiv = useRef(null)
  const mainContentSection = useRef(null)
  const sendToTradeDiv = useRef(null)
  const sendToTradeErrorDiv = useRef(null)
  const otherPointOfContactDiv = useRef(null)
  const entryInstructionsDiv = useRef(null)
  const [topNavProps, setTopNavProps] = useState({})
  let [rooms, setRooms] = useState([])
  let [defect, setDefect] = useState({})
  let [property, setProperty] = useState({})
  let [project, setProject] = useState({})
  let [contacts, setContacts] = useState([])
  let [catTrades, setCatTrades] = useState([])
  let [catRemedialContractors, setCatRemedialContractors] = useState([])
  let [assignRemedialContractor, setAssignRemedialContractor] = useState(false)
  let raisedByTypes = getDefectRaisedByTypes(property, permissions)
  let allDefectStatuses = defectStatusTypeChoices
  let defectStatuses = getAvailableDefectStatuses(defect, user, property)
  let availableCategories = getAvailableCategories(defect, property, categories)
  let organisation = {}
  let [config, setConfig] = useState({})
  const [fileInputs, setFileInputs] = useState([])
  const [showSpinnerModal, setShowSpinnerModal] = useState(false)
  const [invalidCategory, setInvalidCategory] = useState(false)

  const isFieldHidden = (fieldId) => {
    let field = document.querySelector(`#${fieldId}`)
    return field ? !!field.closest('.hidden') : true
  }

  const validate = async () => {
    clearErrors()

    const validationErrors = []

    if (isEmpty(defect.defect_category_id)) {
      setError('defect_category_id', { type: 'manual', message: 'Please select a defect category' })
      validationErrors.push({ field: 'defect_category_id', message: 'Please select a defect category' })
    }
    setInvalidCategory(isEmpty(defect.defect_category_id))

    if (isEmpty(defect.room)) {
      setError('room', { type: 'manual', message: 'Please specify the location of the defect' })
      validationErrors.push({ field: 'room', message: 'Please specify the location of the defect' })
    }

    if (isEmpty(defect.trade_practitioner_id)) {
      setError('trade_practitioner_id', { type: 'manual', message: 'Please allocate the Defect to a Trade' })
      validationErrors.push({ field: 'trade_practitioner_id', message: 'Please allocate the Defect to a Trade' })
    }

    if (!isFieldHidden('point_of_contact_portal_user')) {
      if (defect.point_of_contact_portal_user === "0") {
        setError('point_of_contact_portal_user', { type: 'manual', message: 'Please specify who will be the point of contact for this issue' })
        validationErrors.push({ field: 'point_of_contact_portal_user', message: 'Please specify who will be the point of contact for this issue' })

      } else if (defect.point_of_contact_portal_user === "other") {
        if (isEmpty(defect.point_of_contact_name)) {
          setError('point_of_contact_name', { type: 'manual', message: 'Please provide Point of Contact Name' })
          validationErrors.push({ field: 'point_of_contact_name', message: 'Please provide Point of Contact Name' })
        }
        if (isEmpty(defect.point_of_contact_phone)) {
          setError('point_of_contact_phone', { type: 'manual', message: 'Please provide Point of Contact Phone' })
          validationErrors.push({ field: 'point_of_contact_phone', message: 'Please provide Point of Contact Phone' })
        }
        if (!validateEmail(defect.point_of_contact_email)) {
          setError('point_of_contact_email', { type: 'manual', message: 'Please provide Point of Contact Email' })
          validationErrors.push({ field: 'point_of_contact_email', message: 'Please provide Point of Contact Email' })
        }
      }
    }

    if (isEmpty(defect.client_issue_description) && !isFieldHidden('client_issue_description')) {
      setError('client_issue_description', { type: 'manual', message: 'Please provide a description of the issue' })
      validationErrors.push({ field: 'client_issue_description', message: 'Please provide a description of the issue' })
    }

    if (isEmpty(defect.defect_description) && !isFieldHidden('defect_description')) {
      setError('defect_description', { type: 'manual', message: 'Please provide a description of the defect' })
      validationErrors.push({ field: 'defect_description', message: 'Please provide a description of the defect' })
    }

    if(assignRemedialContractor) {
      if (isEmpty(defect.remedial_contractor_id) && !isFieldHidden('remedial_contractor_id')) {
        setError('remedial_contractor_id', { type: 'manual', message: 'Please select the Remedial Contractor' })
        validationErrors.push({ field: 'remedial_contractor_id', message: 'Please select the Remedial Contractor' })
      }
    }

    if (validationErrors.length === 1) {
      const errorField = validationErrors[0].field
      document.getElementById(errorField)?.focus()
    } else {
      document.getElementById('validation_errors')?.scrollIntoView({behavior: 'smooth', block: 'start'})
      topNavFix()
    }

    return validationErrors.length === 0
  }

  const takePhoto = (e) => {
    document.getElementById('defect_image_upload_camera').click()
  }

  const uploadFile = () => {
    document.getElementById('defect_image_upload').click()
  }

  const handleFileInput = (e) => {
    console.log('handleFileInput')
    const files = e.target.files
    const filesArray = Array.from(files)
    if (filesArray.length === 0) {
      topNavFix()
      return
    }
    
    filesArray.forEach((file) => {
      file.originalFilename = file.name
      file.uniqueFilename = `${Date.now()}-${file.name}`
    })
    setFileInputs((prev) => [...prev, ...filesArray])

    // Cache the images using Workbox
    if ('serviceWorker' in navigator /*&& navigator.serviceWorker.controller*/) {
      let newFilesSaved = 0
      caches.open(imageCacheName).then((cache) => {
        filesArray.forEach((file) => {
          const reader = new FileReader()
          reader.onload = (event) => {
            const base64Data = event.target.result
            const byteString = atob(base64Data.split(',')[1])
            const mimeString = base64Data.split(',')[ 0 ].split(':')[ 1 ].split(';')[ 0 ]
            const ab = new ArrayBuffer(byteString.length)
            const ia = new Uint8Array(ab)
            for (let i = 0; i < byteString.length; i++) {
              ia[ i ] = byteString.charCodeAt(i)
            }
            const blob = new Blob([ia], { type: mimeString, originalFilename: file.originalFilename, uniqueFilename: file.uniqueFilename })
            const response = new Response(blob, {
              headers: { 'Content-Type': file.type },
            })
            cache.put(`/cached-images/${file.uniqueFilename}`, response).then(() => {
              const updatedDefect = { ...defect, images: [...(defect?.images || []), {name: file.name, uniqueFilename: file.uniqueFilename, originalFilename: file.originalFilename}] }
              setDefect(updatedDefect)
              newFilesSaved++
              if (isMobileOrTablet() && newFilesSaved == filesArray.length) {
                // find firstUploadedFile in the Dom, scroll into view
                  let _imagesForDefect = document.getElementById('images_for_defect').querySelectorAll('.photo-wrapper')
                  if (_imagesForDefect && _imagesForDefect.length > 0) {
                    const firstNewImageIndex = _imagesForDefect.length - newFilesSaved
                    topNavFix(null, _imagesForDefect[firstNewImageIndex].scrollIntoView({behavior: 'smooth', block: 'start'}))
                  }
              }
            })
          }
          reader.readAsDataURL(file)
        })
      })
    }
  }

  const onDefectCategoryChange = (e) => {
    console.log('e.value=' + e.value)
    updateDefect('defect_category_id', parseInt(e.value))
    if (errors[ 'defect_category_id' ]) {
      trigger('defect_category_id')
    } else {
      if (isPermitted('select_defect_trade')) {
        document.getElementById('trade_practitioner_id').click()
      }
    }
  }

  const imageProps = () => {
    return {
      config: {}, fileInputs, setFileInputs,
      defect, setDefect
    }
  }

  const highlightdragdrop = (event) => {
    let parentDiv = event.target.closest('#file_upload_div')
    parentDiv.classList.add('border-green-500', 'bg-green-100')
    parentDiv.classList.remove('border-gray-200')
  }

  const unhighlightdragdrop = (event) => {
    let parentDiv = event.target.closest('#file_upload_div')
    parentDiv.classList.remove('border-green-500', 'bg-green-100')
    parentDiv.classList.add('border-gray-200')
  }

  const updateSendToTradeEvent = (_defect) => {
    let showError = 0 === parseInt(_defect.trade_practitioner_id)
    let showSendToTrade = !showError && _defect.current_status !== 'draft'
    let deactivateSendToTrade = 0 === parseInt(_defect.defect_category_id) || _defect.current_status === 'draft'

    if (!deactivateSendToTrade && showError){
      sendToTradeErrorDiv.current.classList.remove('hidden')
    } else {
      sendToTradeErrorDiv.current.classList.add('hidden')
    }

    if(!deactivateSendToTrade && showSendToTrade) {
      sendToTradeDiv.current.classList.remove('hidden')
    } else {
      sendToTradeDiv.current.classList.add('hidden')
    }

    if (deactivateSendToTrade) {
      sendToTradeErrorDiv.current.classList.add('hidden')
      sendToTradeDiv.current.classList.add('hidden')
    }
  }

  const updateDefect = (key, value) => {
    if (key === 'room') value = parseInt(value)

    let _defect = {...defect, [key]: value}
    setDefect(_defect)
    setValue(key, value, {shouldDirty: true})

    if (key === 'defect_category_id') {
      // Need to populate the trades and remedial contractors for this category
      setCatTrades(getCategoryTrades(parseInt(value), originalTradesByCategoryByProject[property.building_project]))
      setCatRemedialContractors(getCategoryTrades(parseInt(value), remedialTradesByCategoryByProject[property.building_project]))
      updateSendToTradeEvent(_defect)
    }

    if (key === 'floorplan_markup_canvas_json' && !isEmpty(value?.stageJSONData)) {
      setConfig({ stageData: value.stageJSONData })
    }

    if (key === 'current_status' || key === 'trade_practitioner_id') {
      updateSendToTradeEvent(_defect)
    }

    if (key === 'point_of_contact_portal_user') {
      ( value === 'other' ) ? otherPointOfContactDiv.current?.classList.remove('hidden') : otherPointOfContactDiv.current?.classList.add('hidden')
    }

    if (key === 'entry_permitted') {
      if( value === 'no' ) {
        entryInstructionsDiv.current?.classList.add('hidden')
      } else {
        entryInstructionsDiv.current?.classList.remove('hidden')
        entryInstructionsDiv.current?.querySelector('#entry_instructions')?.focus()
      }
    }
  }

  const returnToPreviousPage = () => {
    let url = `/properties/${propertyId}/rooms`
    if( !isEmpty(defectId)) {
      // We are on the edit defect page
      url = `/defects/${defectId}`
    }
    navigate(url)
  }

  const saveDefect = async (e) => {
    e.preventDefault()
    const isValid = await validate()
    if (isValid) {
      let recordedAt = defect.recorded_at

      if (!defect.committed_by_author && isEmpty(recordedAt)) {
        recordedAt = new Date()
        recordedAt = format(recordedAt, "yyyy-MM-dd'T'HH:mm:ssXXX")
      }

      let updatedDefect = { ...defect, recorded_at: recordedAt, assign_to_remedial_contractor: assignRemedialContractor }

      // Send to trade event handling
      if (updatedDefect.current_status === 'draft' || isEmpty(updatedDefect.trade_practitioner_id)) {
        delete ( updatedDefect.send_to_trade_type )
      }

      recordDefect(updatedDefect)
      returnToPreviousPage()
    }
  }

  const saveAndNewDefect = async (e) => {
    const isValid = await validate()
    if (isValid) {
      let tempId = +Math.floor(new Date() / 1000)
      let recordedAt = defect.recorded_at

      if (!defect.committed_by_author && isEmpty(recordedAt)) {
        recordedAt = new Date()
        recordedAt = format(recordedAt, "yyyy-MM-dd'T'HH:mm:ssXXX")
      }

      let updatedDefect = { ...defect, recorded_at: recordedAt }

      // Send to trade event handling
      if (updatedDefect.current_status === 'draft' || isEmpty(updatedDefect.trade_practitioner_id)) {
        delete ( updatedDefect.send_to_trade_type )
      }

      recordDefect(updatedDefect)

      let _defect = {
        id: tempId,
        committed_by_author: false,
        high_priority: false,
        room: roomId ?? 0,
        linked_parent_defect: null,
        defect_description: '',
        client_issue_description: '',
        current_status: 'draft',
        defect_category_id: 0,
        building_property_id: propertyId,
        defect_raised_by_type: 'developer',
        images: []
      }

      if (!isFieldHidden('point_of_contact_portal_user')) {
        _defect.point_of_contact_portal_user = "0"
      }

      setDefect(_defect)
      setFileInputs([])
      setAssignRemedialContractor(!isEmpty(_defect.remedial_contractor_id))
      setCatTrades(getCategoryTrades(parseInt(_defect.defect_category_id), originalTradesByCategoryByProject[property.building_project]))
      setCatRemedialContractors(getCategoryTrades(parseInt(_defect.defect_category_id), remedialTradesByCategoryByProject[property.building_project]))
      updateSendToTradeEvent(_defect)

      // Setting values for form so later we know if something has changed
      // for (let key in _defect) {
      //   setValue(key, _defect[ key ])
      // }
    }
  }

  const isPermitted = (perm = '') => {
    let availablePerms = permissions?.properties[ propertyId ]
    if (availablePerms) {
      // Temporary amendments for admins
      if (['admin', 'super_user'].includes(user.role)) {
        availablePerms.push('edit_defect_details')
        availablePerms.push('view_defect_client_details')
        availablePerms.push('edit_defect_client_details')
        // availablePerms.push('')
      }
    }

    return availablePerms?.includes(perm)
  }

  const getDefectStatusLabel = (statusValue) => {
    let statusLabel = statusValue
    allDefectStatuses.some(status => {
      if (status.value === statusValue) {
        statusLabel = status.label
        return true
      }
      return false
    })

    return statusLabel
  }

  const copyClientIssueToDescription = (e) => {
    if (!isEmpty(clientIssueField.current.value)) {
      descriptionField.current.value = clientIssueField.current.value
    }
  }

  const getSelectedTradeName = () => {
    let tradeName = 'unknown'
    if(assignRemedialContractor && !isEmpty(defect.remedial_contractor_id)) {
      tradeName = getAssignedRemedialContractorName(defect)
    } else if(!isEmpty(defect.trade_practitioner_id)) {
      tradeName = getAssignedTradeName(defect)
    }
    return tradeName
  }
  
  const savingKonva = evt => {
    setShowSpinnerModal(true)
  }

  const konvaSaveComplete = evt => {
    setShowSpinnerModal(false)
  }

  useEffect(() => {
    setRooms(getRooms(propertyId))
    let defectObj = {}

    if (defectId) {
      // The defect is either being updated or new, but not for specific room
      defectObj = defects.find(_defect => _defect.id == defectId)
      if (!isEmpty(defectObj)) {
        setDefect(defectObj)
      }
    } else {
      let tempId = +Math.floor(new Date() / 1000)
      defectObj = {
        id: tempId,
        committed_by_author: false,
        high_priority: false,
        room: roomId ?? 0,
        linked_parent_defect: null,
        defect_description: '',
        client_issue_description: '',
        current_status: 'draft',
        defect_category_id: 0,
        building_property_id: propertyId,
        defect_raised_by_type: 'developer',
        images: []
      }

      if(!isFieldHidden('point_of_contact_portal_user')) {
        defectObj.point_of_contact_portal_user = "0"
      }

      setDefect(defectObj)
    }

    const _property = properties?.find(prop => prop.id === propertyId)
    setProperty(_property)
    
    let _project = projects.find(project => project.id === _property.building_project)
    setProject(_project)

    setAssignRemedialContractor(!isEmpty(defectObj.remedial_contractor_id))

    if(!isEmpty(defectObj.defect_category_id)) {
      setCatTrades(getCategoryTrades(parseInt(defectObj.defect_category_id), originalTradesByCategoryByProject[_property.building_project]))
      setCatRemedialContractors(getCategoryTrades(parseInt(defectObj.defect_category_id), remedialTradesByCategoryByProject[_property.building_project]))
      updateSendToTradeEvent(defectObj)
    }

    // Setting values for form so later we know if something has changed
    // for (let key in defectObj) {
    //   setValue(key, defectObj[ key ])
    // }

    {/*}
    let defectCategoryInput = '#defect_category_search_input',
        defectCategorySelect = '#defect_category_id',
        defectCategoryDropdown = '#defect_category_dropdown'

    let defectCategoryDropDown = new DropDown(defectCategoryInput, defectCategorySelect, defectCategoryDropdown)

    console.log(defectCategoryDropDown)

    document.querySelector(defectCategoryInput).addEventListener('click', e => {
        console.log('defectCategoryDropDown.show()')
        defectCategoryDropDown.show()
    })*/}

    setTopNavProps({
      backUrl: isEmpty(defectId) ? `/properties/${propertyId}/rooms` : `/defects/${defectId}`,
      backUrlLabel: isEmpty(defectId) ? 'Rooms List' : 'Defect Summary',
      pageTitle: '',
      mainDivRef: mainDiv,
      mainContentSectionRef: mainContentSection
    })

  }, [roomId, defectId])


  useEffect(() => {
    getDefectImagesFromCache(defect).then(images => {
      console.log(images)
      setFileInputs(images)
    })

  }, [defect.images])

  // Prevent leaving the page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault()
        event.returnValue = 'You have made changes but have not saved. Are you sure you want to move away from this page and lose your changes?'
      }
    }

    setTopNavProps(prevState => {
      let _props = { ...prevState, backBtnClickHandler: (e) => {} }

      if (isDirty) {
        _props.backBtnClickHandler = (e) => {
          e.preventDefault()
          if (window.confirm('You have made changes but have not saved. Are you sure you want to move away from this page and lose your changes?')) {
            returnToPreviousPage()
          }
        }
      }

      return _props
    })

    window.addEventListener('beforeunload', handleBeforeUnload)
    document.addEventListener('konva-stage-save-in-progress', savingKonva)
    document.addEventListener('konva-stage-save-complete', konvaSaveComplete)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      document.addEventListener('konva-stage-save-in-progress', savingKonva)
      document.addEventListener('konva-stage-save-complete', konvaSaveComplete)
    }
  }, [isDirty])

  return (
    <React.Fragment>
    <div className="flex flex-col h-screen" ref={mainDiv} id="maindiv">
      <TopNav {...topNavProps} />

      <div className="flex flex-1 overflow-hidden">
      <SideMenu showProjectDropdown={true} showSelectPropertyButton={true} selectedProject={project} />
        <div className="section bg-gray-50 flex flex-1 flex-col items-stretch min-h-0 h-full w-full overflow-y-auto" id="main-content-section" ref={mainContentSection}>
          <div id="content" className="overflow-auto flex flex-col sm:pt-2 md:p-8 md:pt-4 h-full relative">
            <div className="relative">
              <div id="defect-parent-holder" className="mb-40 @container/edit_defect flex justify-center">

                <div className="p-4 @sm/edit_defect:p-6 bg-white @sm/edit_defect:border @sm/edit_defect:border-gray-100 @sm/edit_defect:shadow-sm @sm/edit_defect:rounded-md @lg/edit_defect:w-9/12">
                  <div className="pb-2 w-full flex flex-wrap items-center justify-between px-3 @md/edit_defect:pb-6 @md/edit_defect:flex-col @md/edit_defect:items-start @md/edit_defect:px-0" id="top-controls-section">
                    <div className="text-gray-900 text-lg font-semibold">
                      {defect.committed_by_author ? ( 'Edit Defect', <span
                        className="text-xl font-semibold text-gray-900 mr-2 mb-2 leading-5 bg-gray-100 rounded-xl">{defect.defect_number}</span> ) : ( 'Create a Defect' )}
                    </div>
                    <div className="text-md text-green-600 font-semibold">{propertySearchName(property)}</div>
                  </div>

                  {/* Error summary */}
                  {Object.keys(errors).length > 0 && (
                    <div className="pb-6 border-t border-gray-100">
                      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert" id="validation_errors">
                        <h3 className="text-red-800 text-md font-semibold">The following needs to be addressed before the defect can be saved:</h3>
                        <div className="mt-2" id="errors-list">
                          <ul className="list-disc list-inside">
                            {Object.values(errors).map((error, index) => (
                            <li key={index}>{error.message}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {raisedByTypes?.length > 1 && (
                    <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                      <div className="w-full">
                        <div className="flex flex-wrap @md/edit_defect:-m-3">
                          <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Raised on Behalf of</p>
                            <p className="text-xs text-gray-500 font-medium">Who is the Defect raised on behalf of.</p>
                          </div>
                          <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                            <div className="relative">
                              <svg className="pointer-events-none absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16"
                                  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z"
                                  fill="#8896AB"></path>
                              </svg>
                              <select id="defect_raised_by_type_select"
                                      name="defect_raised_by_type"
                                      value={defect.defect_raised_by_type}
                                      onChange={e => updateDefect('defect_raised_by_type', e.target.value)}
                                      className="defect-submit appearance-none w-full py-2.5 px-4 text-gray-900 text-base font-normal bg-white border outline-none border-gray-200 focus:border-green-500 rounded-lg shadow-input">
                                {raisedByTypes.map((raisedByType, i) => (
                                  <option key={i} value={raisedByType.value}> {raisedByType.label} </option>
                                ))}
                              </select>
                            </div>
                            <div className="text-red-700 hidden field-error"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isPermitted('edit_defect_client_details') && property.settlement_date && (
                    <>
                      <div className="border-t border-gray-100 py-3 @md/edit_defect:py-6">
                        <div className="w-full">
                          <div className="flex flex-wrap @md/edit_defect:-m-3">
                            <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3" id="point_of_contact_heading">
                              <p className="text-sm text-gray-700 font-semibold">Point of Contact</p>
                              <p className="text-xs text-gray-500 font-medium">Please provide the details for the person to contact to discuss the issue in further detail, and for gaining access to the property.</p>
                            </div>
                            <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                              <div className="w-full">
                                {user.role !== 'client' && (
                                  <p className="text-sm text-red-500 font-medium">
                                    This unit has settled, contact information is required
                                  </p>
                                )}
                                <label htmlFor="point_of_contact_portal_user" className="block mb-2 text-gray-700 font-medium text-sm">
                                  Who is the to contact to discuss the issue?
                                </label>
                                <div className="relative">
                                  <svg className="pointer-events-none absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16"
                                      viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z"
                                      fill="#8896AB"></path>
                                  </svg>
                                  <select {...register('point_of_contact_portal_user', { required: 'Please specify who will be the point of contact for this issue' })}
                                          id="point_of_contact_portal_user"
                                          value={defect.point_of_contact_portal_user || user.id || (defect.point_of_contact_name ? 'other' : '0')}
                                          onChange={e => {
                                            updateDefect('point_of_contact_portal_user', e.target.value)
                                            if (errors[ 'point_of_contact_portal_user' ]) {
                                              trigger('point_of_contact_portal_user')
                                            }
                                          }}
                                          className={`defect-submit appearance-none w-full py-2.5 px-4 text-gray-900 text-base font-normal bg-white border outline-none focus:border-green-500 rounded-lg shadow-input ${errors.point_of_contact_portal_user ? 'border-red-500':''}`}>
                                    <option value="0">--- Select point of contact ---</option>
                                    {contacts.map((contact) => (
                                      <option value={contact.id}>
                                        {user.id == contact.id ? ( 'Please contact me in relation to this issue' ) : ( contact.display_name )}
                                      </option>
                                    ))}
                                    <option value="other">
                                      Other, please specify
                                    </option>
                                  </select>
                                </div>
                                {errors.point_of_contact_portal_user && <div className="text-red-700 field-error">{errors.point_of_contact_portal_user.message}</div>}
                              </div>
                              {contacts.map(contact => (
                                <div data-portal-user-id={contact.id}
                                    className={( ( !defect.point_of_contact_portal_user || defect.point_of_contact_portal_user != contact.id ) && !( !defect.point_of_contact_portal_user && user.id == contact.id ) ) ? 'contact_details w-full flex flex-wrap mt-4 hidden' : 'contact_details w-full flex flex-wrap mt-4'}>
                                  <div className="w-full">
                                    <p className="block text-gray-700 font-base text-sm">
                                      {user.id == contact.id ? 'Your' : contact.user.first_name} details on file are:
                                      <table className="text-sm">
                                        <tbody>
                                        <tr>
                                          <td className="pr-2">Name:</td>
                                          <td className="font-medium">{contact.display_name}</td>
                                        </tr>
                                        <tr>
                                          <td className="pr-2">Email:</td>
                                          <td className="font-medium">{contact.user.email}</td>
                                        </tr>
                                        <tr>
                                          <td className="pr-2">Phone:</td>
                                          <td className="font-medium">{contact.mobile_phone}</td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </p>
                                    {user.id == contact.id ? (
                                      <p className="block mb-2 text-yellow-600 font-medium text-sm">If your details are incorrect, change them via <a href='#' className="underline">your profile</a></p>
                                    ) : (
                                      <p className="block mb-2 text-yellow-600 font-medium text-sm">If the details are incorrect, please get {contact.user.first_name} to amend them in their DefectBuddy profile or select
                                        "Other, please specify" option</p>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div className={!defect.point_of_contact_name ? "w-full flex flex-wrap mt-4 hidden" : "w-full flex flex-wrap mt-4"}
                                  ref={otherPointOfContactDiv}>
                                <div className="w-full">
                                  <label className="block mb-2 text-gray-700 font-medium text-sm" htmlFor="point_of_contact_name">Provide Contact Details</label>
                                  <div>
                                    <input id="point_of_contact_name" {...register('point_of_contact_name', { required: 'Please provide Point of Contact Name' })}
                                          className={`defect-submit block w-full p-2 @md/edit_defect:p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border rounded-lg shadow-input ${errors.point_of_contact_name ? 'border-red-500' : 'border-gray-200'}`}
                                          placeholder="Contact Name"
                                          value={defect.point_of_contact_name || ''}
                                          onChange={e => {
                                            updateDefect('point_of_contact_name', e.target.value)
                                            if (errors[ 'point_of_contact_name' ]) {
                                              trigger('point_of_contact_name')
                                            }
                                          }}
                                          onBlur={e => {
                                            if (errors[ 'point_of_contact_name' ]) {
                                              trigger('point_of_contact_name')
                                            }
                                          }}/>
                                  </div>
                                  {errors.point_of_contact_name && <div className="text-red-700 field-error">{errors.point_of_contact_name.message}</div>}
                                </div>
                                <div className="w-full @md/edit_defect:w-1/2 mt-4 @md/edit_defect:pr-4">
                                  <div>
                                    <input id="point_of_contact_phone" {...register('point_of_contact_phone', { required: 'Please provide Point of Contact Phone' })}
                                          className={`defect-submit block w-full p-2 @md/edit_defect:p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border rounded-lg shadow-input ${errors.point_of_contact_phone ? 'border-red-500' : 'border-gray-200'}`}
                                          placeholder="Contact Phone"
                                          value={defect.point_of_contact_phone || ''}
                                          onChange={e => {
                                            updateDefect('point_of_contact_phone', e.target.value)
                                            if (errors[ 'point_of_contact_phone' ]) {
                                              trigger('point_of_contact_phone')
                                            }
                                          }}
                                          onBlur={e => {
                                            if (errors[ 'point_of_contact_phone' ]) {
                                              trigger('point_of_contact_phone')
                                            }
                                          }}/>
                                  </div>
                                  {errors.point_of_contact_phone && <div className="text-red-700 field-error">{errors.point_of_contact_phone.message}</div>}
                                </div>
                                <div className="w-full @md/edit_defect:w-1/2 mt-4">
                                  <div>
                                    <input id="point_of_contact_email" {...register('point_of_contact_email', { required: 'Please provide Point of Contact Email' })}
                                          className={`defect-submit block w-full  p-2 @md/edit_defect:p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border rounded-lg shadow-input ${errors.point_of_contact_email ? 'border-red-500' : 'border-gray-200'}`}
                                          placeholder="Contact Email"
                                          value={defect.point_of_contact_email || ''}
                                          onChange={e => {
                                            updateDefect('point_of_contact_email', e.target.value)
                                            if (errors[ 'point_of_contact_email' ]) {
                                              trigger('point_of_contact_email')
                                            }
                                          }}
                                          onBlur={e => {
                                            if (errors[ 'point_of_contact_email' ]) {
                                              trigger('point_of_contact_email')
                                            }
                                          }}/>
                                  </div>
                                  {errors.point_of_contact_email && <div className="text-red-700 field-error">{errors.point_of_contact_email.message}</div>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-t border-gray-100 py-3 @md/edit_defect:py-6">
                        <div className="w-full">
                          <div className="flex flex-wrap @md/edit_defect:-m-3">
                            <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3">
                              <p className="text-sm text-gray-700 font-semibold">Permission to access property unattended</p>
                              <p className="text-xs text-gray-500 font-medium"></p>
                            </div>
                            <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                              <div className="w-full">
                                <label htmlFor="entry_permitted" className="block mb-2 text-gray-700 font-medium text-sm">
                                  {user.role == 'client' ? (
                                    `Do you give permission for an authorised ${organisation?.name || ''} staff member or third party to access to Property Unattended?`
                                  ) : (
                                    `Has the owner / tenant granted permission for an authorised ${organisation?.name || ''} staff member or third party to access to Property Unattended?`
                                  )}
                                </label>
                                <div className="relative">
                                  <svg className="pointer-events-none absolute right-4 top-1/2 transform -translate-y-1/2"
                                      width="16" height="16" viewBox="0 0 16 16" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z"
                                      fill="#8896AB"></path>
                                  </svg>
                                  <select name="entry_permitted" id="entry_permitted"
                                          defaultValue="no"
                                          value={defect.entry_permitted}
                                          onChange={e => updateDefect('entry_permitted', e.target.value)}
                                          className="defect-submit appearance-none w-full py-2.5 px-4 text-gray-900 text-base font-normal bg-white border outline-none focus:border-green-500 rounded-lg shadow-input">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                  </select>
                                </div>
                              </div>
                              <div className={!defect.committed_by_author || defect.entry_permitted == 'no' ? "w-full flex flex-wrap mt-4 hidden" : "w-full flex flex-wrap mt-4"} ref={entryInstructionsDiv}>
                                <div className="w-full">
                                  <label className="block mb-2 text-gray-700 font-medium text-sm" htmlFor="entry_instructions">Please provide instructions for how they can access the key, and any other entry instructions (e.g.
                                    door code).</label>
                                  <textarea name="entry_instructions"
                                            id="entry_instructions"
                                            className="defect-submit block w-full h-32  p-2 @md/edit_defect:p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border border-gray-200 rounded-lg shadow-input resize-none"
                                            onChange={e => updateDefect('entry_instructions', e.target.value)}
                                            value={defect.entry_instructions || ''}></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {isPermitted('edit_external_reference_number') && (
                    <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                      <div className="w-full">
                        <div className="flex flex-wrap @md/edit_defect:-m-3">
                          <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3" id="external_reference_heading">
                            <p className="text-sm text-gray-700 font-semibold">External Reference Number</p>
                          </div>
                          <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                            <div className="relative">
                              <input name="external_reference_number"
                                    type="text"
                                    value={defect.external_reference_number?.value}
                                    onChange={e => updateDefect('external_reference_number', e.target.value)}
                                    className="defect-submit block w-full p-2 @md/edit_defect:p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border border-gray-200 rounded-lg shadow-input"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                    <div className="w-full">
                      <div className="flex flex-wrap @md/edit_defect:-m-3">
                        <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3" id="select_room_heading">
                          <p className="text-sm text-gray-700 font-semibold">Defect Location</p>
                        </div>
                        <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                          <div className="relative">
                            <svg className="pointer-events-none absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z"
                                fill="#8896AB"></path>
                            </svg>
                            <select {...register('room', { required: 'Please specify the location of the defect' })}
                                    id="room"
                                    value={defect.room || ""}
                                    disabled={!!defect.linked_parent_defect}
                                    onChange={e => {
                                      updateDefect('room', e.target.value)
                                      if (errors[ 'room' ]) {
                                        trigger('room')
                                      }
                                    }}
                                    className={`defect-submit appearance-none w-full py-2.5 px-4 text-gray-900 text-base font-normal bg-white border outline-none focus:border-green-500 rounded-lg shadow-input ${errors.room ? 'border-red-500' : 'border-gray-200'}`}>
                              <option value="">--- select location --</option>
                              {rooms.map((room, i) => (
                                <option key={i} value={room.id}> {room.room_name} </option>
                              ))}
                            </select>
                          </div>
                          {/*{% if child_defects %}
                        <div className="text-sm text-gray-700 font-medium mt-3">The location cannot be changed, as this defect is linked to at least one child defect.</div>
                        {% endif %}*/}
                          {errors.room && <div className="text-red-700 field-error">{errors.room.message}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="flex flex-wrap @md/edit_defect:-m-3">
                        <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3" id="select_room_heading">
                          <p className="text-sm text-gray-700 font-semibold">Location Notes</p>
                          <p className="text-xs text-gray-500 font-medium">Please provide any helpful notes on the location of the Defect.</p>
                        </div>
                        <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                          <div className="relative">
                            <input name="location_notes" type="text"
                                  value={defect?.location_notes || ''}
                                  onChange={e => updateDefect('location_notes', e.target.value)}
                                  className="defect-submit block w-full p-2 @md/edit_defect:p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border border-gray-200 rounded-lg shadow-input"/>
                          </div>
                          <div className="text-red-700 hidden"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isPermitted('select_defect_category') && (
                    <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                      <div className="w-full">
                        <div className="flex flex-wrap @md/edit_defect:-m-3">
                          <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3" id="defect_category_heading">
                            <p className="text-sm text-gray-700 font-semibold">Defect Category</p>
                          </div>
                          <div className="w-full md:flex-1 px-3 py-1 md:py-3">
                            <div id="defect_category_input_parent">
                              <div className="relative">
                                <ReactSelect value={availableCategories.find(c => c.value === defect.defect_category_id)}
                                             {...register('defect_category_id', { required: 'Please select a defect category' })}
                                             onChange={onDefectCategoryChange} isInvalid={invalidCategory} 
											                       menuShouldScrollIntoView={true} openMenuOnFocus={true}
                                             options={availableCategories}
                                             placeholder="Select category" />
                              </div>
                              {errors.defect_category_id && <div className="text-red-700 field-error">{errors.defect_category_id.message}</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                      {isPermitted('select_defect_trade') && (
                        <>
                          <div className="w-full">
                            <div className="flex flex-wrap @md/edit_defect:-m-3">
                              <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3" id="original_trade_heading">
                                <p className="text-sm text-gray-700 font-semibold">Original Sub-Contractor</p>
                              </div>
                              <div id="trades_for_category" className="w-full md:flex-1 px-3 py-1 md:py-3">
                                <div className="relative">
                                  <select {...register('trade_practitioner_id', { required: 'Please allocate the Defect to a Trade' })}
                                          id="trade_practitioner_id"
                                          className={`appearance-none w-full py-2.5 px-4 text-gray-900 text-base font-normal bg-white border outline-none focus:border-green-500 rounded-lg shadow-input ${errors.trade_practitioner_id ? 'border-red-500' : 'border-gray-200'}`}
                                          disabled={defect.linked_parent_defect}
                                          required={!defect.linked_parent_defect}
                                          value={defect.trade_practitioner_id || defect.allocated_trade_practitioner}
                                          onChange={e => {
                                            updateDefect('trade_practitioner_id', parseInt(e.target.value))
                                            if (errors['trade_practitioner_id']) {
                                              trigger('trade_practitioner_id')
                                            }
                                          }}>
                                    <option value="0">Select trade</option>
                                    {catTrades.map((trade, i) => (
                                      <option key={i} value={trade.id}>{trade.business_name}</option>
                                    ))}
                                  </select>
                                </div>
                                {errors.trade_practitioner_id && <div className="text-red-700 field-error">{errors.trade_practitioner_id.message}</div>}
                              </div>
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="flex flex-wrap @md/edit_defect:-m-3">
                              <div className="w-1/3 hidden @md/edit_defect:block px-3 py-1 md:py-3">
                                  <p className="text-sm text-gray-700 font-semibold"></p>
                              </div>
                              <div className="flex w-full @md/edit_defect:flex-1 p-3 items-center">
                                <input type="checkbox" id="assign_to_remedial_contractor" name="assign_to_remedial_contractor"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                      checked={assignRemedialContractor}
                                      disabled={defect.linked_parent_defect}
                                      onChange={e => setAssignRemedialContractor(e.target.checked)}/>
                                <label htmlFor="assign_to_remedial_contractor" className="ml-2 text-sm font-medium text-gray-900 ">
                                  Assign to Remedial Contractor
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className={`w-full ${!assignRemedialContractor ? 'hidden' : ''}`} id="remedial_contractor">
                            <div className="flex flex-wrap @md/edit_defect:-m-3">
                              <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3" id="remedial_trade_heading">
                                <p className="text-sm text-gray-700 font-semibold">Remedial Contractor</p>
                              </div>
                              <div id="remedial_contractors_for_category" className="w-full md:flex-1 px-3 py-1 md:py-3">
                                <div className="relative">
                                  <select {...register('remedial_contractor_id', { required: 'Please select the Remedial Contractor' })}
                                          id="remedial_contractor_id"
                                          className={`appearance-none w-full py-2.5 px-4 text-gray-900 text-base font-normal bg-white border outline-none focus:border-green-500 rounded-lg shadow-input  ${errors.remedial_contractor_id ? 'border-red-500' : 'border-gray-200'}`}
                                          disabled={defect.linked_parent_defect}
                                          value={defect.remedial_contractor_id || defect.remedial_trade_company}
                                          onChange={e => {
                                            updateDefect('remedial_contractor_id', parseInt(e.target.value))
                                            if (errors['remedial_contractor_id']) {
                                              trigger('remedial_contractor_id')
                                            }
                                          }}>
                                    <option value="0">Select Remedial Contractor</option>
                                    {catRemedialContractors.map((contractor, i) => (
                                      <option key={i} value={contractor.id}>{contractor.business_name}</option>
                                    ))}
                                  </select>
                                </div>
                                {errors.remedial_contractor_id && <div className="text-red-700 field-error">{errors.remedial_contractor_id.message}</div>}
                              </div>
                            </div>
                          </div>
                          {defect.linked_parent_defect && (
                            <div className="w-full">
                              <div className="flex flex-wrap @md/edit_defect:-m-3">
                                <div className="w-full md:w-1/3 px-3 py-1 md:py-3">
                                </div>
                                <div className="w-full md:flex-1 px-3 py-1 md:py-3">
                                  <p className="text-xs text-gray-500 font-medium pt-2" id="defect_status_info">
                                    This Defect is linked to {defect.linked_parent_defect}. Updates to Trades on {defect.linked_parent_defect} will also be reflected on this Defect.
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {isPermitted('edit_defect_status') && (
                    <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                      <div className="w-full">
                        <div className="flex flex-wrap @md/edit_defect:-m-3">
                          <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Status </p>
                          </div>
                          <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                            <div className="relative">
                              <svg className="pointer-events-none absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16"
                                  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z"
                                  fill="#8896AB"></path>
                              </svg>
                              <select name="current_status"
                                      disabled={defect.linked_parent_defect}
                                      value={defect.current_status}
                                      onChange={e => updateDefect('current_status', e.target.value)}
                                      className="defect-submit appearance-none w-full py-2.5 px-4 text-gray-900 text-base font-normal bg-white border outline-none border-gray-200 focus:border-green-500 rounded-lg shadow-input">
                                {defectStatuses.map((status, i) => (
                                  <option key={i} value={status.value}> {status.label} </option>
                                ))}
                              </select>
                            </div>
                            {defect.linked_parent_defect && (
                              <p className="text-xs text-gray-500 font-medium pt-2" id="defect_status_info">
                                This Defect is linked to {defect.linked_parent_defect}. Updates to the status of {defect.linked_parent_defect} will also update the status of this Defect.
                              </p>
                            )}
                            <div ref={sendToTradeErrorDiv} className="hidden">Cannot email Defect, no trade has been selected</div>
                            <div ref={sendToTradeDiv} className="relative mt-4 hidden" onChange={e => updateDefect('send_to_trade_type', e.target.value)}>
                              <p className="text-gray-700 font-semibold">
                                When would you like to email this Defect to <strong>{getSelectedTradeName()}</strong>?
                              </p>
                              <div>
                                <label>
                                  <input type="radio" value="send-delayed" name="send_to_trade_type" defaultChecked={true} />
                                  <span className="ml-1">Send at 5pm tomorrow</span>
                                </label>
                              </div>
                              <div className="mt-1">
                                <label>
                                  <input type="radio" value="send-immediately" name="send_to_trade_type" />
                                  <span className="ml-1">Mark Defect as URGENT and send immediately</span>
                                </label>
                              </div>
                            </div>
                            {/*<div id="current_trade_send_info_div" className="flex flex-col flex-wrap items-start justify-between pt-4 hidden">
                              <div id="current_trade_send_info" className="text-green-600"></div>
                              <div id="change_send_time"
                                  className="flex flex-wrap justify-center w-auto px-4 py-1.5 font-medium text-sm text-gray-500 hover:text-green-500 hover:bg-gray-50 border border-gray-200 hover:border-gray-300 rounded-md shadow-sm cursor-pointer hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-time" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                    strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                  <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
                                  <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                                  <path d="M15 3v4"></path>
                                  <path d="M7 3v4"></path>
                                  <path d="M3 11h16"></path>
                                  <path d="M18 16.496v1.504l1 1"></path>
                                </svg>
                                <span>Change Send Time</span>
                              </div>
                            </div>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {( isPermitted('edit_defect_client_details') || isPermitted('view_defect_client_details') ) && (
                    <div id="client_issue_description_div"
                        className={`py-3 @md/edit_defect:py-6 border-t border-gray-100 ${!( ['purchaser', 'tenant'].includes(defect.defect_raised_by_type) ) ? 'hidden' : ''}`}>
                      <div className="w-full">
                        <div className="flex flex-wrap @md/edit_defect:-m-3">
                          <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3" id="client_issue_description_heading">
                            <p className="text-sm text-gray-700 font-semibold">
                              {isPermitted('edit_defect_details') ? (
                                'Client Issue Description'
                              ) : (
                                'Description of Issue'
                              )}
                            </p>
                            <p className="text-xs text-gray-500 font-medium">Please provide as much information as possible to assist us.</p>
                          </div>
                          <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                            {isPermitted('edit_defect_client_details') ? (
                              <textarea {...register('client_issue_description', { required: 'Please provide a description of the issue' })}
                                        id="client_issue_description"
                                        onChange={e => {
                                          updateDefect('client_issue_description', e.target.value)
                                          if (errors[ 'client_issue_description' ]) {
                                            trigger('client_issue_description')
                                          }
                                        }}
                                        onBlur={e => {
                                          if (errors[ 'client_issue_description' ]) {
                                            trigger('client_issue_description')
                                          }
                                        }}
                                        ref={clientIssueField}
                                        className={`defect-submit block w-full h-32  p-2 @md/edit_defect:p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border rounded-lg shadow-input resize-none ${!( ['purchaser', 'tenant'].includes(defect.defect_raised_by_type) ) ? "hidden" : ""} ${errors.client_issue_description ? 'border-red-500' : 'border-gray-200'}`}
                                        value={defect.client_issue_description}></textarea>
                            ) : (
                              <p className="text-gray-500" id="client_issue_description">{defect.client_issue_description}</p>
                            )}
                            {errors.client_issue_description && <div className="text-red-700 field-error">{errors.client_issue_description.message}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isPermitted('edit_defect_details') && (
                    <>
                      {( isPermitted('edit_defect_client_details') || isPermitted('view_defect_client_details') ) && (
                        <div id="copy_client_issue_div" className={!( ['purchaser', 'tenant'].includes(defect.defect_raised_by_type) ) ? "hidden" : ""}>
                          <div className="w-full">
                            <div className="flex flex-wrap">
                              <div className="w-full @md/edit_defect:w-1/3"></div>
                              <div className="w-full flex @md/edit_defect:flex-1 justify-center">
                                <div id="copy_issue_to_defect_desc_button"
                                    onClick={copyClientIssueToDescription}
                                    className="flex flex-wrap justify-center w-auto px-4 py-1.5 font-medium text-sm text-gray-500 hover:bg-gray-50 hover:text-green-500 border border-gray-200 hover:border-gray-300 rounded-md shadow-sm cursor-pointer">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                      strokeWidth="2" stroke="currentColor" aria-hidden="true" style={{ rotate: '180deg' }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 11l7-7 7 7M5 19l7-7 7 7"></path>
                                  </svg>
                                  <span>Copy Client Issue to Defect Description</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className={`py-3 @md/edit_defect:py-6 ${!isPermitted('edit_defect_client_details') ? 'border-t border-gray-100' : ''}`}>
                        <div className="w-full">
                          <div className="flex flex-wrap @md/edit_defect:-m-3" id="defect_description_heading">
                            <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3">
                              <p className="text-sm text-gray-700 font-semibold">
                                {isPermitted('edit_defect_details') ? 'Defect Description' : 'Description'}
                              </p>
                              <p className="text-xs text-gray-500 font-medium">
                                Please provide as much information as possible to assist us.
                                <br/>This information will be shared with the Trade Contractor
                              </p>
                            </div>
                            <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                            <textarea {...register('defect_description', { required: 'Please provide a description of the defect' })}
                                  id="defect_description"
                                  ref={descriptionField}
                                  onChange={e => {
                                    updateDefect('defect_description', e.target.value)
                                    if (errors[ 'defect_description' ]) {
                                      trigger('defect_description')
                                    }
                                  }}
                                  onBlur={e => {
                                    if (errors[ 'defect_description' ]) {
                                      trigger('defect_description')
                                    }
                                  }}
                                  className={`defect-submit block w-full h-32 p-2 @md/edit_defect:p-3 text-base text-gray-900 font-normal outline-none focus:border-green-500 border rounded-lg shadow-input resize-none ${errors.defect_description ? 'border-red-500' : 'border-gray-200'}`}
                                  value={defect.defect_description}></textarea>
                              {errors.defect_description && <div className="text-red-700 field-error">{errors.defect_description.message}</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                    <div className="w-full">
                      <div className="flex flex-wrap @md/edit_defect:-m-3">
                        <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3">
                          <p className="text-sm text-gray-700 font-semibold">Upload Photo(s)</p>
                          <p className="text-xs text-gray-500 font-medium">Upload photo(s) of the defect</p>
                        </div>
                        <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                          <div className="flex flex-wrap @md/edit_defect:flex-nowrap relative rounded-lg" id="files_container">
                            <div className="w-full flex flex-wrap absolute bottom-0 left-0 px-5 z-50 hidden" id="photo_upload_progress_container">
                              <div className="w-full my-3 h-4 rounded-full bg-gray-200">
                                <div id="photo_upload_progress" className="h-4 rounded-full bg-green-500" style={{ width: 0 }}></div>
                              </div>
                            </div>
                            {isMobileOrTablet() && (
                            <div id="take_photo_div"
                                className="w-1/2 @md/edit_defect:mr-2 relative flex flex-col items-center justify-center p-6 h-44 text-center text-green-500 focus-within:border-green-500 border border-dashed border-gray-200 rounded-lg">
                              <svg className="mb-1.5" width="24" height="24" fill="currentColor" viewBox="0 0 16 16"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                  <path
                                    d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"></path>
                              </svg>
                              <p className="mb-1 text-sm text-gray-700 font-medium">
                                <span className="text-green-500">Take Photo</span>
                              </p>
                              <input className="absolute top-0 left-0 w-full h-full opacity-0"
                                    type="file"
                                    accept="image/jpeg,image/heic,image/png,image/gif,image/vnd.dwg,image/vnd.dxf,image/webp,video/mp4,video/mpeg,video/quicktime,video/webm,video/x-ms-wmv,video/x-msvideo,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.slide,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    capture="environment"
                                    name="defect_image_upload"
                                    onChange={handleFileInput}
                                    id="defect_image_upload_camera"/>
                            </div>
                            )}
                            <div id="file_upload_div"
                                className={'w-1/2 relative flex flex-col items-center justify-center p-6 h-44 text-center text-green-500 focus-within:border-green-500 border border-dashed border-gray-200 rounded-lg'
                                    + (isMobileOrTablet() ? ' @md/edit_defect:ml-4' : '')}
                                onDragOver={highlightdragdrop} onDragLeave={unhighlightdragdrop}>
                              <svg className="mb-1.5" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M8.71 7.71L11 5.41V15C11 15.2652 11.1054 15.5196 11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071C12.8946 15.5196 13 15.2652 13 15V5.41L15.29 7.71C15.383 7.80373 15.4936 7.87813 15.6154 7.92889C15.7373 7.97966 15.868 8.0058 16 8.0058C16.132 8.0058 16.2627 7.97966 16.3846 7.92889C16.5064 7.87813 16.617 7.80373 16.71 7.71C16.8037 7.61704 16.8781 7.50644 16.9289 7.38458C16.9797 7.26272 17.0058 7.13202 17.0058 7C17.0058 6.86799 16.9797 6.73729 16.9289 6.61543C16.8781 6.49357 16.8037 6.38297 16.71 6.29L12.71 2.29C12.6149 2.19896 12.5028 2.1276 12.38 2.08C12.1365 1.97999 11.8635 1.97999 11.62 2.08C11.4972 2.1276 11.3851 2.19896 11.29 2.29L7.29 6.29C7.19676 6.38324 7.1228 6.49393 7.07234 6.61575C7.02188 6.73758 6.99591 6.86814 6.99591 7C6.99591 7.13186 7.02188 7.26243 7.07234 7.38425C7.1228 7.50607 7.19676 7.61677 7.29 7.71C7.38324 7.80324 7.49393 7.8772 7.61575 7.92766C7.73757 7.97812 7.86814 8.00409 8 8.00409C8.13186 8.00409 8.26243 7.97812 8.38425 7.92766C8.50607 7.8772 8.61676 7.80324 8.71 7.71ZM21 12C20.7348 12 20.4804 12.1054 20.2929 12.2929C20.1054 12.4804 20 12.7348 20 13V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V13C4 12.7348 3.89464 12.4804 3.70711 12.2929C3.51957 12.1054 3.26522 12 3 12C2.73478 12 2.48043 12.1054 2.29289 12.2929C2.10536 12.4804 2 12.7348 2 13V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7957 22 19V13C22 12.7348 21.8946 12.4804 21.7071 12.2929C21.5196 12.1054 21.2652 12 21 12Z"
                                  fill="currentColor"></path>
                              </svg>
                              <div className="mb-1 text-sm text-gray-700 font-medium">
                                <span className="text-green-500">Click to Upload Images</span>
                                {!isMobileOrTablet() && (
                                  <p>or drag and drop</p>
                                )}
                              </div>
                              <p className="text-xs text-gray-500 font-medium">PNG, JPG or up to 10MB</p>
                              <input id="defect_image_upload"
                                    name="defect_image_upload"
                                    onChange={handleFileInput}
                                    className="absolute top-0 left-0 w-full h-full opacity-0" type='file' multiple
                                    accept="image/jpeg,image/heic,image/png,image/gif,image/vnd.dwg,image/vnd.dxf,image/webp,video/mp4,video/mpeg,video/quicktime,video/webm,video/x-ms-wmv,video/x-msvideo,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.slide,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
                            </div>
                          </div>
                          <div id="images_for_defect" className="container pt-2 mx-auto">
                            <DefectImages {...imageProps()} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                    <div className="w-full">
                      <div className="flex flex-wrap @md/edit_defect:-m-3">
                        <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3">
                          <p className="text-sm text-gray-700 font-semibold">Markup</p>
                        </div>
                        <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                          <div id="defect_markup_image">
                            <FloorplanMarkup config={config} property={property} updateDefect={updateDefect}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isPermitted('flag_defect_as_high_priority') && (
                    <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                      <div className="w-full">
                        <div className="flex flex-wrap @md/edit_defect:-m-3">
                          <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Priority</p>
                            {/* <!--<p className="text-xs text-gray-500 font-medium">Use if description is required</p>--> */}
                          </div>
                          <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                            <div className="w-auto pt-2">
                              <label htmlFor="toggleHighPriority" className="flex items-center cursor-pointer">
                          <span className="relative">
                            <input name="high_priority"
                                  type="checkbox"
                                  id="toggleHighPriority"
                                  className="sr-only"
                                  checked={!!defect.high_priority}
                                  onChange={e => updateDefect('high_priority', e.target.checked)}/>
                            <span className="block bg-gray-600 w-14 h-8 rounded-full"></span>
                            <span className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></span>
                          </span>
                                <span className="ml-3 text-gray-700 font-medium">High Priority</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!isPermitted('edit_defect_status') && (
                    <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                      <div className="w-full">
                        <div className="flex flex-wrap @md/edit_defect:-m-3">
                          <div className="w-full @md/edit_defect:w-1/3 px-3 py-1 @md/edit_defect:py-3">
                            <p className="text-sm text-gray-700 font-semibold">Status</p>
                          </div>
                          <div className="w-full @md/edit_defect:flex-1 px-3 py-1 @md/edit_defect:py-3">
                            <p className="mb-2 text-gray-500 leading-relaxed">{getDefectStatusLabel(defect.current_status)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isPermitted('link_unlink_defects') && (
                    <div className="py-3 @md/edit_defect:py-6 border-t border-gray-100">
                      <div className="w-full">
                        <div id="parent_defect_wrapper">
                          <p>...Parent defect wrapper...</p>
                          {/* include "partials/edit_parent_defect.html" */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <footer className="bottom-0 left-0 right-0 fixed z-10" style={{ width: 'inherit' }}>
                <div className="w-full mx-auto my-auto px-2 py-4 md:px-4 md:px-14 bg-gray-50 border-t border-gray-100">
                  <div className="flex flex-wrap items-center justify-end">
                    <div className="w-full md:w-auto p-2">
                      <div className="flex flex-nowrap justify-between items-start md:gap-6 w-full">
                        <div className="w-auto">
                          <a href="#rooms-for-property-page" id="bottom-cancel"
                            className="flex flex-wrap justify-center w-auto px-2.5 py-2 font-medium text-base border border-gray-200 text-gray-500 hover:bg-gray-50 hover:text-green-500 hover:border-gray-300 bg-white rounded-md shadow-sm"
                            onClick={returnToPreviousPage}>
                            <p>Cancel</p>
                          </a>
                        </div>
                        {isMobileOrTablet() && (
                        <div className="flex w-auto justify-center rounded-lg text-lg md:gap-3" role="group">
                          <button id="bottom-take_photo_div" onClick={takePhoto}
                                  className="flex flex-wrap justify-center w-auto px-2.5 py-2 font-medium text-base border border-gray-200 text-gray-500 hover:bg-gray-50
                                    hover:text-green-500 hover:border-gray-300 bg-white rounded-l-md md:rounded-md shadow-sm"
                                  >
                            <svg className="lg:mr-2 w-6 h-6" width="24" height="24" fill="currentColor" viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                <path
                                  d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"></path>
                            </svg>
                            <span className="hidden lg:block">Take Photo</span>
                          </button>
                          <button id="bottom-file_upload_div" onClick={uploadFile}
                                  className="flex flex-wrap justify-center w-auto px-2.5 md:px-4 py-2 font-medium text-base border border-gray-200 text-gray-500 hover:bg-gray-50 
                                  hover:text-green-500 hover:border-gray-300 bg-white rounded-r-md md:rounded-md shadow-sm"
                                  >
                            <svg className="lg:mr-2 w-6 h-6" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M8.71 7.71L11 5.41V15C11 15.2652 11.1054 15.5196 11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071C12.8946 15.5196 13 15.2652 13 15V5.41L15.29 7.71C15.383 7.80373 15.4936 7.87813 15.6154 7.92889C15.7373 7.97966 15.868 8.0058 16 8.0058C16.132 8.0058 16.2627 7.97966 16.3846 7.92889C16.5064 7.87813 16.617 7.80373 16.71 7.71C16.8037 7.61704 16.8781 7.50644 16.9289 7.38458C16.9797 7.26272 17.0058 7.13202 17.0058 7C17.0058 6.86799 16.9797 6.73729 16.9289 6.61543C16.8781 6.49357 16.8037 6.38297 16.71 6.29L12.71 2.29C12.6149 2.19896 12.5028 2.1276 12.38 2.08C12.1365 1.97999 11.8635 1.97999 11.62 2.08C11.4972 2.1276 11.3851 2.19896 11.29 2.29L7.29 6.29C7.19676 6.38324 7.1228 6.49393 7.07234 6.61575C7.02188 6.73758 6.99591 6.86814 6.99591 7C6.99591 7.13186 7.02188 7.26243 7.07234 7.38425C7.1228 7.50607 7.19676 7.61677 7.29 7.71C7.38324 7.80324 7.49393 7.8772 7.61575 7.92766C7.73757 7.97812 7.86814 8.00409 8 8.00409C8.13186 8.00409 8.26243 7.97812 8.38425 7.92766C8.50607 7.8772 8.61676 7.80324 8.71 7.71ZM21 12C20.7348 12 20.4804 12.1054 20.2929 12.2929C20.1054 12.4804 20 12.7348 20 13V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V13C4 12.7348 3.89464 12.4804 3.70711 12.2929C3.51957 12.1054 3.26522 12 3 12C2.73478 12 2.48043 12.1054 2.29289 12.2929C2.10536 12.4804 2 12.7348 2 13V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7957 22 19V13C22 12.7348 21.8946 12.4804 21.7071 12.2929C21.5196 12.1054 21.2652 12 21 12Z"
                                fill="currentColor"></path>
                            </svg>
                            <span className="hidden lg:block">Upload Photo</span>
                          </button>
                        </div>
                        )}
                        <div className="flex flex-nowrap w-auto justify-center rounded-lg text-lg md:gap-3" role="group">
                          <button id="bottom-confirm" type="submit"
                                  className="flex flex-nowrap px-2.5 md:px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-base text-white border border-green-500 border-r-green-200 rounded-l-md shadow-sm md:border-r-green-500 md:rounded-md"
                                  onClick={saveDefect}>
                            Save
                          </button>
                          <button id="bottom-save-new" type="button"
                                  className="flex flex-nowrap px-2.5 md:px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-base text-white border border-green-500 rounded-r-md shadow-sm md:rounded-md md:whitespace-nowrap"
                                  onClick={saveAndNewDefect}>
                            <span className="md:hidden">+ New</span>
                            <span className="hidden md:block md:whitespace-nowrap">Save &amp; New</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    {showSpinnerModal && (<SpinnerModal />)}
    </React.Fragment>
  )
}

export default DefectFormPage
