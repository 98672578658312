import { create } from 'zustand';

// Utility function to get all defect keys from localStorage
const getAllDefectKeys = () => {
  return Object.keys(localStorage).filter(key => key.startsWith('defect_'));
};

// Utility function to get all defects from localStorage
const getAllDefects = () => {
  const keys = getAllDefectKeys();
  const defects = keys.map(key => JSON.parse(localStorage.getItem(key)));
  return defects;
};

const saveDefectToStore = (defect) => {
    const key = `defect_${defect.id}`;
    localStorage.setItem(key, JSON.stringify(defect));
}

const deleteDefectFromStore = (defect) => {
    const key = `defect_${defect.id}`;
    localStorage.removeItem(key);
}

// Zustand store for managing defects
const useDefectStore = create((set, get) => ({
  // This will be used to track if a refresh is needed
  defects: getAllDefects(),

  // Action to add or update a defect
  recordDefect: (defect) => {
    saveDefectToStore(defect)
    set({ defects: getAllDefects() });
  },

  // Action to delete a defect
  deleteDefect: (defectId) => {
    deleteDefectFromStore(defectId)
    set({ defects: getAllDefects() });
  },

  // Action to get a defect by ID
  getDefect: (defectId) => {
    const key = `defect_${defectId}`;
    return JSON.parse(localStorage.getItem(key));
  },

  // Action to get all defects
  getAllDefects: () => {
    return get().defects;
  },

  // Action to filter defects based on criteria
  filterDefects: (criteria) => {
    return get().defects.filter(criteria);
  },
  
  updateSyncedDefects: (newDefects) => {
    newDefects.forEach(defect => {
        saveDefectToStore(defect)
    })
    set({ defects: getAllDefects() });
  }
}));

export default useDefectStore;